const loadedScriptsDict = {};

export function loadScriptOnce(url) {
  if (loadedScriptsDict[url]) {
    return;
  }
  loadedScriptsDict[url] = true;
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = url;
  document.querySelector('head').appendChild(s);
}

export function loadIframeResizerScript() {
  return loadScriptOnce('https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.11/iframeResizer.contentWindow.min.js');
}
