import React from 'react';
import PropTypes from 'prop-types';
import Shapes from 'Shapes';
import './restaurant-order-details.scss';
import Walking from 'Icons/Walking';
var takeoutIconStyle = {
    minHeight: 18,
    marginTop: -8,
    width: 'auto',
};
var RestaurantOrderDetails = function (props) {
    var restaurant = props.restaurant, wrapperClass = props.wrapperClass;
    if (!restaurant.formattedDistance && !restaurant.distance) {
        return null;
    }
    return (React.createElement("div", { className: wrapperClass },
        React.createElement("div", { className: "restaurant__order-details-item mt-0" },
            React.createElement(Walking, { style: takeoutIconStyle, className: "text-primary" }),
            " ",
            restaurant.formattedDistance)));
};
RestaurantOrderDetails.propTypes = {
    restaurant: Shapes.deliveryRestaurantShape,
    wrapperClass: PropTypes.string,
};
export default RestaurantOrderDetails;
