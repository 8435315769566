import React from 'react'

import { isOtoStolik } from 'App/services/tenant'
import { EPaymentType } from 'App/app/enums'

const prefix = `/images/cuisine-types${isOtoStolik() ? '' : '-lubje'}`

const cuisineImg = path => (
  <img
    className="sidebar__cuisine-type-icon"
    src={`${prefix}/${path}`}
    alt=""
  />
)

export const CuisineTypesIconSet = {
  Amerykańska: cuisineImg('american.svg'),
  Wschodnia: cuisineImg('eastern.svg'),
  Burgery: cuisineImg('burgers.svg'),
  Makaron: cuisineImg('italian.svg'),
  Włoska: cuisineImg('italian.svg'),
  Meksykańska: cuisineImg('mexican.svg'),
  Pub: cuisineImg('pub.svg'),
  Sushi: cuisineImg('sushi.svg'),
  Desery: cuisineImg('desserts.svg'),
  Europejska: cuisineImg('european.svg'),
  Japońska: cuisineImg('japanese.svg'),
  Pizza: cuisineImg('pizza.svg'),
  Sałatki: cuisineImg('salads.svg'),
  Wege: cuisineImg('vege.svg'),
  Śniadania: cuisineImg('breakfast.svg'),
  Regionalna: cuisineImg('dumplings.svg'),
  Indyjska: cuisineImg('indian.svg'),
  Kebab: cuisineImg('kebab.svg'),
  Polska: cuisineImg('polish.svg'),
  Zupy: cuisineImg('soup.svg'),
}

export const PaymentTypesIconSet = {
  [EPaymentType.CASH]: (
    <svg
      aria-hidden="true"
      focusable="false"
      width="1.25em"
      height="1em"
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 640 512"
    >
      <path
        d="M320 144c-53.02 0-96 50.14-96 112c0 61.85 42.98 112 96 112c53 0 96-50.13 96-112c0-61.86-42.98-112-96-112zm40 168c0 4.42-3.58 8-8 8h-64c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h16v-55.44l-.47.31a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09l15.33-10.22a23.99 23.99 0 0 1 13.31-4.03H328c4.42 0 8 3.58 8 8v88h16c4.42 0 8 3.58 8 8v16zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160z"
        fill="currentColor"
      />
    </svg>
  ),
  [EPaymentType.CARD_ON_PLACE]: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      width="1.13em"
      height="1em"
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 576 512"
    >
      <path
        d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"
        fill="currentColor"
      />
    </svg>
  ),
}

export const DeliveryModeIconSet = {
  delivery: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="bicycle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className="svg-inline--fa fa-bicycle fa-w-20"
    >
      <path
        fill="currentColor"
        d="M512.303 192c-19.586-.047-38.147 4.313-54.759 12.132L373.508 71.439A16 16 0 0 0 359.991 64h-67.998c-6.627 0-12 5.373-12 12v8c0 6.627 5.372 12 12 12h59.193l40.532 64H255.994v-20c0-6.627-5.372-12-12-12h-83.998c-8.836 0-16 7.163-16 16s7.163 16 16 16h55.999l-31.808 44.969c-17.085-8.362-36.303-13.035-56.622-12.968C56.937 192.234-.001 249.37 0 320c.001 70.692 57.307 128 127.997 128 59.641 0 109.755-40.793 123.964-96h52.031a16.001 16.001 0 0 0 13.107-6.824l100.744-143.924 12.677 20.018c-28.385 23.449-46.487 58.903-46.531 98.587-.077 69.963 56.843 127.499 126.801 128.138 70.559.644 128.101-55.842 129.193-125.995 1.099-70.503-57.17-129.829-127.68-130zM127.997 416c-52.933 0-95.998-43.065-95.998-96s43.064-96 95.998-96c13.307 0 25.989 2.724 37.521 7.639L98.89 326.824C91.47 337.423 99.083 352 111.997 352h106.506c-13.207 37.248-48.788 64-90.506 64zm95.998-96h-81.268l49.744-71.065c19.354 17.575 31.524 42.925 31.524 71.065zm71.668 0h-39.669c0-39.04-17.483-73.992-45.04-97.47L232.325 192H385.26l-89.597 128zm212.533 95.927c-50.058-1.938-90.528-42.677-92.154-92.747-.961-29.57 11.533-56.303 31.81-74.546l52.759 83.306c3.546 5.599 10.959 7.263 16.558 3.717l6.758-4.281c5.599-3.546 7.263-10.96 3.717-16.558l-52.785-83.346c11.427-4.811 23.972-7.473 37.128-7.473 52.933 0 95.998 43.065 95.998 96 .001 54.194-45.136 98.043-99.789 95.928z"
      />
    </svg>
  ),
  takeout: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="walking"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      className="svg-inline--fa fa-walking fa-w-10 fa-5x"
    >
      <path
        fill="currentColor"
        d="M94.8 347.8s-.1-.1-.1-.2l-20.4 51c-2 5-5 9.6-8.9 13.4L7 470.5c-9.4 9.4-9.4 24.6 0 33.9 4.7 4.7 10.8 7 16.9 7s12.3-2.3 16.9-7l58.4-58.5c8.5-8.5 15-18.5 19.4-29.5l13.5-33.7-36.2-33.5-1.1-1.4zM207.8 96c26.5 0 47.9-21.5 47.9-48S234.2 0 207.8 0c-26.5 0-47.9 21.5-47.9 48s21.4 48 47.9 48zm104.7 174.9L283.2 242c-.9-.9-1.6-2-2-3.2L268.3 200c-14.4-43.1-54.4-72-99.8-72-34.8 0-53 8.8-95.7 26-20.9 8.4-37.9 24.1-48.2 44.8l-14.4 31.1c-13.3 28.7 30.1 49.1 43.5 20.2l14-30.4c4.8-9.6 12.9-17 22.8-21 21.7-8.7 33.1-13.5 44.3-17.1L115 260.8c-4.7 18.9.3 38.8 14.9 54.6l79 73c5.9 5.5 10 12.5 11.8 20.4l19.5 84.8c2.6 11.5 14.4 21.2 28.7 18 12.9-3 20.9-15.9 17.9-28.8l-19.5-84.7c-4-17.3-13-32.8-26-44.9l-53.8-49.6 26.2-104.8c7.4 9.7 7.5 12.6 21.8 55.4 2.8 8.3 7.5 15.9 13.8 22.2l29.3 29c22.3 21.5 56.7-11.9 33.9-34.5z"
      />
    </svg>
  ),
}
