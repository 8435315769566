var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import Clock from '../../icons/Clock';
import { daysLabels, today, getWeekDayIndex } from '../../app/date-helpers';
var normalizedDayLabels = __spreadArray([], __read(Object.values(daysLabels)), false);
// @ts-ignore to check what's going on here, but it probably works
normalizedDayLabels.push(normalizedDayLabels.shift());
export var getDayOpeningHours = function (_a) {
    var open = _a.open, close = _a.close, isClosed = _a.isClosed;
    if (isClosed) {
        return 'Zamknięte';
    }
    return "".concat(open.hour, ":").concat(open.min || '00', "\u2014").concat(close.hour, ":").concat(close.min || '00');
};
var RestaurantDeliveryHours = function (_a) {
    var restaurant = _a.restaurant;
    console.error('RestaurantDeliveryHours uses outdated deliveryHours field');
    var name = restaurant.name, details = restaurant.details;
    var deliveryHours = details.deliveryHours;
    if (!deliveryHours || !deliveryHours.length) {
        return null;
    }
    var content = deliveryHours
        .map(function (hourPair, index) {
        return "<div>".concat(normalizedDayLabels[index], ": ").concat(getDayOpeningHours(hourPair), "</div>");
    })
        .join('');
    return (React.createElement("div", { className: "restaurant__opening-hours" },
        React.createElement(Clock, null),
        React.createElement("span", { className: "ml-2" },
            React.createElement("span", { className: "capitalize" }, daysLabels[today.getDay()]),
            ":",
            ' ',
            getDayOpeningHours(deliveryHours[getWeekDayIndex(today)])),
        "\u00A0",
        React.createElement("a", { tabIndex: 0, role: "button", className: "btn btn-link restaurant__show-hours", "data-toggle": "popover", "data-placement": "bottom", "data-trigger": "focus", "data-html": "true", title: "Godziny przyjmowania zam\u00F3wie\u0144 ".concat(name), "data-content": content }, "inne dni")));
};
RestaurantDeliveryHours.displayName = 'RestaurantDeliveryHours';
export default RestaurantDeliveryHours;
