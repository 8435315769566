export var EPaymentType;
(function (EPaymentType) {
    EPaymentType["CASH"] = "cash";
    EPaymentType["CARD_ON_PLACE"] = "card_on_place";
    EPaymentType["CARD_ONLINE"] = "card_online";
    EPaymentType["BLIK"] = "blik";
    EPaymentType["TRANSFER"] = "transfer";
    EPaymentType["APPLE_PAY"] = "apple_pay";
    EPaymentType["GOOGLE_PAY"] = "google_pay";
    EPaymentType["PAID"] = "paid";
})(EPaymentType || (EPaymentType = {}));
export var EPaymentStatus;
(function (EPaymentStatus) {
    EPaymentStatus["NEW"] = "new";
    EPaymentStatus["PENDING"] = "pending";
    EPaymentStatus["CONFIRMED"] = "confirmed";
    EPaymentStatus["PENDING_REFUND"] = "pending_refund";
    EPaymentStatus["REFUNDED"] = "refunded";
})(EPaymentStatus || (EPaymentStatus = {}));
