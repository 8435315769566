var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { getOrdersConfig, hasOrders } from '../../app/logic';
import { DISCOUNT_TYPES } from '../../order-fields';
import OtoAlertNew from '../../components/common/OtoAlertNew';
import DiscountIcon from '../../icons/DiscountIcon';
var discountStyle = {
    border: '1px solid var(--primary)',
    borderRadius: 4,
    color: 'var(--primary)',
    cursor: 'pointer',
    display: 'inline-block',
    padding: '3px 6px',
    marginBottom: 8,
};
var RestaurantDiscountLabel = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.customText, customText = _c === void 0 ? null : _c, restaurant = _a.restaurant, _d = _a.isStatic, isStatic = _d === void 0 ? false : _d, _e = _a.fullText, fullText = _e === void 0 ? false : _e;
    if (!hasOrders(restaurant)) {
        return null;
    }
    var texts = customText
        ? [customText]
        : getRestaurantDiscountTexts(restaurant);
    var commonProps = {
        className: className,
        style: discountStyle,
    };
    return (React.createElement(React.Fragment, null, texts.map(function (text) {
        var content = fullText
            ? text
            : "".concat(text.slice(0, 30)).concat(text.length > 30 ? '...' : '');
        if (isStatic) {
            return (React.createElement(OtoAlertNew, { key: text, className: "d-inline-flex ".concat(className), color: "primary", icon: React.createElement(DiscountIcon, null), closable: false, withBorder: true }, content));
        }
        return (React.createElement("a", __assign({ href: "/zamawiaj/".concat(restaurant.slug), key: text }, commonProps, { title: text }), content));
    })));
};
function getRestaurantDiscountTexts(restaurant) {
    var texts = [];
    var _a = getOrdersConfig(restaurant), discountPercent = _a.discountPercent, discountPercentIfTakeout = _a.discountPercentIfTakeout, discountRules = _a.discountRules, discountText = _a.discountText;
    if (discountPercent) {
        texts.push("-".concat(discountPercent, "% na ca\u0142e menu"));
    }
    if (discountPercentIfTakeout) {
        texts.push("-".concat(discountPercentIfTakeout, "% przy odbiorze osobistym"));
    }
    if (discountRules && discountRules.length) {
        texts.push.apply(texts, __spreadArray([], __read(discountRules.map(getDiscountRuleText).filter(Boolean)), false));
    }
    if (discountText) {
        texts.push(discountText);
    }
    return texts;
}
export function getDiscountRuleText(rule) {
    if (rule.type === DISCOUNT_TYPES.SAME_CATEGORY) {
        return "-".concat(rule.percent, "% na 2. produkt w kategorii ").concat(rule.categoryName);
    }
    if (rule.type === DISCOUNT_TYPES.SAME_PRODUCT) {
        return "-".concat(rule.percent, "% na 2. ").concat(rule.productName);
    }
    return null;
}
export default RestaurantDiscountLabel;
