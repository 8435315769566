var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import PropTypes from 'prop-types';
import deliveryShape from './delivery';
import restaurantShape from './restaurant';
import { priceOptionShape } from './restaurant-menu';
import { EPaymentType } from '../enums/payments';
var customerPersonalPart = {
    customer_firstname: PropTypes.string.isRequired,
    customer_lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
};
var customerAddressPart = {
    address: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string.isRequired,
    flat: PropTypes.string,
    house: PropTypes.string.isRequired,
};
var customerMarketingPart = {
    allowEmail: PropTypes.bool.isRequired,
    allowMarketing: PropTypes.bool,
    allowSms: PropTypes.bool.isRequired,
};
export var customerShape = PropTypes.shape(__assign(__assign(__assign(__assign(__assign({}, customerPersonalPart), customerMarketingPart), { cutleryPreferences: PropTypes.oneOf(['yes', 'no']), courrierCalled: PropTypes.string, delivery: PropTypes.string.isRequired, deliveryAt: PropTypes.string }), customerAddressPart), { notes: PropTypes.string, 
    // TODO split these into separate order fields
    customDeliveryPrice: PropTypes.number }));
var commonProductFields = {
    id: PropTypes.number,
    additionalFields: PropTypes.array,
    desc: PropTypes.string,
    image: PropTypes.string,
    isDisabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    notes: PropTypes.string,
    oldPrice: PropTypes.number,
    // option: PropTypes.string.isRequired,
    labels: PropTypes.array,
    price: PropTypes.number.isRequired,
    priceOptions: PropTypes.arrayOf(priceOptionShape),
    selectedAdditionalFields: PropTypes.array, // TODO shape
};
export var productShape = PropTypes.exact(commonProductFields);
export var orderedProductShape = PropTypes.exact(__assign(__assign({}, commonProductFields), { amount: PropTypes.number.isRequired, category: PropTypes.string.isRequired, displayPrice: PropTypes.number, hasDiscount: PropTypes.bool, option: PropTypes.string, voucher_id: PropTypes.number }));
var sourceRelShape = PropTypes.shape({
    app_name: PropTypes.oneOf([
        'bombardino_mobile_app',
        'czarna_owca_mobile_app',
        'lubje_mobile_app',
        'lesznoje_mobile_app',
        'otostolik_mobile_app',
        'web_app',
    ]).isRequired,
    app_version: PropTypes.string,
    id: PropTypes.number.isRequired,
    order_id: PropTypes.number.isRequired,
    platform: PropTypes.oneOf(['mobile_app', 'web_desktop', 'web_mobile'])
        .isRequired,
    tenant: PropTypes.oneOf(['lubje', 'otostolik', 'lesznoje']).isRequired,
});
export var orderShape = PropTypes.shape({
    confirmation_code: PropTypes.string.isRequired,
    confirmed_at: PropTypes.string,
    commission: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    customer: customerShape.isRequired,
    customer_id: PropTypes.number.isRequired,
    delivery: deliveryShape,
    delivery_cost: PropTypes.number.isRequired,
    delivery_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_online_payment: PropTypes.bool.isRequired,
    payment: PropTypes.object,
    payment_id: PropTypes.number.isRequired,
    payment_status: PropTypes.string.isRequired,
    payment_type: PropTypes.oneOf(Object.values(EPaymentType)).isRequired,
    prepare_time: PropTypes.number,
    food_prepare_time: PropTypes.number,
    price: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(orderedProductShape).isRequired,
    promo_code: PropTypes.object,
    promo_code_id: PropTypes.number,
    restaurant: restaurantShape,
    restaurant_id: PropTypes.number.isRequired,
    source: PropTypes.string.isRequired,
    source_rel: sourceRelShape,
    status: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
});
