var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LS_CART_KEY } from '../order-fields';
import { isToday } from './date-helpers';
import { hasLocalStorageAccess } from '../pwa';
import { getWithExpiry, setWithExpiry } from '../utils/local-storage';
export function fixDecimal(num) {
    return Math.round(num * 100) / 100;
}
export function getNewCart(currentCart, dish) {
    var existingIndex = currentCart.findIndex(function (cartItem) {
        if (cartItem.name !== dish.name) {
            return false;
        }
        if (cartItem.category !== dish.category) {
            return false;
        }
        if (cartItem.price !== dish.price) {
            return false;
        }
        if (cartItem.option !== dish.option) {
            return false;
        }
        if (cartItem.selectedAdditionalFields.length &&
            dish.selectedAdditionalFields.length) {
            var selectedAdditionalFieldsThatChange = cartItem.selectedAdditionalFields.filter(function (f, index) {
                return (cartItem.selectedAdditionalFields[index] !==
                    dish.selectedAdditionalFields[index]);
            });
            if (selectedAdditionalFieldsThatChange.length) {
                return false;
            }
        }
        return true;
    });
    var amountToSet = dish.amount || 1;
    if (existingIndex > -1) {
        return __spreadArray(__spreadArray(__spreadArray([], __read(currentCart.slice(0, existingIndex)), false), [
            __assign(__assign({}, currentCart[existingIndex]), { amount: currentCart[existingIndex].amount + amountToSet })
        ], false), __read(currentCart.slice(existingIndex + 1)), false);
    }
    return __spreadArray(__spreadArray([], __read(currentCart), false), [__assign(__assign({}, dish), { amount: amountToSet })], false);
}
// @ts-ignore
export function isDeliveryClosed(deliveryHours, date) {
    if (deliveryHours === void 0) { deliveryHours = {}; }
    if (date === void 0) { date = new Date(); }
    var open = deliveryHours.open, close = deliveryHours.close, isClosed = deliveryHours.isClosed;
    if (!open || !close) {
        return false;
    }
    if (isClosed) {
        return true;
    }
    var currentHour = date.getHours();
    var currentMinutes = currentHour * 60 + date.getMinutes();
    var openMinutes = open.hour * 60 + (open.min || 0);
    var closeMinutes = close.hour * 60 + (close.min || 0);
    var closesAfterMidnight = close.hour < open.hour;
    if (closesAfterMidnight) {
        if (currentHour >= open.hour ||
            currentHour < close.hour ||
            (currentHour === close.hour && currentMinutes <= close.min)) {
            return false;
        }
        if (currentHour < open.hour || currentHour > close.hour) {
            return true;
        }
        if (isToday(date)) {
            return false;
        }
    }
    if (currentMinutes < openMinutes) {
        return true;
    }
    if (currentMinutes > closeMinutes) {
        return true;
    }
    return false;
}
var CART_TTL = 3 * 60 * 60 * 1000; // 3 hours
var getCartKey = function (restaurant) { return "".concat(LS_CART_KEY, "-").concat(restaurant.id); };
export var getCart = function (restaurant) {
    return hasLocalStorageAccess() ? getWithExpiry(getCartKey(restaurant)) : null;
};
export var setCart = function (restaurant, items) {
    return hasLocalStorageAccess() &&
        setWithExpiry(getCartKey(restaurant), items, CART_TTL);
};
export var clearCart = function (restaurant) {
    return hasLocalStorageAccess() && localStorage.removeItem(getCartKey(restaurant));
};
