import React from 'react';
import ArrowUp from '../../icons/ArrowUp';

import { debounce } from '../../app/lodash-cut';

class ScrollToTop extends React.PureComponent {
  state = {
    wasArrowTopVisible: false,
    btnStyle: { display: 'none' }
  }
  componentDidMount() {
    this.debouncedScroll = debounce(this.handleScroll, 500);
    window.addEventListener('scroll', this.debouncedScroll);
  }

  handleScroll = () => {
    const { wasArrowTopVisible } = this.state;
    let shouldArrowTopBeVisible = window.scrollY > window.outerHeight;
    if (wasArrowTopVisible !== shouldArrowTopBeVisible) {
      const display = shouldArrowTopBeVisible ? 'block' : 'none';
      this.setState({
        wasArrowTopVisible: shouldArrowTopBeVisible,
        btnStyle: { display }
      })
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedScroll);
  }

  static scroll(e, scrollDuration = 700) {
    const scrollStep = -window.scrollY / (scrollDuration / 15);
    const scrollInterval = setInterval(function () {
      if (window.scrollY != 0) {
        window.scrollBy(0, scrollStep);
      }
      else clearInterval(scrollInterval);
    }, 15);
  }

  render() {
    return <button
      type="button"
      onClick={ScrollToTop.scroll}
      className="fab-btn"
      style={this.state.btnStyle}>
      <ArrowUp />
      {this.props.text ? <span>&nbsp;{this.props.text}</span> : null}
    </button>
  }
}

ScrollToTop.displayName = 'ScrollToTop';

export default ScrollToTop;

