import LogService from 'Utils/log';
import { APIService } from 'App/services/api';
export function getRestaurants(appMode, deliveryMode, options) {
    if (options === void 0) { options = {}; }
    var address = options.address, cityId = options.cityId, coordinates = options.coordinates;
    var isBooking = appMode === 'booking';
    var isOrder = appMode === 'order';
    var urlMode = isBooking ? appMode : deliveryMode;
    var coordsPart = coordinates && coordinates.lng && coordinates.lat
        ? "&lat=".concat(coordinates.lat, "&lng=").concat(coordinates.lng, "&long=").concat(coordinates.lng)
        : '';
    var modeBasedParams = isBooking
        ? "&city=".concat(cityId)
        : "".concat(coordsPart, "&address=").concat(address);
    var groupBy = isOrder ? '&group=promotions' : '';
    var url = "/restaurants?mode=".concat(urlMode).concat(modeBasedParams, "&menu=false").concat(groupBy);
    return APIService.get(url)
        .then(function (response) { return response.json(); })
        .then(function (data) {
        return !!groupBy
            ? {
                restaurants: (data.find(function (item) { return item.type === 'restaurants'; }) || {})
                    .restaurants || [],
                promotions: data.filter(function (item) { return item.type === 'promotion'; }),
            }
            : {
                restaurants: data,
            };
    })
        .catch(function (e) {
        LogService.fetchError({
            actionName: 'WEB getRestaurants error',
            e: e,
            url: url,
        });
        throw new Error(e);
    });
}
