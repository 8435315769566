var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import PropTypes from 'prop-types';
import { ADDITIONAL_FIELD_TYPES } from 'App/config';
var menuItemLabelShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
});
export var menuItemAdditionalFieldShape = PropTypes.shape({
    addonPrice: PropTypes.number,
    name: PropTypes.string.isRequired,
    max: PropTypes.number,
    type: PropTypes.oneOf(Object.values(ADDITIONAL_FIELD_TYPES)).isRequired,
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
});
export var priceOptionShape = PropTypes.exact({
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    oldPrice: PropTypes.number,
});
export var menuItemDailyAvailabilityShape = PropTypes.shape({
    hourFrom: PropTypes.number,
    hourTo: PropTypes.number,
    minuteFrom: PropTypes.number,
    minuteTo: PropTypes.number,
    isAvailable: PropTypes.bool.isRequired,
    isFullDay: PropTypes.bool.isRequired,
}).isRequired;
export var menuItemAvailabilityShape = PropTypes.shape({
    mon: menuItemDailyAvailabilityShape,
    tue: menuItemDailyAvailabilityShape,
    wed: menuItemDailyAvailabilityShape,
    thu: menuItemDailyAvailabilityShape,
    fri: menuItemDailyAvailabilityShape,
    sat: menuItemDailyAvailabilityShape,
    sun: menuItemDailyAvailabilityShape,
});
var menuItemBaseFields = {
    id: PropTypes.number,
    desc: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    price: PropTypes.number.isRequired,
    oldPrice: PropTypes.number,
    labels: PropTypes.arrayOf(menuItemLabelShape.isRequired),
    isDisabled: PropTypes.bool.isRequired,
    additionalFields: PropTypes.arrayOf(menuItemAdditionalFieldShape.isRequired),
    priceOptions: PropTypes.arrayOf(priceOptionShape.isRequired),
    availability: menuItemAvailabilityShape,
};
export var menuItemShape = PropTypes.shape(menuItemBaseFields);
export var menuItemWithCategoryShape = PropTypes.shape(__assign(__assign({}, menuItemBaseFields), { category: PropTypes.string.isRequired }));
export var categoryShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(menuItemWithCategoryShape.isRequired).isRequired,
    text: PropTypes.string,
});
var menuShape = PropTypes.shape({
    categories: PropTypes.arrayOf(categoryShape.isRequired).isRequired,
});
export default menuShape;
