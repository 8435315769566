var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import './oto-alert.scss';
var OtoAlertNew = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.color, color = _c === void 0 ? 'primary' : _c, _d = _a.closable, closable = _d === void 0 ? true : _d, icon = _a.icon, _e = _a.withBorder, withBorder = _e === void 0 ? false : _e;
    var _f = __read(useState(true), 2), isOpen = _f[0], setIsOpen = _f[1];
    if (!isOpen) {
        return null;
    }
    var close = function () { return setIsOpen(false); };
    return (React.createElement("div", { className: "oto-alert oto-alert--".concat(color, " ").concat(className, " ").concat(withBorder ? 'oto-alert--with-border' : '') },
        icon ? (React.createElement("div", { className: "d-flex flex-row align-items-center" },
            icon,
            " ",
            children)) : (children),
        closable && (React.createElement("img", { src: "/images/alert-close-button.svg", alt: "zamknij komunikat", className: "oto-alert__close", onClick: close, tabIndex: 0, role: "button" }))));
};
export default OtoAlertNew;
