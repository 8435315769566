var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import PropTypes from 'prop-types';
import { getIsOpenState, getWeekDayIndex, isDaySame, isToday, } from '../../app/date-helpers';
import { isBookingPartner, isDebugMode } from '../../app/logic';
import { reenableRestaurantLabelPopover } from '../../app/ui';
import Shapes, { instantBookingsShape } from '../../app/shapes/main';
import LogService from '../../utils/log';
import { getSelectedDayOpeningHours, isRestaurantDimmed } from '../../utils/restaurant';
import { isActive } from 'Utils/restaurant-filtering';
import RestaurantButtonsAndAlerts from './buttons/RestaurantBookingButtonsAndAlerts';
import RestaurantCuisine from './RestaurantCuisine';
import RestaurantLocation from './RestaurantLocation';
import RestaurantDiscountLabel from './RestaurantDiscountLabel';
import RestaurantDeliveryHours from './RestaurantDeliveryHours';
import RestaurantOpeningHours from './RestaurantOpeningHours';
import RestaurantDeliveryDetails from './RestaurantDeliveryDetails';
import RestaurantTakeoutDetails from './RestaurantTakeoutDetails';
import { OTO_PRODUCTS } from '../../config';
import RestaurantThumbnail from './RestaurantThumbnail';
import { DeliveryTypes } from 'App/order-fields';
import RestaurantTitle from './RestaurantTitle';
import './restaurant.scss';
var Restaurant = /** @class */ (function (_super) {
    __extends(Restaurant, _super);
    function Restaurant() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Restaurant.prototype.shouldComponentUpdate = function (nextProps) {
        var _this = this;
        if (!isDaySame(this.props.visitDate, nextProps.visitDate)) {
            return true;
        }
        var keysThatChanged = [
            'filter',
            'visitTime',
            'restaurant',
            'isOrderMode',
            'deliversToSelectedAddress',
        ].filter(function (key) { return _this.props[key] !== nextProps[key]; });
        if (keysThatChanged.length) {
            return true;
        }
        if (isBookingPartner(this.props.restaurant) ||
            isBookingPartner(nextProps.restaurant)) {
            var partnerKeysThatChanged = ['peopleAmount', 'instantBookings'].filter(function (key) { return _this.props[key] !== nextProps[key]; });
            if (partnerKeysThatChanged.length) {
                setTimeout(reenableRestaurantLabelPopover, 0);
                return true;
            }
        }
        return false;
    };
    Restaurant.prototype.render = function () {
        var _a = this.props, deliversToSelectedAddress = _a.deliversToSelectedAddress, filter = _a.filter, isOrderMode = _a.isOrderMode, deliveryMode = _a.deliveryMode, restaurant = _a.restaurant, visitDate = _a.visitDate;
        var cuisine_label = restaurant.cuisine_label, name = restaurant.name, opening_hours = restaurant.opening_hours;
        var selectedDayHours = isOrderMode
            ? null
            : getSelectedDayOpeningHours(opening_hours, visitDate);
        if (restaurant.status === 'debug' && !isDebugMode()) {
            return null;
        }
        var isTakeout = deliveryMode === DeliveryTypes.takeout;
        var _b = isRestaurantDimmed(restaurant, isOrderMode, { deliversToSelectedAddress: deliversToSelectedAddress }), isDimmed = _b.isDimmed, dimmedText = _b.dimmedText, dimmedBtnText = _b.dimmedBtnText;
        var className = "restaurant \n      ".concat(isActive(restaurant, filter) ? 'visible' : '', "\n      ").concat(isOrderMode ? 'restaurant--order-mode' : 'restaurant--booking-mode', "\n      ").concat(isDimmed ? 'restaurant--dimmed' : '');
        if (!cuisine_label ||
            (cuisine_label.length === 1 && cuisine_label[0].includes(','))) {
            LogService.activity("".concat(name, " cuisine label is missing or has incorrect structure"));
        }
        return (React.createElement("article", { className: className },
            !isOrderMode && (React.createElement(RestaurantTitle, { isOrderMode: isOrderMode, restaurant: restaurant, showInstantBookLabel: true })),
            React.createElement(RestaurantThumbnail, { isOrderMode: isOrderMode, restaurant: restaurant }),
            React.createElement("section", { className: "restaurant__details" },
                isOrderMode && (React.createElement(RestaurantTitle, { isOrderMode: isOrderMode, restaurant: restaurant })),
                isOrderMode && (React.createElement(RestaurantDiscountLabel, { restaurant: restaurant, className: "mr-1 mb-1" })),
                React.createElement(RestaurantCuisine, { cuisine: cuisine_label, onClick: this.props.handleCuisineClick }),
                (!isOrderMode || isTakeout) && (React.createElement(RestaurantLocation, { location: restaurant.location })),
                isOrderMode && isTakeout && (React.createElement(RestaurantDeliveryHours, { restaurant: restaurant })),
                !isOrderMode && (React.createElement(RestaurantOpeningHours, { restaurant: restaurant, selectedDayHours: selectedDayHours })),
                isOrderMode && (React.createElement(React.Fragment, null, isTakeout ? (React.createElement(RestaurantTakeoutDetails, { restaurant: restaurant, wrapperClass: "restaurant__order-details mb-2" })) : (React.createElement(RestaurantDeliveryDetails, { basketStatus: null, restaurant: restaurant, wrapperClass: "restaurant__order-details mb-2" })))),
                React.createElement("div", { className: "md-plus flex-column w-100" }, !isDimmed && this.renderButtonsAndAlerts(selectedDayHours))),
            React.createElement("div", { className: "md-minus flex-column w-100" }, !isDimmed && this.renderButtonsAndAlerts(selectedDayHours)),
            isDimmed && (React.createElement("div", { className: "restaurant__overlay" },
                React.createElement("div", { className: "restaurant__overlay-title" }, dimmedText),
                React.createElement("a", { href: "/zamawiaj/".concat(restaurant.slug), className: "restaurant__overlay-link" }, dimmedBtnText)))));
    };
    Restaurant.prototype.renderButtonsAndAlerts = function (selectedDayHours) {
        if (this.props.isOrderMode) {
            return null;
        }
        var _a = this.props, canBookNonPartner = _a.canBookNonPartner, handleReservationClick = _a.handleReservationClick, instantBookings = _a.instantBookings, restaurant = _a.restaurant, peopleAmount = _a.peopleAmount, visitDate = _a.visitDate, visitTime = _a.visitTime;
        return (React.createElement(RestaurantButtonsAndAlerts, { canBookNonPartner: canBookNonPartner, selectedDayHours: selectedDayHours, instantBookings: instantBookings, peopleAmount: peopleAmount, visitDate: visitDate, visitTime: visitTime, restaurant: restaurant, handleReservationClick: handleReservationClick }));
    };
    Restaurant.checkAdvance = function (restaurant, restaurantAvailability, visitDate) {
        if (restaurant.products.includes(OTO_PRODUCTS.reservations) &&
            restaurant.config.config.allow_reservations_right_before_opening &&
            isToday(visitDate) &&
            !getIsOpenState(restaurant.opening_hours[getWeekDayIndex(new Date())], new Date().getHours()).isOpen) {
            return {
                tooLittleAdvance: false,
                timesThatMatchHourAdvance: (restaurantAvailability && restaurantAvailability.availability) || [],
            };
        }
        var restaurantConfig = (restaurantAvailability && restaurantAvailability.config) || {};
        var allInstantBookTimes = (restaurantAvailability && restaurantAvailability.availability) || [];
        var autoConfirmBeforeHours = parseInt(restaurantConfig.auto_confirm_before);
        if (autoConfirmBeforeHours >= 24 && isToday(visitDate)) {
            return {
                tooLittleAdvance: true,
            };
        }
        var hour = new Date().getHours();
        var timesThatMatchHourAdvance = isToday(visitDate)
            ? allInstantBookTimes.filter(function (_a) {
                var time = _a.time;
                return parseInt(time, 10) - hour >= autoConfirmBeforeHours;
            })
            : allInstantBookTimes;
        if (allInstantBookTimes.length && !timesThatMatchHourAdvance.length) {
            return {
                tooLittleAdvance: true,
            };
        }
        return {
            tooLittleAdvance: false,
            timesThatMatchHourAdvance: timesThatMatchHourAdvance,
        };
    };
    Restaurant.displayName = 'Restaurant';
    Restaurant.propTypes = {
        canBookNonPartner: PropTypes.bool.isRequired,
        deliversToSelectedAddress: PropTypes.bool.isRequired,
        restaurant: Shapes.restaurantShape.isRequired,
        instantBookings: instantBookingsShape,
        filter: PropTypes.object,
        visitDate: PropTypes.instanceOf(Date).isRequired,
        visitTime: PropTypes.string,
        peopleAmount: PropTypes.number,
        handleCuisineClick: PropTypes.func.isRequired,
        handleReservationClick: PropTypes.func,
        isOrderMode: PropTypes.bool.isRequired,
        deliveryMode: PropTypes.oneOf([DeliveryTypes.usual, DeliveryTypes.takeout])
            .isRequired,
    };
    Restaurant.defaultProps = {
        filter: {
            name: '',
            cuisine: '',
        },
    };
    return Restaurant;
}(React.Component));
export default Restaurant;
