import React from 'react';
import PropTypes from 'prop-types';
import './oto-hashtag.scss';
var OtoHashTag = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, tag = _a.tag, onClick = _a.onClick, onRemove = _a.onRemove;
    var wrapperClass = "oto-hashtag ".concat(className, " ").concat(onClick ? 'oto-hashtag--clickable' : '', " ").concat(onRemove ? 'oto-hashtag--removable' : '');
    return React.createElement("div", { className: wrapperClass, onClick: onClick },
        React.createElement("span", { className: "oto-hashtag__content" }, tag),
        onRemove && React.createElement("button", { type: "button", "aria-label": "Usu\u0144 filtrowanie ".concat(tag), className: "oto-hashtag__button", onClick: onRemove }, "x"));
};
OtoHashTag.propTypes = {
    className: PropTypes.string,
    tag: PropTypes.element.isRequired,
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
};
OtoHashTag.displayName = 'OtoHashTag';
export default OtoHashTag;
