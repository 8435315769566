var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { slugify } from '../../app/general';
import { debounce } from '../../app/lodash-cut';
import { getAvailableCuisineTypes, AppMode } from '../../config';
import { AnalyticsService } from '../../utils/tracking';
import OtoHashTag from 'App/components/common/OtoHashTag';
import EditRegular from 'App/icons/EditRegular';
import Search from 'App/icons/Search';
import ChevronUp from 'App/icons/ChevronUp';
import { CuisineTypesIconSet } from 'Icons/IconSets';
import { APIService } from 'App/services/api';
import LogService from 'Utils/log';
import OtoSpinner from 'Comps/common/OtoSpinner';
import Shapes from 'Shapes';
var RestaurantListSidebar = /** @class */ (function (_super) {
    __extends(RestaurantListSidebar, _super);
    function RestaurantListSidebar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            cuisineTypesLoading: false,
            cuisineTypes: null,
            hasError: false,
            showAllCuisineTypes: false,
            visibleName: '',
        };
        _this.getMemoizedSortedCuisineTypes = memoizeOne(getAllCuisineTypesSorted);
        _this.debouncedFilter = debounce(_this.props.handleFilterChange, 200);
        _this.fetchCuisineTypes = function () {
            _this.setState({ cuisineTypesLoading: true });
            var url = "/restaurants/cuisine-types?mode=".concat(_this.props.mode);
            APIService.get(url)
                .then(function (response) { return response.json(); })
                .then(function (cuisineTypes) {
                _this.setState({
                    cuisineTypesLoading: false,
                    cuisineTypes: cuisineTypes,
                });
            })
                .catch(function (e) {
                LogService.fetchError({
                    url: url,
                    e: e,
                    actionName: 'RestaurantListSidebar cuisine types',
                });
                _this.setState({ cuisineTypesLoading: false });
            });
        };
        _this.onCuisineChange = function (cuisine) {
            _this.props.handleFilterChange(__assign(__assign({}, _this.props.filter), { cuisine: cuisine }));
            AnalyticsService.trackCuisineFilterClick(cuisine);
        };
        _this.onNameChange = function (event) {
            var searchedName = event.target.value;
            _this.setState({ visibleName: searchedName });
            _this.debouncedFilter(__assign(__assign({}, _this.props.filter), { name: slugify(event.target.value) }));
        };
        _this.reloadWithNewCity = function (e) {
            return (window.location.href = "/restaurants?mode=".concat(_this.props.mode, "&city=").concat(e.target.value));
        };
        return _this;
    }
    RestaurantListSidebar.prototype.componentDidMount = function () {
        this.fetchCuisineTypes();
    };
    RestaurantListSidebar.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.filter.name &&
            !this.props.filter.name &&
            this.state.visibleName) {
            this.setState({ visibleName: '' });
        }
    };
    RestaurantListSidebar.prototype.render = function () {
        return (React.createElement("aside", { className: "sidebar ".concat(this.props.className) },
            React.createElement("h4", { className: "sidebar__title collapsed", role: "button", "data-toggle": "collapse", "data-target": "#sidebarContent", "aria-expanded": "true", "aria-controls": "sidebarContent" },
                "Filtry",
                React.createElement(ChevronUp, null)),
            React.createElement("div", { className: "collapse sidebar__content", id: "sidebarContent" },
                this.props.mode == AppMode.RESERVATION
                    ? this.renderCitiesFilter()
                    : '',
                this.renderNameFilter(),
                this.renderCuisineFilter())));
    };
    RestaurantListSidebar.prototype.renderCitiesFilter = function () {
        if (!this.props.cities) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "sidebar__label" }, "Miasto"),
            React.createElement("select", { className: "form-control", value: this.props.cityId, onChange: this.reloadWithNewCity }, this.props.cities.map(function (c) { return (React.createElement("option", { key: c.id, value: c.id }, c.name)); }))));
    };
    RestaurantListSidebar.prototype.renderNameFilter = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("label", { htmlFor: "restaurant-name", className: "sidebar__label" }, "Nazwa lokalu"),
            React.createElement("div", { className: "input-group" },
                React.createElement("div", { className: "input-group-prepend" },
                    React.createElement("span", { className: "input-group-text", id: "addon-search" },
                        React.createElement(Search, null))),
                React.createElement("input", { type: "text", value: this.state.visibleName, onChange: this.onNameChange, name: "restaurant-name", className: "form-control", placeholder: "Wyszukaj wg nazwy", "aria-label": "Wyszukaj wg nazwy", "aria-describedby": "addon-search" }))));
    };
    RestaurantListSidebar.prototype.renderCuisineFilter = function () {
        var _this = this;
        var _a = this.props, filter = _a.filter, mode = _a.mode, restaurants = _a.restaurants;
        var _b = this.state, cuisineTypes = _b.cuisineTypes, cuisineTypesLoading = _b.cuisineTypesLoading, showAllCuisineTypes = _b.showAllCuisineTypes;
        var clearBtn = (React.createElement("button", { className: "btn btn-link p-0 lh-1", type: "button", onClick: function () { return _this.onCuisineChange(''); } },
            React.createElement(EditRegular, null),
            " ",
            React.createElement("span", null, "Wyczy\u015B\u0107")));
        var cuisineTypesToShow = showAllCuisineTypes
            ? this.getMemoizedSortedCuisineTypes(restaurants)
            : cuisineTypes || getAvailableCuisineTypes(mode);
        return (React.createElement(React.Fragment, null,
            React.createElement("label", { htmlFor: "cuisine", className: "sidebar__label d-flex flex-row vertical-center justify-content-between" },
                "Typ kuchni ",
                !!filter.cuisine && clearBtn),
            React.createElement("div", { className: "d-flex flex-row flex-wrap" },
                cuisineTypesLoading && React.createElement(OtoSpinner, { center: true, className: "mb-3" }),
                cuisineTypesToShow.map(function (cuisine) { return (React.createElement(OtoHashTag, { key: cuisine, tag: React.createElement("div", null,
                        CuisineTypesIconSet[cuisine] || null,
                        " ",
                        cuisine), onClick: filter.cuisine !== cuisine
                        ? function () { return _this.onCuisineChange(cuisine); }
                        : undefined, onRemove: filter.cuisine === cuisine
                        ? function () { return _this.onCuisineChange(''); }
                        : undefined })); }),
                !showAllCuisineTypes && (React.createElement("button", { type: "button", className: "btn btn-link p-0", onClick: function () { return _this.setState({ showAllCuisineTypes: true }); } }, "wi\u0119cej typ\u00F3w kuchni")))));
    };
    RestaurantListSidebar.displayName = 'RestaurantListSidebar';
    RestaurantListSidebar.propTypes = {
        cityId: PropTypes.number.isRequired,
        className: PropTypes.string,
        cities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })),
        filter: PropTypes.object.isRequired,
        mode: PropTypes.string.isRequired,
        handleFilterChange: PropTypes.func.isRequired,
        restaurants: PropTypes.arrayOf(Shapes.restaurantShape),
    };
    RestaurantListSidebar.defaultProps = {
        className: '',
    };
    return RestaurantListSidebar;
}(React.PureComponent));
export default RestaurantListSidebar;
function getAllCuisineTypesSorted(restaurants) {
    var sortEntries = function (_a, _b) {
        var _c = __read(_a, 2), aLabel = _c[0], aAmount = _c[1];
        var _d = __read(_b, 2), bLabel = _d[0], bAmount = _d[1];
        return aAmount > bAmount ? -1 : 1;
    };
    var counted = (restaurants || [])
        .map(function (r) { return r.cuisine_label || []; })
        .flat()
        .reduce(function (dict, label) {
        dict[label] = (dict[label] || 0) + 1;
        return dict;
    }, {});
    return Object.entries(counted)
        .sort(sortEntries)
        .map(function (_a) {
        var _b = __read(_a, 2), label = _b[0], amount = _b[1];
        return label;
    });
}
