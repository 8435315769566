import React from 'react';
import { UncontrolledAlert as Alert } from 'reactstrap';
import { getWeekDayIndex, getIsOpenState } from '../app/date-helpers';
import { getIsWorkingHours } from '../app/time-helpers';
import { NOT_WORKING_HOURS, RESTAURANT_CLOSED } from '../app/lang';
import { OTO_PRODUCTS } from 'App/config';
import Shapes from 'Shapes';
var ReservationRestaurantClosedAlert = function (props) {
    var restaurant = props.restaurant;
    if (!!restaurant.products && restaurant.products.includes(OTO_PRODUCTS.reservations)) {
        return null;
    }
    if (getIsWorkingHours()) {
        try {
            var todayOpeningHours = restaurant.opening_hours[getWeekDayIndex(new Date())];
            var todayOpeningHoursTimeOnly = todayOpeningHours.split(' ')[1];
            var hour = (new Date()).getHours();
            var openState = getIsOpenState(todayOpeningHours, hour);
            if (openState.isValid && !openState.isOpen) {
                return React.createElement(Alert, { color: "warning" }, RESTAURANT_CLOSED(restaurant.name, todayOpeningHoursTimeOnly));
            }
            return null;
        }
        catch (e) {
            console.error('cannot parse hours for: ', restaurant.name, e);
            return null;
        }
    }
    return React.createElement(Alert, { color: "warning" },
        React.createElement("strong", null, "Uwaga: "),
        NOT_WORKING_HOURS);
};
ReservationRestaurantClosedAlert.propTypes = {
    // @ts-ignore todo unify shapes & intefaces
    restaurant: Shapes.restaurantShape.isRequired
};
export default ReservationRestaurantClosedAlert;
