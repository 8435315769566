var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import Trash from 'Icons/Trash';
import { getLastUsedDeliveryAddresses, removeLastUsedDeliveryAddress, } from 'Utils/location';
var LastUsedAddresses = function (props) {
    var _a = __read(useState([]), 2), lastUsedAddresses = _a[0], setLastUsedAddressed = _a[1];
    useEffect(function () {
        setLastUsedAddressed(getLastUsedDeliveryAddresses());
    }, []);
    var removeAddress = function (address) {
        setLastUsedAddressed(lastUsedAddresses.filter(function (i) { return i != address; }));
        removeLastUsedDeliveryAddress(address);
    };
    var onSelect = props.onSelect;
    if (!(lastUsedAddresses === null || lastUsedAddresses === void 0 ? void 0 : lastUsedAddresses.length)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "h5 mt-4 px-1" }, "Ostatnio u\u017Cywane adresy:"),
        lastUsedAddresses.map(function (addr) { return (React.createElement("div", { key: addr, className: "row m-0 justify-content-between menu-item" },
            React.createElement("span", { role: "button", onClick: function () { return onSelect(addr); } }, addr),
            React.createElement("button", { type: "button", className: "btn btn-link p-0", onClick: function () { return removeAddress(addr); } },
                React.createElement(Trash, null)))); })));
};
export default LastUsedAddresses;
