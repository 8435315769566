import filterXss from 'xss'
import 'url-search-params-polyfill'

export function getCityId() {
  const urlParams = new URLSearchParams(filterXss(window.location.search))
  return parseInt(urlParams.get('city'), 10)
}

export function getSafeUrlParam(str) {
  const urlParams = new URLSearchParams(filterXss(window.location.search))
  return urlParams.get(str)
}

export function isIframe() {
  const pathParts = filterXss(window.location.pathname).split('/')
  const isMenuPageIframe =
    pathParts[1] === 'restaurants' && pathParts[3] === 'iframe'
  const isOrderPageIframe =
    pathParts[1] === 'order' && pathParts[3] === 'iframe'
  const hasDifferentWindows = window !== window.parent
  return isMenuPageIframe || isOrderPageIframe || hasDifferentWindows
}

export const getAppSource = () => (isIframe() ? 'iframe' : 'web_app')
