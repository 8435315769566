import React from 'react';
import PropTypes from 'prop-types';

import { MODES } from '../../config';
import { ModeTogglerWrapper, ModeItem } from '../common/SelectMode';

const ModeToggler = props => {
  const { activeMode, className, fixed, setMode } = props;
  return <ModeTogglerWrapper className={className} fixed={fixed}>
    {
      MODES.map(mode => <ModeItem
        key={mode.name}
        isActive={activeMode === mode.name}
        onClick={() => setMode(mode.name)}>
        {mode.label}
      </ModeItem>)
    }
  </ModeTogglerWrapper>
}

ModeToggler.propTypes = {
  activeMode: PropTypes.string.isRequired,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  setMode: PropTypes.func.isRequired,
}

ModeToggler.defaultProps = {
  className: '',
  fixed: false,
}

export default ModeToggler;
