import React from 'react';
import OtoAlert from 'Comps/common/OtoAlert';
import { getBookingConfig } from 'App/app/general';
var ReservationTooBigGroupAlert = function (props) {
    var maxPeopleAmount = props.maxPeopleAmount, restaurant = props.restaurant;
    var message = "Dla rezerwacji powy\u017Cej ".concat(maxPeopleAmount, " os\u00F3b, restauracja prosi o kontakt telefoniczny pod numerem: ").concat(restaurant.phone);
    var auto_reservation_max_guests_label = getBookingConfig(restaurant).auto_reservation_max_guests_label;
    if (auto_reservation_max_guests_label) {
        message = auto_reservation_max_guests_label.replace('$phone', restaurant.phone);
    }
    return React.createElement(OtoAlert, { text: message });
};
export default ReservationTooBigGroupAlert;
