import PropTypes from 'prop-types';
var deliveryAreaOptionShape = PropTypes.exact({
    price: PropTypes.number.isRequired,
    minCartAmount: PropTypes.number.isRequired,
    freeDeliveryFrom: PropTypes.number.isRequired,
});
var deliveryConfigShape = PropTypes.exact({
    area: PropTypes.any,
    areaOptions: PropTypes.arrayOf(deliveryAreaOptionShape.isRequired).isRequired,
    minCartAmount: PropTypes.number,
    price: PropTypes.number,
    freeDeliveryFrom: PropTypes.number,
    maxKmDistance: PropTypes.number,
    dynamicDeliveryPricing: PropTypes.bool,
    baseDeliveryKm: PropTypes.number,
    extraKmCost: PropTypes.number,
    // TODO make these requireable
    lat: PropTypes.number,
    long: PropTypes.number,
});
var ordersConfigShape = PropTypes.exact({
    allowOrders: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    allowOrdersChangedAt: PropTypes.string,
    allowedDeliveryOptionKeys: PropTypes.array,
    allowedPaymentOptionKeys: PropTypes.array,
    allowedDeliveryPaymentOptionKeys: PropTypes.array,
    allowedTakeoutPaymentOptionKeys: PropTypes.array,
    alertText: PropTypes.string,
    askForCutlery: PropTypes.bool,
    delivery: deliveryConfigShape.isRequired,
    deliveryTime: PropTypes.number,
    deliveryHours: PropTypes.array.isRequired,
    dynamicDeliveryText: PropTypes.string,
    discountPercent: PropTypes.number,
    discountPercentIfTakeout: PropTypes.number,
    discountText: PropTypes.string,
    // todo refactor - it's usually rules or categories/products
    discountRules: PropTypes.array,
    discountCategories: PropTypes.array,
    discountProducts: PropTypes.array,
    roundDiscount: PropTypes.bool,
    takeoutTime: PropTypes.number,
});
export default ordersConfigShape;
