import React from 'react';
import { getDynamicDeliveryText, getFreeDeliveryFrom, getMinCartAmountText, getMinimumDeliveryPrice, getStaticDeliveryPrice, hasDynamicDeliveryPrice, } from '../../utils/restaurant';
import { currency } from '../../config';
import Car from '../../icons/Car';
import CartIcon from '../../icons/CartIcon';
import TimeOutlined from '../../icons/TimeOutlined';
import IconWithPopover from '../common/IconWithPopover';
import './restaurant-order-details.scss';
var RestaurantDeliveryDetails = function (props) {
    var basketStatus = props.basketStatus, restaurant = props.restaurant, showFreeDeliveryFrom = props.showFreeDeliveryFrom, wrapperClass = props.wrapperClass;
    var ordersConfig = restaurant.config.orders;
    if (restaurant.delivering === false ||
        (!!restaurant.currentArea && !restaurant.currentArea.delivering)) {
        return null;
    }
    var freeDeliveryFrom = getFreeDeliveryFrom(restaurant, basketStatus);
    var freeDeliveryLabel = !!freeDeliveryFrom && freeDeliveryFrom > 0
        ? ", od ".concat(freeDeliveryFrom, " ").concat(currency, " za darmo")
        : '';
    var deliveryPriceLabel = !basketStatus &&
        !restaurant.currentArea &&
        hasDynamicDeliveryPrice(ordersConfig) ? (React.createElement("div", { className: "d-flex flex-row" },
        "od ",
        getMinimumDeliveryPrice(restaurant),
        " ",
        currency,
        React.createElement(IconWithPopover, { className: "text-primary ml-2", title: "Szczeg\u00F3\u0142y dostawy", text: getDynamicDeliveryText(ordersConfig, true) }))) : ("".concat(getStaticDeliveryPrice(restaurant, basketStatus), " ").concat(currency));
    return (React.createElement("div", { className: wrapperClass },
        React.createElement("div", { className: "restaurant__order-details-item" },
            React.createElement(TimeOutlined, { className: "text-primary" }),
            " ",
            ordersConfig.deliveryTime,
            ' ',
            "min.",
            ' ',
            React.createElement(IconWithPopover, { className: "ml-2", text: "\u015Aredni czas na otrzymanie zam\u00F3wienia z dostaw\u0105. Ten czas nie jest gwarancj\u0105 i mo\u017Ce si\u0119 r\u00F3\u017Cni\u0107 w zale\u017Cno\u015Bci od nat\u0119\u017Cenia ruchu oraz z\u0142o\u017Cono\u015Bci zam\u00F3wienia." })),
        React.createElement("div", { className: "restaurant__order-details-item" },
            React.createElement(Car, { className: "text-primary" }),
            " Koszt dostawy: ",
            deliveryPriceLabel,
            freeDeliveryLabel),
        React.createElement("div", { className: "restaurant__order-details-item" },
            React.createElement(CartIcon, null),
            " Min. koszyk:",
            ' ',
            getMinCartAmountText(restaurant, basketStatus),
            " ",
            currency)));
};
RestaurantDeliveryDetails.displayName = 'RestaurantDeliveryDetails';
export default RestaurantDeliveryDetails;
