import React from 'react';

import Clock from '../../icons/Clock';

const RestaurantOpeningHours = ({ className = 'mb-2', restaurant, selectedDayHours }) => {
  const { name, opening_hours: hours } = restaurant;
  if (!hours || !hours.length) {
    return null;
  }
  const content = `<div>${hours.join('</div><div>')}</div>`;
  return (<div className={`${className} restaurant__opening-hours`}>
    <Clock />
    &nbsp;
    <span>Godziny otwarcia {selectedDayHours}</span>
    &nbsp;
    <a tabIndex="0" role="button" className="btn btn-link restaurant__show-hours" data-toggle="popover" data-placement="bottom" data-trigger="focus" data-html="true" title={`Godziny otwarcia ${name}`} data-content={content}>inne dni</a>
  </div>);
};

RestaurantOpeningHours.displayName = 'RestaurantOpeningHours';

export default RestaurantOpeningHours;
