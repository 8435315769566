import { DEFAULT_VALUES } from '../config';
export var omit = function (obj, arr) {
    return Object.keys(obj)
        .filter(function (k) { return !arr.includes(k); })
        .reduce(function (acc, key) { return ((acc[key] = obj[key]), acc); }, {});
};
export var slugify = function (str) {
    if (str === void 0) { str = ''; }
    return str
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/\./g, '')
        .replace(/\,/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/\//g, '')
        .replace(/\!/g, '')
        .replace(/\?/g, '')
        .replace(/\+/g, '')
        .replace(/\*/g, '')
        .replace(/\&/g, '')
        .replace(/\"/g, '')
        .replace(/\'/g, '')
        .replace(/ą/g, 'a')
        .replace(/ę/g, 'e')
        .replace(/ó/g, 'o')
        .replace(/ō/g, 'o')
        .replace(/ł/g, 'l')
        .replace(/ń/g, 'n')
        .replace(/ś/g, 's')
        .replace(/ć/g, 'c')
        .replace(/ź/g, 'z')
        .replace(/ż/g, 'z');
};
export function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export var getBookingConfig = function (restaurant) {
    return restaurant && restaurant.config && restaurant.config.config;
};
export var hasKids = function (restaurant) {
    return (getBookingConfig(restaurant) || {}).has_kids;
};
export var hasAreaPreferencePossibility = function (restaurant) {
    var config = getBookingConfig(restaurant);
    if (config && typeof config.has_area_preference_possibility === 'boolean') {
        return config.has_area_preference_possibility;
    }
    return [16, 18, 45, 86, 93].includes(restaurant.id);
};
export function getSafePeopleAmount(peopleAmount) {
    return peopleAmount && !isNaN(peopleAmount)
        ? peopleAmount
        : DEFAULT_VALUES.peopleAmount;
}
