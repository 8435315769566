var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import SelectBar from '../components/SelectBar';
import RestaurantListSidebar from '../components/RestaurantList/RestaurantListSidebar';
import RestaurantList from '../components/RestaurantList/RestaurantList';
import ReservationModal from '../components/ReservationModal';
import OtoAlert from '../components/common/OtoAlert';
import OtoError from '../components/common/OtoError';
import { isDaySame, isToday } from '../app/date-helpers';
import { canBookTodayForHour } from '../app/time-helpers';
import { TOO_BIG_GROUP } from '../app/lang';
import { enablePopover, expandSidebarIfDesktop, hasStaticHeader, isDesktop } from '../app/ui';
import { getSafePeopleAmount } from '../app/general';
import { isBookingPartner } from '../app/logic';
import { getInstantBookings } from '../services/api';
import { getRestaurants } from '../services/restaurants';
import { DELIVERY_MODES, MAX_GROUP, AppMode } from '../config';
import { instantBookingsErrorState, instantBookingsInitialState, } from '../app/shapes/main';
import { checkAndLogPWAUsage, reloadIfVisitedLongAgo, } from '../pwa';
import LogService, { createDerivedStateFromErrorLogger, logComponentDidCatch, } from '../utils/log';
import ModeToggler from '../components/modes/ModeToggler';
import { getRestaurantsWithCustomFields, getRestaurantListUrlParams, } from '../app/restaurant-list';
import ModeSelectModal from '../components/modes/ModeSelectModal';
import AddressAutocomplete from '../components/OrderForm/AddressAutocomplete';
import DeliveryService, { geocodeByAddress, getIsAddressSelected, } from '../utils/location';
import OtoSpinner from '../components/common/OtoSpinner';
import { getCityId } from '../utils/url-params';
import AddressSelect from '../components/modes/AddressSelect';
import Marker from '../icons/Marker';
import DeliveryModeToggler from 'Comps/modes/DeliveryModeToggler';
import { AnalyticsService } from 'Utils/tracking';
import RestaurantsSlider from 'Comps/RestaurantsSlider/RestaurantsSlider';
import BannersSection from 'Comps/Banners/BannersSection';
import TenantService from 'App/services/tenant';
import { getHoursRange } from 'Utils/reservation';
import { initSentry } from 'App/services/sentry';
import Restaurant from 'Utils/restaurant';
var COMPONENT_NAME = 'OtoStolik';
var OtoStolik = /** @class */ (function (_super) {
    __extends(OtoStolik, _super);
    function OtoStolik(props) {
        var _this = _super.call(this, props) || this;
        _this.filteredByCuisineOnFirstFetch = false;
        _this.fetchRestaurants = function () {
            var isOrderMode = _this.state.mode === AppMode.ORDER;
            if (!_this.state.addressSelected && isOrderMode) {
                return Promise.resolve(null);
            }
            if (!_this.state.coordinates && !!_this.state.address && isOrderMode) {
                return _this.getCoordinatesPositionByAddress();
            }
            _this.setState({
                restaurantsLoading: true,
                restaurantsError: null,
            });
            return getRestaurants(_this.state.mode, _this.state.deliveryMode, {
                address: _this.state.address,
                cityId: _this.state.cityId,
                coordinates: _this.state.coordinates,
            })
                .then(function (response) {
                var allRestaurants = response.restaurants, promotions = response.promotions;
                var isBooking = _this.state.mode === AppMode.RESERVATION;
                _this.setState({
                    allRestaurants: isBooking
                        ? allRestaurants.filter(function (r) { return r.city_id === _this.state.cityId; })
                        : allRestaurants,
                    restaurants: getRestaurantsWithCustomFields(allRestaurants, _this.state.mode),
                    restaurantsLoading: false,
                    promotions: (promotions || []).filter(function (promo) { return promo.restaurants && promo.restaurants.length; }),
                });
            })
                .catch(function (e) {
                LogService.error('OtoStolik restaurant list fetchRestaurants', e);
                _this.setState({
                    restaurantsError: e,
                    restaurantsLoading: false,
                });
            });
        };
        _this.handleFilterChange = function (filter) {
            return _this.setState({ filter: filter });
        };
        _this.handleAppModeChange = function (mode) {
            if (mode === AppMode.ORDER) {
                _this.setState({
                    mode: mode,
                    restaurants: getRestaurantsWithCustomFields(_this.state.allRestaurants || [], mode),
                });
                return _this.fetchRestaurants();
            }
            else {
                window.location.href = "/rezerwuj?city=".concat(_this.state.cityId);
            }
        };
        _this.handleDateChange = function (date) {
            // TODO this is same in 3 places - App, Home, Iframe. Add tests & refactor.
            var isSameDaySelected = isDaySame(_this.state.visitDate, date);
            _this.setState({ visitDate: date }, _this.updateInstantBookings);
            if (isSameDaySelected) {
                return;
            }
            if (isToday(date)) {
                var newValidHours = _this.state.allHours.filter(canBookTodayForHour);
                newValidHours.length &&
                    _this.handleTimeChange({ target: { value: newValidHours[0] } });
                _this.setState({ hours: newValidHours });
            }
            else {
                _this.setState({ hours: _this.state.allHours });
            }
        };
        _this.handleTimeChange = function (event) {
            var visitTime = event.target.value;
            _this.setState({
                visitTime: visitTime,
                selectBarVisitTime: visitTime,
            }, _this.updateInstantBookings);
        };
        _this.handlePeopleAmountChange = function (event) {
            var value = parseInt(event.target.value, 10);
            var isTooBig = value > MAX_GROUP;
            var newState = __assign(__assign({}, _this.state), { peopleAmount: isTooBig ? MAX_GROUP : value, showTooBigGroupAlert: isTooBig });
            _this.setState(newState, _this.updateInstantBookings);
        };
        _this.clearAddress = function () { return _this.setState({ address: '', addressSelected: false }); };
        _this.handleAddressChange = function (address) { return _this.setState({ address: address }); };
        _this.handleDeliveryModeChange = function (newDeliveryMode) {
            DeliveryService.saveDeliveryPreferences({ mode: newDeliveryMode }, 'app1');
            var addressSelected = getIsAddressSelected(newDeliveryMode, _this.state.address);
            _this.setState({
                addressSelected: addressSelected,
                deliveryMode: newDeliveryMode,
            }, _this.fetchRestaurants);
        };
        _this.updateAddress = function (newAddress) {
            if (!newAddress) {
                return _this.clearAddress();
            }
            _this.setState({ address: newAddress, addressSelected: true });
            _this.getCoordinatesPositionByAddress(newAddress);
        };
        _this.handleAddressUpdateError = function (e) {
            _this.setState({ addressGeocodeError: e, whoDeliverLoading: false });
        };
        _this.getCoordinatesPositionByAddress = function (address) {
            if (address === void 0) { address = _this.state.address; }
            return geocodeByAddress(address)
                .then(function (googleMapPlace) {
                var newDeliveryPrefs = DeliveryService.mapGooglePlaceToDeliveryPrefs(googleMapPlace, address, DELIVERY_MODES[0].name);
                DeliveryService.saveDeliveryPreferences(newDeliveryPrefs, 'app2');
                var coordinates = newDeliveryPrefs.coordinates;
                _this.setState({ coordinates: coordinates }, _this.fetchRestaurants);
            })
                .catch(_this.handleAddressUpdateError);
        };
        _this.reservationHourClick = function (selectedRestaurant, btnTime) {
            if (btnTime === void 0) { btnTime = _this.state.visitTime; }
            _this.setState({
                selectedRestaurant: selectedRestaurant,
                visitTime: btnTime,
            });
            AnalyticsService.trackBookingCheckout();
        };
        _this.clearSelectedRestaurant = function () { return _this.setState({ selectedRestaurant: null }); };
        var _a = getRestaurantListUrlParams(), mode = _a.mode, initialCuisineFilter = _a.initialCuisineFilter, initialPeopleAmount = _a.initialPeopleAmount, initialVisitDate = _a.initialVisitDate, initialVisitTime = _a.initialVisitTime;
        var deliveryPreferences = DeliveryService.getDeliveryPreferences();
        var _b = deliveryPreferences || {}, _c = _b.address, address = _c === void 0 ? '' : _c, _d = _b.mode, deliveryMode = _d === void 0 ? DELIVERY_MODES[0].name : _d, _e = _b.coordinates, coordinates = _e === void 0 ? null : _e;
        var addressSelected = getIsAddressSelected(deliveryMode, address);
        var reservationHours = getHoursRange(32400, 75600);
        _this.state = {
            // order-related
            address: address,
            addressSelected: addressSelected,
            addressGeocodeError: null,
            coordinates: coordinates,
            deliveryMode: deliveryMode,
            // reservation-related
            allHours: reservationHours,
            hours: reservationHours,
            instantBookings: instantBookingsInitialState,
            cityId: getCityId() || 1,
            selectBarVisitTime: initialVisitTime,
            selectedRestaurant: null,
            showTooBigGroupAlert: false,
            peopleAmount: initialPeopleAmount,
            visitTime: initialVisitTime,
            visitDate: initialVisitDate,
            // common
            allRestaurants: null,
            filter: {
                cuisine: initialCuisineFilter,
                name: '',
            },
            hasError: false,
            mode: mode,
            restaurants: null,
            restaurantsLoading: true,
            restaurantsError: null,
            whoDeliverLoading: false,
        };
        return _this;
    }
    OtoStolik.prototype.componentDidMount = function () {
        var _this = this;
        var notice = document.getElementById('network-needed-notice');
        notice && notice.remove();
        // Setting initial values
        enablePopover();
        expandSidebarIfDesktop();
        checkAndLogPWAUsage('restaurant list');
        reloadIfVisitedLongAgo();
        ReactGA.initialize(TenantService.getGoogleAnalyticsTrackingId());
        var isBookMode = this.state.mode === AppMode.RESERVATION;
        if (this.state.addressSelected || isBookMode) {
            this.fetchRestaurants().then(function () {
                if (isBookMode) {
                    _this.updateInstantBookings();
                }
            });
        }
    };
    OtoStolik.prototype.componentDidCatch = function (error, info) {
        logComponentDidCatch(COMPONENT_NAME, error, info);
    };
    OtoStolik.prototype.updateInstantBookings = function () {
        var _this = this;
        if (!this.state.restaurants) {
            return;
        }
        this.setState({
            instantBookings: __assign(__assign({}, this.state.instantBookings), { loading: true, error: false }),
        });
        var ids = this.state.restaurants
            .filter(isBookingPartner)
            .map(function (item) { return item.id; });
        var _a = this.state, visitTime = _a.visitTime, visitDate = _a.visitDate;
        var peopleAmount = getSafePeopleAmount(this.state.peopleAmount);
        var params = {
            ids: ids,
            peopleAmount: peopleAmount,
            visitDate: visitDate,
            visitTime: visitTime,
        };
        var errorCb = function () {
            _this.setState({ instantBookings: instantBookingsErrorState });
        };
        getInstantBookings(params, errorCb)
            .then(function (data) {
            _this.setState({
                instantBookings: {
                    loading: false,
                    error: false,
                    data: data,
                },
            });
        })
            .catch(function (e) {
            LogService.error('OtoStolik getInstantBookings', e);
            _this.setState({ instantBookings: instantBookingsErrorState });
        });
    };
    OtoStolik.prototype.render = function () {
        if (this.state.hasError) {
            return React.createElement(OtoError, { className: "first-after-header mx-2" });
        }
        var _a = this.state, filter = _a.filter, instantBookings = _a.instantBookings, hours = _a.hours, visitDate = _a.visitDate, visitTime = _a.visitTime, selectBarVisitTime = _a.selectBarVisitTime, restaurants = _a.restaurants, peopleAmount = _a.peopleAmount, selectedRestaurant = _a.selectedRestaurant, mode = _a.mode, deliveryMode = _a.deliveryMode;
        var safePeopleAmount = getSafePeopleAmount(peopleAmount);
        var isReservationMode = mode === AppMode.RESERVATION;
        var isOrderMode = mode === AppMode.ORDER;
        var sidebarClassName = isReservationMode || hasStaticHeader() ? '' : 'mt-5 mt-sm-0';
        return (React.createElement("div", null,
            React.createElement("div", { id: "alert-container", className: "alert-bar first-after-header" }, this.renderAlerts()),
            isReservationMode && (React.createElement(SelectBar, { className: "mt-5 mt-md-0", handleDateChange: this.handleDateChange, handleTimeChange: this.handleTimeChange, handlePeopleAmountChange: this.handlePeopleAmountChange, visitTime: selectBarVisitTime, visitDate: visitDate, peopleAmount: peopleAmount, reservationHours: hours })),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center align-items-start" },
                    isDesktop() ||
                        TenantService.isTenantWithDefaultOrderMode() ? null : (React.createElement(ModeToggler, { activeMode: mode, className: "md-minus", fixed: true, setMode: this.handleAppModeChange })),
                    isDesktop() ||
                        TenantService.isTenantWithDefaultBookingMode() ? null : (React.createElement(DeliveryModeToggler, { activeMode: this.state.deliveryMode, className: "md-minus", fixed: true, setMode: this.handleDeliveryModeChange })),
                    React.createElement(RestaurantListSidebar, { cityId: this.state.cityId, className: sidebarClassName, filter: filter, mode: mode, handleFilterChange: this.handleFilterChange, restaurants: restaurants }),
                    React.createElement(RestaurantList, { cityId: this.state.cityId, appMode: mode, deliveryMode: deliveryMode, filter: filter, loading: this.state.restaurantsLoading, instantBookings: instantBookings, handleFilterChange: this.handleFilterChange, handleReservationClick: this.reservationHourClick, restaurants: restaurants, peopleAmount: safePeopleAmount, visitDate: visitDate, visitTime: visitTime },
                        isOrderMode && React.createElement(BannersSection, null),
                        this.state.restaurantsError && React.createElement(OtoError, null),
                        React.createElement(RestaurantsSlider, { promotions: this.state.promotions }),
                        isOrderMode && this.renderAddressBox()))),
            selectedRestaurant ? (React.createElement(ReservationModal, { explainConfirmationDuration: true, hideModal: this.clearSelectedRestaurant, peopleAmount: safePeopleAmount, source: "landing", restaurant: selectedRestaurant, showEmailField: Restaurant.requiresEmailForReservation(selectedRestaurant), showMarketingFields: Restaurant.wantsToCollectMarketingAgreemtnts(selectedRestaurant), visitDate: visitDate, visitTime: visitTime })) : null,
            !mode && TenantService.isTenantWithDefaultBookingMode() && (React.createElement(ModeSelectModal, { setMode: this.handleAppModeChange })),
            mode === AppMode.ORDER && !this.state.addressSelected && (React.createElement(AddressSelect, { addressValue: this.state.address, handleAddressSelect: this.updateAddress, handleValueChange: this.handleAddressChange },
                React.createElement(DeliveryModeToggler, { activeMode: this.state.deliveryMode, className: "mb-3", setMode: this.handleDeliveryModeChange })))));
    };
    OtoStolik.prototype.renderAddressBox = function () {
        var _a = this.state, deliveryMode = _a.deliveryMode, whoDeliverLoading = _a.whoDeliverLoading;
        return (React.createElement("div", { className: "restaurant-list__address-box" },
            isDesktop() && TenantService.isTenantWithDefaultOrderMode() && (React.createElement(DeliveryModeToggler, { activeMode: deliveryMode, className: "mb-3", setMode: this.handleDeliveryModeChange })),
            this.renderSelectedDelivery(),
            whoDeliverLoading && (React.createElement("div", { className: "mt-2" },
                React.createElement(OtoSpinner, { className: "mr-2" }),
                React.createElement("span", null, "Pobieram list\u0119 restauracji")))));
    };
    OtoStolik.prototype.renderSelectedDelivery = function () {
        var _a = this.state, address = _a.address, addressSelected = _a.addressSelected, deliveryMode = _a.deliveryMode;
        var isTakeoutMode = deliveryMode === 'takeout';
        return addressSelected ? (React.createElement("div", { className: "restaurant-list__selected-address" },
            React.createElement("span", { className: "mr-1" },
                React.createElement(Marker, null),
                " Adres ",
                isTakeoutMode ? 'odbioru' : 'dostawy',
                ":",
                ' ',
                address || 'nie podano'),
            ' ',
            React.createElement("button", { type: "button", className: "btn btn-link p-0", onClick: this.clearAddress }, "zmie\u0144"))) : (React.createElement(AddressAutocomplete, { handleSelect: this.updateAddress, handleValueChange: this.handleAddressChange, placeholder: "Adres dostawy, np. Aleje Rac\u0142awickie 12", value: address }));
    };
    OtoStolik.prototype.renderAlerts = function () {
        var _a = this.state, mode = _a.mode, showTooBigGroupAlert = _a.showTooBigGroupAlert;
        if (mode !== AppMode.RESERVATION) {
            return null;
        }
        return React.createElement(React.Fragment, null, showTooBigGroupAlert && React.createElement(OtoAlert, { text: TOO_BIG_GROUP }));
    };
    OtoStolik.displayName = COMPONENT_NAME;
    OtoStolik.getDerivedStateFromError = createDerivedStateFromErrorLogger(COMPONENT_NAME);
    return OtoStolik;
}(React.PureComponent));
export default OtoStolik;
if (process.env.NODE_ENV !== 'test') {
    initSentry();
    var appContainer = document.getElementById('app');
    appContainer && ReactDOM.render(React.createElement(OtoStolik, null), appContainer);
}
