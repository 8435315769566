import PropTypes from 'prop-types';
import { basketStatusShape, upsellingItemShape } from './basket-status';
import { appModePropType, deliveryModePropType } from './modes';
import { orderShape } from './order';
import restaurantShape from './restaurant';
import menuShape, { categoryShape, menuItemShape, menuItemWithCategoryShape, } from './restaurant-menu';
import ordersConfigShape from './orders-config';
export var instantBookingsShape = PropTypes.exact({
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.bool,
});
export var instantBookingsErrorState = {
    data: [],
    loading: false,
    error: true,
};
export var instantBookingsInitialState = {
    data: [],
    error: false,
    loading: false,
};
export var deliveryConfigShape = PropTypes.exact({
    area: PropTypes.object,
    areaOptions: PropTypes.array,
    baseDeliveryKm: PropTypes.number,
    extraKmCost: PropTypes.number,
    dynamicDeliveryPricing: PropTypes.bool,
    minCartAmount: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    freeDeliveryFrom: PropTypes.number,
    maxKmDistance: PropTypes.number,
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    long: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});
export var deliveryRestaurantShape = PropTypes.shape({
    // TODO deprecated
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    menu: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
}).isRequired;
export var filterShape = PropTypes.exact({
    name: PropTypes.string,
    cuisine: PropTypes.string,
});
export var promoCodeShape = PropTypes.exact({
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
});
var Shapes = {
    appModePropType: appModePropType,
    basketStatusShape: basketStatusShape,
    deliveryConfigShape: deliveryConfigShape,
    deliveryRestaurantShape: deliveryRestaurantShape,
    deliveryModePropType: deliveryModePropType,
    orderShape: orderShape,
    ordersConfigShape: ordersConfigShape,
    restaurantShape: restaurantShape,
    restaurantMenuShape: menuShape,
    restaurantMenuCategoryShape: categoryShape,
    restaurantMenuItemShape: menuItemShape,
    restaurantMenuItemWithCategoryShape: menuItemWithCategoryShape,
    upsellingItemShape: upsellingItemShape,
};
export default Shapes;
