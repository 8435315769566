import React from 'react';
import OtoAlertNew from './OtoAlertNew';
var defaultDisablingRuleText = 'Drodzy goście, w tym dniu restauracja jest zamknięta.';
var CustomRules = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, rules = _a.rules;
    return (React.createElement(React.Fragment, null, rules.map(function (rule) {
        var color = rule.disableBook ? 'danger' : 'warning';
        var text = rule.text || defaultDisablingRuleText;
        return (React.createElement(OtoAlertNew, { color: color, className: className, key: rule.text, withBorder: true }, text));
    })));
};
export default CustomRules;
