import { IFRAME_CONFIG } from '../config';
import { loadIframeResizerScript } from './load-script';
import { isIframe } from './url-params';

class IframeObserver {
  ready = false;
  listeners = [];
  reactOnIframeChange = false;
  lastPageInfo = {};

  initIfApplicable(options = {}) {
    if (isIframe()) {
      window.iFrameResizer = {
        targetOrigin: '*', // todo website domain here, otherwise a security risk
        onReady: () => {
          console.warn('IframeObserver onReady fired');
          this.propagatePageInfo();
          options.onReady && options.onReady();
        },
        onMessage: message => {
          const { data, type } = JSON.parse(message);
          if (type === 'otostolikIframeOptions' && data && 'offset' in data) {
            console.warn('IframeObserver onMessage, overriding IFRAME_CONFIG with data: ', data.offset);
            Object.entries(data.offset).forEach(([key, value]) => {
              IFRAME_CONFIG[key] = value;
            });
          }
        }
      };
      loadIframeResizerScript();
    }
  }

  propagatePageInfo() {
    this.ready = true;
    this.reactOnIframeChange = pageInfo => {
      if (pageInfo && 'documentWidth' in pageInfo) {
        this.listeners.forEach(listener => listener(pageInfo));
        this.lastPageInfo = pageInfo;
      }
    }
    parentIFrame && parentIFrame.getPageInfo(this.reactOnIframeChange);
  }

  addListener = listener => {
    if (this.ready) {
      listener();
    }
    this.listeners = [...this.listeners, listener];
  };

  removeListener = listener => {
    this.listeners = this.listeners.filter(item => item !== listener);
    if (this.listeners.length === 0) {
      this.reactOnIframeChange = false;
    }
  }

  hasParentIframe = () => {
    return ('parentIFrame' in window);
  }
}

const iframeObserver = new IframeObserver();

export default iframeObserver;
