import { slugify } from '../app/general';
import { levenshteinDistance } from '../app/algorithms';
export function isActive(item, filter) {
    var nameSlug = removeConsecutiveDuplicateChars(slugify(item.name));
    var filterSlug = removeConsecutiveDuplicateChars(filter.name);
    if (filter.cuisine && !(item.cuisine_label || []).includes(filter.cuisine)) {
        return false;
    }
    if (nameSlug.includes(filterSlug)) {
        return true;
    }
    if (nameSlug.replace(/-/g, '').includes(filterSlug.replace(/-/g, ''))) {
        return true;
    }
    if (hasOneExactWordMatch(nameSlug.split('-'), filterSlug.split('-'))) {
        return true;
    }
    if (hasOneFuzzyWordMatch(nameSlug.split('-'), filterSlug.split('-'))) {
        return true;
    }
    return false;
}
export function removeConsecutiveDuplicateChars(str) {
    if (str === void 0) { str = ''; }
    return str.replace(/(.)\1+/g, '$1');
}
function hasOneExactWordMatch(nameParts, filterParts) {
    var longEnoughtFilterParts = filterParts.filter(function (str) { return str.length > 2; });
    return longEnoughtFilterParts.filter(function (filterWord) { return nameParts.includes(filterWord); }).length;
}
function hasOneFuzzyWordMatch(nameParts, filterParts) {
    var longEnoughtFilterParts = filterParts.filter(function (str) { return str.length > 3; });
    return longEnoughtFilterParts.filter(function (filterWord) {
        return nameParts.filter(function (nameWord) { return levenshteinDistance(nameWord, filterWord) < 2; }).length;
    }).length;
}
