import React from 'react';
import PropTypes from 'prop-types';
import { DELIVERY_MODES } from '../../config';
import { ModeTogglerWrapper, ModeItem } from '../common/SelectMode';
import { DeliveryModeIconSet } from 'Icons/IconSets';
var DeliveryModeToggler = function (props) {
    var activeMode = props.activeMode, className = props.className, fixed = props.fixed, setMode = props.setMode, showLongLabel = props.showLongLabel;
    return (React.createElement(ModeTogglerWrapper, { className: className, fixed: fixed }, DELIVERY_MODES.map(function (mode) { return (React.createElement(ModeItem, { key: mode.name, isActive: activeMode === mode.name, onClick: function () { return setMode(mode.name); } },
        DeliveryModeIconSet[mode.name],
        React.createElement("span", { className: "ml-1" }, showLongLabel ? mode.longLabel : mode.label))); })));
};
DeliveryModeToggler.propTypes = {
    activeMode: PropTypes.oneOf(['delivery', 'takeout']).isRequired,
    className: PropTypes.string,
    fixed: PropTypes.bool,
    setMode: PropTypes.func.isRequired,
    showLongLabel: PropTypes.bool,
};
DeliveryModeToggler.defaultProps = {
    className: '',
    fixed: false,
    showLongLabel: false,
};
DeliveryModeToggler.displayName = 'DeliveryModeToggler';
export default DeliveryModeToggler;
