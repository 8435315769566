import React from 'react';
var RestaurantCuisine = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, cuisine = _a.cuisine, onClick = _a.onClick;
    if (!cuisine || !cuisine.length) {
        return null;
    }
    var text = onClick
        ? cuisine.map(function (c) { return React.createElement("button", { key: c, className: "btn btn-link p-0 vertical-center", onClick: function () { return onClick(c); } },
            "#",
            c); })
        : React.createElement("span", { className: "ml-2" }, cuisine.join(', '));
    return React.createElement("div", { className: "".concat(className, " restaurant__cuisine vertical-center") }, text);
};
RestaurantCuisine.displayName = 'RestaurantCuisine';
export default RestaurantCuisine;
