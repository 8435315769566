import PropTypes from 'prop-types';
import restaurantShape from './restaurant';
import userShape from './user';
import { EPaymentType } from '../enums/payments';
var DELIVERY_TYPE_EXTERNAL = 'external';
var DELIVERY_TYPE_INTERNAL = 'internal';
var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["NEW"] = "new";
    DeliveryStatus["ASSIGNED"] = "assigned";
    DeliveryStatus["ARRIVING"] = "arriving";
    DeliveryStatus["ARRIVED"] = "arrived";
    DeliveryStatus["ON_THE_WAY"] = "on_the_way";
    DeliveryStatus["DELIVERED"] = "delivered";
    DeliveryStatus["DECLINED"] = "declined";
})(DeliveryStatus || (DeliveryStatus = {}));
export var DriverType;
(function (DriverType) {
    DriverType["Restaurant"] = "restaurant";
    DriverType["Foodeli"] = "foodeli";
    DriverType["Robot"] = "robot";
})(DriverType || (DriverType = {}));
export var statusLogShape = PropTypes.exact({
    created_at: PropTypes.string.isRequired,
    delivery_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
});
var deliveryShape = PropTypes.exact({
    address: PropTypes.string.isRequired,
    assigned_to: PropTypes.number,
    arriving_in: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    declined_by: PropTypes.array.isRequired,
    delivery_at: PropTypes.string,
    driver_type: PropTypes.oneOf(Object.values(DriverType))
        .isRequired,
    distance: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    inform_before: PropTypes.number,
    notes: PropTypes.string,
    order_id: PropTypes.number,
    payment_id: PropTypes.number,
    payment_status: PropTypes.oneOf(Object.values(EPaymentType))
        .isRequired,
    phone: PropTypes.string.isRequired,
    price: PropTypes.number,
    restaurant: restaurantShape,
    restaurant_id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(DeliveryStatus)).isRequired,
    status_logs: PropTypes.arrayOf(statusLogShape.isRequired),
    type: PropTypes.oneOf([DELIVERY_TYPE_EXTERNAL, DELIVERY_TYPE_INTERNAL])
        .isRequired,
    updated_at: PropTypes.string.isRequired,
    lat: PropTypes.number,
    lng: PropTypes.number,
    user: userShape,
});
export default deliveryShape;
