var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getBookingConfig } from 'App/app/general';
import { getOrdersConfig } from 'App/app/logic';
import { DeliveryTypes } from 'App/order-fields';
import { OTO_PRODUCTS } from '../config';
import { getWeekDayIndex } from 'App/app/date-helpers';
var DEFAULTS = {
    MIN_CART_AMOUNT: 40,
    MIN_DELIVERY_TIME: 60,
};
var hasMultipleDeliveryAreas = function (ordersConfig) {
    return !!ordersConfig.delivery.areaOptions &&
        ordersConfig.delivery.areaOptions.length > 1;
};
var hasSingleDeliveryArea = function (ordersConfig) {
    return !!ordersConfig.delivery.areaOptions &&
        ordersConfig.delivery.areaOptions.length === 1;
};
export function getMinCartAmountText(restaurant, basketStatus) {
    if (basketStatus && basketStatus.currentArea) {
        return basketStatus.currentArea.minCartAmount;
    }
    if ('currentArea' in restaurant) {
        return restaurant.currentArea.minCartAmount;
    }
    var ordersConfig = getOrdersConfig(restaurant);
    var delivery = ordersConfig.delivery;
    if (hasMultipleDeliveryAreas(ordersConfig)) {
        var min = Math.min.apply(Math, __spreadArray([], __read(delivery.areaOptions.map(function (opt) { return opt.minCartAmount || 0; })), false));
        var max = Math.max.apply(Math, __spreadArray([], __read(delivery.areaOptions.map(function (opt) { return opt.minCartAmount || 0; })), false));
        return min === max ? min : "Od ".concat(min, " do ").concat(max);
    }
    if (hasSingleDeliveryArea(ordersConfig)) {
        return delivery.areaOptions[0].minCartAmount;
    }
    if (delivery && delivery.dynamicDeliveryPricing) {
        // TODO dynamicDeliveryPricing is deprecated
        return delivery.minCartAmount || DEFAULTS.MIN_CART_AMOUNT;
    }
    // TODO should never happen, either hasMultipleDeliveryAreas or hasSingleDeliveryArea should always work
    return '';
}
export function getDynamicDeliveryText(
// @ts-ignore allow empty object for now
ordersConfig, showFreeDeliveryFrom) {
    if (ordersConfig === void 0) { ordersConfig = {}; }
    var delivery = ordersConfig.delivery;
    if (hasMultipleDeliveryAreas(ordersConfig)) {
        var min = Math.min.apply(Math, __spreadArray([], __read(ordersConfig.delivery.areaOptions.map(function (opt) { return opt.price || 0; })), false));
        var max = Math.max.apply(Math, __spreadArray([], __read(ordersConfig.delivery.areaOptions.map(function (opt) { return opt.price || 0; })), false));
        return "Od ".concat(min, "z\u0142 do ").concat(max, "z\u0142, zale\u017Cy od odleg\u0142o\u015Bci wzgl\u0119dem lokalu.");
    }
    if (delivery && delivery.dynamicDeliveryPricing) {
        var _a = ordersConfig.delivery, baseDeliveryKm = _a.baseDeliveryKm, extraKmCost = _a.extraKmCost, price = _a.price, freeDeliveryFrom = _a.freeDeliveryFrom;
        var freeDeliveryPart = showFreeDeliveryFrom && !!freeDeliveryFrom
            ? " Darmowa dostawa od ".concat(freeDeliveryFrom, "z\u0142.")
            : '';
        return "".concat(price, "z\u0142 za dostaw\u0119 do ").concat(baseDeliveryKm, "km + ").concat(extraKmCost, "z\u0142 za ka\u017Cdy kolejny km.").concat(freeDeliveryPart);
    }
    return ordersConfig.dynamicDeliveryText;
}
export function getMinimumDeliveryTime(ordersConfig) {
    return ordersConfig.deliveryTime || DEFAULTS.MIN_DELIVERY_TIME;
}
// @ts-ignore allow empty object for now
export function hasDynamicDeliveryPrice(ordersConfig) {
    if (ordersConfig === void 0) { ordersConfig = {}; }
    if (!ordersConfig.delivery) {
        return false;
    }
    if (hasMultipleDeliveryAreas(ordersConfig)) {
        return true;
    }
    if (hasSingleDeliveryArea(ordersConfig)) {
        return false;
    }
    return (ordersConfig.delivery.dynamicDeliveryPricing ||
        ordersConfig.dynamicDeliveryText);
}
export var getMinimumDeliveryPrice = function (restaurant) {
    if ('currentArea' in restaurant) {
        return restaurant.currentArea.price;
    }
    var ordersConfig = getOrdersConfig(restaurant);
    if (ordersConfig.delivery && ordersConfig.delivery.areaOptions) {
        // TODO use distance based price, not min
        return Math.min.apply(Math, __spreadArray([], __read(ordersConfig.delivery.areaOptions.map(function (opt) { return opt.price || 0; })), false));
    }
    return ordersConfig.delivery.price;
};
export var getMinimumCartAmount = function (restaurant, basketStatus) {
    if (!!basketStatus && !!basketStatus.currentArea) {
        return basketStatus.currentArea.minCartAmount;
    }
    if ('currentArea' in restaurant) {
        return restaurant.currentArea.minCartAmount;
    }
    var ordersConfig = getOrdersConfig(restaurant);
    if (ordersConfig.delivery && ordersConfig.delivery.areaOptions) {
        // TODO use distance based minCartAmount, not min
        return Math.min.apply(Math, __spreadArray([], __read(ordersConfig.delivery.areaOptions.map(function (opt) { return opt.minCartAmount; })), false));
    }
    return ordersConfig.delivery.minCartAmount || DEFAULTS.MIN_CART_AMOUNT;
};
export var getFreeDeliveryFrom = function (restaurant, basketStatus) {
    if (basketStatus && basketStatus.currentArea) {
        return basketStatus.currentArea.freeDeliveryFrom;
    }
    if ('currentArea' in restaurant) {
        return restaurant.currentArea.freeDeliveryFrom;
    }
    var ordersConfig = getOrdersConfig(restaurant);
    if (ordersConfig.delivery && ordersConfig.delivery.areaOptions) {
        // TODO use distance based freeDeliveryFrom, not min
        return Math.min.apply(Math, __spreadArray([], __read(ordersConfig.delivery.areaOptions.map(function (opt) { return opt.freeDeliveryFrom; })), false));
    }
    return ordersConfig.delivery.freeDeliveryFrom;
};
export var getStaticDeliveryPrice = function (restaurant, basketStatus) {
    if (basketStatus && basketStatus.currentArea) {
        return basketStatus.currentArea.price;
    }
    if ('currentArea' in restaurant) {
        return restaurant.currentArea.price;
    }
    var ordersConfig = getOrdersConfig(restaurant);
    if (!!ordersConfig.delivery.areaOptions) {
        return ordersConfig.delivery.areaOptions[0].price || 0;
    }
    return ordersConfig.delivery.price;
};
// @ts-ignore allow empty object for now
export var isDeliveryPossible = function (ordersConfig) {
    if (ordersConfig === void 0) { ordersConfig = {}; }
    var options = ordersConfig.allowedDeliveryOptionKeys;
    return (!options ||
        options.includes(DeliveryTypes.usual) ||
        options.includes(DeliveryTypes.contactless));
};
// @ts-ignore allow empty object for now
export var isTakeoutPossible = function (ordersConfig) {
    if (ordersConfig === void 0) { ordersConfig = {}; }
    var options = ordersConfig.allowedDeliveryOptionKeys;
    return !options || options.includes(DeliveryTypes.takeout);
};
export var isRestaurantDimmed = function (restaurant, isOrderMode, options) {
    if (options === void 0) { options = { deliversToSelectedAddress: false }; }
    if (!isOrderMode) {
        return { isDimmed: false };
    }
    var _a = restaurant.details, isDeliveryClosed = _a.isDeliveryClosed, disabledOrdersText = _a.disabledOrdersText;
    if (isDeliveryClosed) {
        return {
            isDimmed: true,
            dimmedText: 'Restauracja obecnie jest zamknięta',
            dimmedBtnText: 'zobacz menu i zamów później',
        };
    }
    if (!!disabledOrdersText) {
        return {
            isDimmed: true,
            dimmedText: 'Zamówienia są chwilowo wstrzymane',
            dimmedBtnText: 'zobacz menu i zamów później',
        };
    }
    var deliversToSelectedAddress = options.deliversToSelectedAddress;
    if (!deliversToSelectedAddress) {
        return {
            isDimmed: true,
            dimmedText: 'Restauracja nie dowozi pod wskazany adres',
            dimmedBtnText: 'Zobacz menu i zamów z odbiorem osobistym',
        };
    }
    return { isDimmed: false };
};
export var getSelectedDayOpeningHours = function (opening_hours, visitDate) {
    return (opening_hours === null || opening_hours === void 0 ? void 0 : opening_hours.length) && opening_hours[getWeekDayIndex(visitDate)] || undefined;
};
var hasProduct = function (restaurant, product) {
    return (restaurant.products || []).includes(product);
};
var hasLubjeOrdersList = function (restaurant) {
    return hasProduct(restaurant, OTO_PRODUCTS.lubje_orders);
};
var wantsToCountNonVaccinated = function (restaurant) {
    var config = getBookingConfig(restaurant);
    return config && config.ask_about_non_vaccinated_guest_amount;
};
var requiresEmailForReservation = function (restaurant) {
    return [64, 141, 452, 468, 471].includes(restaurant.id);
};
var wantsToCollectMarketingAgreemtnts = function (restaurant) {
    return [64, 141, 452, 468, 471].includes(restaurant.id);
};
var Restaurant = {
    getOrdersConfig: getOrdersConfig,
    hasLubjeOrdersList: hasLubjeOrdersList,
    hasProduct: hasProduct,
    requiresEmailForReservation: requiresEmailForReservation,
    wantsToCollectMarketingAgreemtnts: wantsToCollectMarketingAgreemtnts,
    wantsToCountNonVaccinated: wantsToCountNonVaccinated,
};
export default Restaurant;
