import React from 'react';
import PropTypes from 'prop-types';
import Frown from '../../icons/Frown';
import TenantService from 'App/services/tenant';
var OtoError = function (props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, _b = props.withWrapper, withWrapper = _b === void 0 ? false : _b;
    var mail = TenantService.getTechnicalSupportEmail();
    var phone = '+48 791 792 809';
    var error = (React.createElement("div", { className: "alert alert-danger ".concat(className) },
        props.children,
        React.createElement(Frown, null),
        " Ooops! Co\u015B posz\u0142o nie tak.",
        React.createElement("p", null,
            "Pom\u00F3\u017C nam rozwi\u0105za\u0107 ten problem - zr\u00F3b zrzut tego ekranu i wy\u015Blij go na",
            ' ',
            React.createElement("a", { href: "mailto:".concat(mail) }, mail),
            ", lub zadzwo\u0144 na",
            ' ',
            React.createElement("a", { href: "tel:".concat(phone) }, phone),
            "."),
        React.createElement("p", null, "Je\u017Celi oka\u017Ce si\u0119, \u017Ce co\u015B przeoczyli\u015Bmy i naprawimy to dzi\u0119ki Tobie - odwdzi\u0119czymy si\u0119 kodem rabatowym :)")));
    if (withWrapper) {
        return (React.createElement("div", { className: "masthead justify-content-center align-items-start" },
            props.children,
            error));
    }
    return error;
};
OtoError.displayName = 'OtoError';
OtoError.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
export default OtoError;
