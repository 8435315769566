import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AddressAutocomplete from '../OrderForm/AddressAutocomplete';
import OtoSpinner from 'Comps/common/OtoSpinner';
import './mode-select-modal.scss';
import './address-select-modal.scss';
import LastUsedAddresses from './LastUsedAddresses';
var AddressSelect = function (props) {
    var addIcon = props.addIcon, addressValue = props.addressValue, error = props.error, handleValueChange = props.handleValueChange, handleAddressSelect = props.handleAddressSelect, loading = props.loading, _a = props.showLastUsed, showLastUsed = _a === void 0 ? true : _a, _b = props.type, type = _b === void 0 ? 'modal' : _b;
    var content = (React.createElement(React.Fragment, null,
        props.children,
        React.createElement("div", { className: "vertical-center w-100" }, loading ? (React.createElement(OtoSpinner, { className: "ml-2" })) : (React.createElement(AddressAutocomplete, { addIcon: addIcon, className: "w-100", handleSelect: handleAddressSelect, handleValueChange: handleValueChange, placeholder: "Adres dostawy, np. Aleje Rac\u0142awickie 12", value: addressValue }))),
        error && React.createElement("div", { className: "text-danger mt-2" }, error),
        showLastUsed && (React.createElement(React.Fragment, null,
            React.createElement(LastUsedAddresses, { onSelect: handleAddressSelect }),
            React.createElement("div", { className: "small text-muted mt-2" }, "*Przy odbiorze osobistym restauracje b\u0119d\u0105 sortowane wg. odleg\u0142o\u015Bci od podanego adresu")))));
    if (type === 'plain') {
        return content;
    }
    return (React.createElement(Modal, { isOpen: true, centered: true, className: "mode-select-modal address-select-modal" },
        React.createElement(ModalHeader, null, "Podaj adres dostawy"),
        React.createElement(ModalBody, { className: "d-flex flex-column" }, content)));
};
AddressSelect.propTypes = {
    addressValue: PropTypes.string.isRequired,
    addIcon: PropTypes.bool,
    children: PropTypes.node,
    error: PropTypes.string,
    handleAddressSelect: PropTypes.func.isRequired,
    handleValueChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    showLastUsed: PropTypes.bool,
    type: PropTypes.oneOf(['modal', 'plain']),
};
AddressSelect.displayName = 'AddressSelect';
export default AddressSelect;
