import filterXss from 'xss'

import LogService from './utils/log'

let wasOffline = false
updateIndicator()

const getURL = () => filterXss(window.location.pathname)

window.addEventListener('online', updateIndicator)
window.addEventListener('offline', updateIndicator)
window.addEventListener('appinstalled', () =>
  LogService.activity(`OtoStolik PWA appinstalled from page ${getURL()}!`)
)

function updateIndicator() {
  if (isPWA()) {
    checkIsOnline.then((isOnline) => {
      if (isOnline) {
        if (wasOffline) {
          wasOffline = false
          location.reload()
        }
      } else {
        wasOffline = true
        var div = document.createElement('div')
        div.innerText =
          'Dla poprawnego działania aplikacji potrzebny jest dostęp do sieci'
        div.style =
          'color: white; background-color: #f4623a; border-radius: 5px; padding: 10px; margin: 10px;'
        document.body.prepend(div)
      }
    })
  }
}

function checkIsOnline() {
  return new Promise((resolve) => {
    const xhr = XMLHttpRequest
      ? new XMLHttpRequest()
      : new ActiveXObject('Microsoft.XMLHttp')
    xhr.onload = function () {
      resolve(true)
    }
    xhr.onerror = function () {
      resolve(false)
    }
    xhr.open('GET', 'anypage.php', true)
    xhr.send()
  })
}

function isPWA() {
  return window.matchMedia('(display-mode: standalone)').matches
}

export function checkAndLogPWAUsage(page = 'Unknown page') {
  if (isPWA()) {
    LogService.activity(
      `Someone's using PWA at page: ${page}, url: ${getURL()}.`
    )
  }
}

export function hasLocalStorageAccess() {
  return isStorageSupported(window, 'localStorage')
}

function isStorageSupported(globalObject, storageType) {
  try {
    const storage = globalObject[storageType]
    storage.setItem('test', 'test')
    storage.removeItem('test')
    return true
  } catch (err) {
    return false
  }
}

export function reloadIfVisitedLongAgo() {
  if (!hasLocalStorageAccess()) {
    return
  }
  const lastVisitDateStr = filterXss(localStorage.getItem('lastVisitDate'))
  if (lastVisitDateStr) {
    const lastVisitDate = new Date(lastVisitDateStr)
    const now = new Date()
    if (lastVisitDate.getHours() !== now.getHours()) {
      localStorage.setItem('lastVisitDate', new Date())
      window.location.reload(true)
    }
  }
}

export function reloadOnceWhenCrashed() {
  if (!hasLocalStorageAccess()) {
    return
  }
  const lastCrashDateStr = filterXss(localStorage.getItem('lastCrashDate'))
  if (lastCrashDateStr) {
    const lastCrashDate = new Date(lastCrashDateStr)
    const now = new Date()
    if (lastCrashDate.getHours() !== now.getHours()) {
      localStorage.setItem('lastCrashDate', new Date())
      window.location.reload(true)
    }
  }
}
