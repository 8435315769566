import { MAX_GROUP, ORDER_STATUS } from '../config';
import { isTakeoutOrder } from './logic';
export var NOT_WORKING_HOURS = 'Rezerwacje w lokalach niepartnerskich realizujemy w godzinach 8-22. Rezerwacje poza tymi godzinami są realizowane po godzinie 8 następnego dnia, lub później, w zależności od godzin otwarcia restauracji.';
export var RESTAURANT_CLOSED = function (name, todayHours) { return "Wygl\u0105da na to, \u017Ce lokal ".concat(name, " jest zamkni\u0119ty. Dzisiaj jego godziny pracy to ").concat(todayHours, ". Rezerwacja zostanie zrealizowana po otwarciu lokalu."); };
export var TOO_BIG_GROUP = "Obecny maksymalny rozmiar grupy to ".concat(MAX_GROUP, " os\u00F3b");
export var TOO_SHORT_ADVANCE = function (restaurant) { return "Restauracja ".concat(restaurant.name, " rezerwuje stoliki online z minimalnym wyprzedzeniem ").concat(restaurant.config.config.auto_confirm_before, " godzin. Je\u017Celi interesuje Ci\u0119 bli\u017Cszy termin - zadzwo\u0144 do restauracji, lub przyjd\u017A bez rezerwacji :)"); };
export var NO_ADDITIONAL_NOTES_GUARANTEE = 'Przekażemy dodatkowe uwagi restauracji podczas rezerwacji, ale nie gwarantujemy ich spełnienia.';
export var RESTAURANT_WILL_BE_CLOSED = function (name, dayHours) { return "W wybranym dniu oraz godzinie lokal ".concat(name, " b\u0119dzie zamkni\u0119ty. Jego godziny pracy w ").concat(dayHours, ". Zapraszamy do rezerwacji w innych godzinach."); };
export var mapOrderStatusToLabel = function (order) {
    var _a;
    var labels = (_a = {},
        _a[ORDER_STATUS.new] = 'Oczekuje na płatność',
        _a[ORDER_STATUS.pending] = 'Wysłane do restauracji',
        _a[ORDER_STATUS.confirmed] = 'W trakcie przygotowania',
        _a[ORDER_STATUS.sent] = isTakeoutOrder(order) ? 'Gotowe do odbioru' : 'W drodze do Ciebie',
        _a[ORDER_STATUS.declined] = 'Anulowane',
        _a[ORDER_STATUS.cancelled] = 'Anulowane',
        _a[ORDER_STATUS.delivered] = isTakeoutOrder(order) ? 'Odebrane' : 'Dostarczone',
        _a);
    return labels[order.status];
};
