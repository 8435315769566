var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import PropTypes from 'prop-types';
import Restaurant from '../Restaurant';
import CustomRules from '../../common/CustomRules';
import OtoAlert from '../../common/OtoAlert';
import OtoError from '../../common/OtoError';
import OtoSpinner from '../../common/OtoSpinner';
import { getIsClosedForTime } from '../../../app/date-helpers';
import { TOO_SHORT_ADVANCE } from '../../../app/lang';
import { getActiveReservationCustomRules, isBookingPartner, } from '../../../app/logic';
import { getBookingConfig } from 'App/app/general';
import OtoAlertNew from 'Comps/common/OtoAlertNew';
import ReservationTooBigGroupAlert from 'Comps/reservations/ReservationTooBigGroupAlert';
var noTimesForPartner = 'Brak dostępnych miejsc o wybranej porze.';
var maxGroupsByRestaurantId = {
    29: 8,
    65: 15,
    141: 15,
};
var RestaurantBookingButtonsAndAlerts = /** @class */ (function (_super) {
    __extends(RestaurantBookingButtonsAndAlerts, _super);
    function RestaurantBookingButtonsAndAlerts() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getCustomRules = function () {
            var _a = _this.props, restaurant = _a.restaurant, peopleAmount = _a.peopleAmount, visitDate = _a.visitDate;
            var activeCRs = getActiveReservationCustomRules(restaurant, {
                peopleAmount: peopleAmount,
                visitDate: visitDate,
            });
            var hasDisablingCRs = !!activeCRs.filter(function (rule) { return rule.disableBook; })
                .length;
            return { activeCRs: activeCRs, hasDisablingCRs: hasDisablingCRs };
        };
        return _this;
    }
    RestaurantBookingButtonsAndAlerts.prototype.render = function () {
        var _a = this.props, instantBookings = _a.instantBookings, restaurant = _a.restaurant, selectedDayHours = _a.selectedDayHours;
        if (!isBookingPartner(restaurant)) {
            return this.renderSingleButton(selectedDayHours);
        }
        if (instantBookings.error) {
            return (React.createElement("div", { className: this.props.centerWrapperClass },
                React.createElement(OtoError, null)));
        }
        if (instantBookings.loading) {
            return (React.createElement("div", { className: this.props.centerWrapperClass },
                React.createElement(OtoSpinner, null)));
        }
        var availability = isBookingPartner(restaurant) &&
            instantBookings.data &&
            instantBookings.data.filter(function (item) { return item.restaurant_id === restaurant.id; });
        if (!availability) {
            return this.renderSingleButton(selectedDayHours);
        }
        return this.renderInstantBookButtons(selectedDayHours, availability[0]);
    };
    RestaurantBookingButtonsAndAlerts.prototype.renderInstantBookButtons = function (selectedDayHours, restaurantAvailability) {
        var _this = this;
        var _a = this.props, peopleAmount = _a.peopleAmount, restaurant = _a.restaurant;
        var _b = Restaurant.checkAdvance(restaurant, restaurantAvailability, this.props.visitDate), tooLittleAdvance = _b.tooLittleAdvance, timesThatMatchHourAdvance = _b.timesThatMatchHourAdvance;
        if (tooLittleAdvance) {
            return this.renderTooLittleAdvance();
        }
        var _c = this.getCustomRules(), activeCRs = _c.activeCRs, hasDisablingCRs = _c.hasDisablingCRs;
        if (hasDisablingCRs) {
            return React.createElement(CustomRules, { className: "py-1 mb-3", rules: activeCRs });
        }
        if (!timesThatMatchHourAdvance || !timesThatMatchHourAdvance.length) {
            return this.renderSingleButton(selectedDayHours, noTimesForPartner);
        }
        var disabledButtonsAmount = 0;
        var buttons = timesThatMatchHourAdvance.map(function (_a) {
            var available_seats = _a.available_seats, time = _a.time, reason = _a.reason;
            var disabled = available_seats < peopleAmount;
            if (disabled) {
                disabledButtonsAmount++;
            }
            var mapTooltipToReason = {
                not_in_advance_enough: 'Zbyt krótkie wyprzedzenie czasowe. Wybierz inną godzinę.',
                reservation_closed: 'Rezerwacja o wybranej porze nie jest możliwa',
                no_free_seats: 'Brak możliwości rezerwacji - lokal posiada komplet rezerwacji. Wybierz inną datę.',
                not_enough_seats: 'Brak możliwości rezerwacji - lokal posiada komplet rezerwacji. Wybierz inną datę.',
            };
            var tooltipText = mapTooltipToReason[reason] || null;
            return (React.createElement("button", { key: time, type: "button", className: "btn restaurant__reserve-btn", disabled: disabled, onClick: function () { return _this.props.handleReservationClick(restaurant, time); } },
                time,
                tooltipText && React.createElement("span", { className: "oto-tooltip" }, tooltipText)));
        });
        var _d = getBookingConfig(restaurant) || {}, auto_reservation_max_guests = _d.auto_reservation_max_guests, reservations_warning = _d.reservations_warning;
        var maxPeopleAmount = auto_reservation_max_guests || maxGroupsByRestaurantId[restaurant.id];
        var canBookForSelectedPeopleAmount = !maxPeopleAmount || peopleAmount <= maxPeopleAmount;
        return (React.createElement(React.Fragment, null,
            React.createElement(CustomRules, { className: "py-1 mb-3", rules: activeCRs }),
            reservations_warning && (React.createElement(OtoAlertNew, { color: "warning", className: "py-1 mb-3", withBorder: true }, reservations_warning)),
            canBookForSelectedPeopleAmount ? (React.createElement(React.Fragment, null,
                disabledButtonsAmount ? this.renderNoPlaceInfo() : null,
                React.createElement("div", { className: "restaurant__hours ".concat(this.props.restaurantHoursClass) }, buttons))) : (React.createElement(ReservationTooBigGroupAlert, { maxPeopleAmount: maxPeopleAmount, restaurant: restaurant }))));
    };
    RestaurantBookingButtonsAndAlerts.prototype.renderSingleButton = function (selectedDayHours, customDisableMessage) {
        var _this = this;
        if (customDisableMessage === void 0) { customDisableMessage = null; }
        var _a = this.props, canBookNonPartner = _a.canBookNonPartner, restaurant = _a.restaurant, visitTime = _a.visitTime;
        var isClosed = getIsClosedForTime(selectedDayHours, visitTime);
        var _b = this.getCustomRules(), activeCRs = _b.activeCRs, hasDisablingCRs = _b.hasDisablingCRs;
        if (hasDisablingCRs) {
            return React.createElement(CustomRules, { className: "py-1 mb-3", rules: activeCRs });
        }
        var tooltipText = '';
        if (isClosed) {
            tooltipText =
                'O wybranej porze lokal jest zamknięty. Wybierz inną godzinę.';
        }
        else if (!canBookNonPartner) {
            tooltipText =
                'Brak możliwości rezerwacji - zbyt krótkie wyprzedzenie czasowe. Wybierz inną godzinę.';
        }
        else if (!!customDisableMessage) {
            tooltipText = customDisableMessage;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(CustomRules, { className: "py-1 mb-3", rules: activeCRs }),
            React.createElement("div", { className: "restaurant__hours ".concat(this.props.restaurantHoursClass) },
                React.createElement("button", { type: "button", className: "btn restaurant__reserve-btn restaurant__reserve-btn--single", disabled: isClosed || !canBookNonPartner || !!customDisableMessage, onClick: function () {
                        return _this.props.handleReservationClick(restaurant, visitTime);
                    } },
                    "Sprawd\u017A dost\u0119pno\u015B\u0107 i zarezerwuj o ",
                    visitTime,
                    tooltipText && React.createElement("span", { className: "oto-tooltip" }, tooltipText)))));
    };
    RestaurantBookingButtonsAndAlerts.prototype.renderTooLittleAdvance = function () {
        var restaurant = this.props.restaurant;
        return React.createElement(OtoAlert, { color: "info", text: TOO_SHORT_ADVANCE(restaurant) });
    };
    RestaurantBookingButtonsAndAlerts.prototype.renderNoPlaceInfo = function () {
        var rules = (this.props.restaurant && this.props.restaurant).custom_rules;
        if (!rules || !rules.length) {
            return null;
        }
        var rule = rules.filter(function (rule) { return rule.key === 'no_place_info'; })[0];
        return rule && rule.text && React.createElement(OtoAlert, { color: "info", text: rule.text });
    };
    RestaurantBookingButtonsAndAlerts.displayName = 'RestaurantBookingButtonsAndAlerts';
    RestaurantBookingButtonsAndAlerts.propTypes = {
        canBookNonPartner: PropTypes.bool.isRequired,
        centerWrapperClass: PropTypes.string,
        restaurantHoursClass: PropTypes.string,
        selectedDayHours: PropTypes.string,
        instantBookings: PropTypes.object.isRequired,
        peopleAmount: PropTypes.number.isRequired,
        visitDate: PropTypes.object.isRequired,
        visitTime: PropTypes.string.isRequired,
        restaurant: PropTypes.object.isRequired,
        handleReservationClick: PropTypes.func.isRequired,
    };
    RestaurantBookingButtonsAndAlerts.defaultProps = {
        centerWrapperClass: 'w-100 text-center text-md-left',
        restaurantHoursClass: '',
    };
    return RestaurantBookingButtonsAndAlerts;
}(React.PureComponent));
export default RestaurantBookingButtonsAndAlerts;
