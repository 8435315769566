import { IFRAME_CONFIG } from '../config';
import iframeObserver from '../utils/iframe-observer';

export function enableDatepicker(props) {
  const { container, format, startDate, initialValue, onDateChange } = props;
  const elem = $(container);
  if (elem && elem.datepicker) {
    elem.datepicker({
      autoclose: true,
      language: "pl",
      container,
      orientation: "bottom left",
      startDate,
      ignoreReadonly: true,
      format,
    });
    elem.datepicker().on('changeDate', onDateChange);
    elem.datepicker('setDate', initialValue);
  }
}

export function enablePopover() {
  $(function () {
    $('[data-toggle="popover"]').popover()
  });
}

export function bindOpeningHoursPopover() {
  $('.restaurant__show-hours[data-toggle="popover"]').popover();
}

export function reenableRestaurantLabelPopover() {
  $('.restaurant__label[data-toggle="popover"]').popover();
}

export function isDesktop() {
  return window.innerWidth > 991;
}

export function expandSidebar() {
  $('#sidebarContent').collapse && $('#sidebarContent').collapse('show');
}

export function expandSidebarIfDesktop() {
  if (isDesktop()) {
    expandSidebar();
  }
}

const defaultOffset = 72;
export function smoothScroll(offset = defaultOffset) {
  if (isNaN(offset)) {
    offset = defaultOffset;
  }
  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      smoothScrollTo(this.hash);
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function () {
    $('.navbar-collapse').collapse('hide');
  });
}

export function smoothScrollTo(selector, offset = defaultOffset) {
  let target = $(selector);
  target = target.length ? target : $('[name=' + selector.slice(1) + ']');
  if (target.length) {
    let scrollTop = (target.offset().top - offset);
    if (iframeObserver.hasParentIframe()) {
      parentIFrame.scrollTo(0, scrollTop + iframeObserver.lastPageInfo.offsetTop - IFRAME_CONFIG.fixedHeaderHeight);
    } else {
      $('html, body').animate({
        scrollTop: scrollTop
      }, 1000, "easeInOutExpo");
    }
    return false;
  }
}

export function enablePartnerLogosSlider() {
  $(document).ready(function () {
    $('.partner-logos').slick({
      slidesToScroll: 1,
      slidesToShow: 8,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 3000,
      cssEase: 'linear',
      infinite: true,
      arrows: false,
      dots: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 768,
          settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 520,
          settings: { slidesToShow: 3 }
        }
      ]
    });
  });
}

export function hasFixedHeader() {
  const mainNav = document.querySelector('#mainNav');
  if(!mainNav) {
    return false
  }
  return getComputedStyle(mainNav)?.position === 'fixed'
}

export function hasStaticHeader() {
  return !hasFixedHeader();
}
