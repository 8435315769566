import React from 'react';
import PropTypes from 'prop-types';

import { enablePopover } from '../../app/ui';
import QuestionCircle from '../../icons/QuestionCircle';

const popoverProps = {
  'data-toggle': 'popover',
  'data-placement': 'top',
  'data-trigger': 'hover',
};

export default class IconWithPopover extends React.Component {
  componentDidMount() {
    enablePopover();
  }

  render() {
    const { className, icon, title, text } = this.props;
    const textDependingProps = text ? {} : {
      template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
    }
    return <span className={className} title={title} data-content={text} {...textDependingProps}  {...popoverProps}>
      {icon || <QuestionCircle />}
    </span>
  }
}

IconWithPopover.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
};
