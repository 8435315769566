var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var today = new Date();
export var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
export function getWeekDayIndex(date) {
    var weekDayIndex = date.getDay();
    return (weekDayIndex === 0 ? 6 : weekDayIndex - 1);
}
export function isMon(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 1;
}
export function isTue(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 2;
}
export function isWed(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 3;
}
export function isThu(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 4;
}
export function isFri(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 5;
}
export function isSat(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 6;
}
export function isSun(date) {
    if (date === void 0) { date = new Date(); }
    return date.getDay() === 0;
}
export function splitOpeningHours(hoursString) {
    return hoursString.replace(/<div>/g, '').split('</div>');
}
export function isToday(date) {
    return isDaySame(date, new Date());
}
export function isDaySame(date1, date2) {
    return (date1.getDate() === date2.getDate()
        && date1.getMonth() === date2.getMonth()
        && date1.getFullYear() === date2.getFullYear());
}
export function getIsClosedForTime(openingHours, time) {
    var openStateInfo = getIsOpenState(openingHours, parseInt(time));
    return (openStateInfo.isValid && !openStateInfo.isOpen);
}
export function getIsOpenState(openingHours, hourToCheck) {
    var invalidState = {
        isValid: false,
    };
    if (!openingHours || !openingHours.includes) {
        return invalidState;
    }
    if (openingHours.includes('Zamknięte')) {
        return {
            isValid: true,
            isOpen: false
        };
    }
    if (openingHours.includes('Czynne całą dobę')) {
        return {
            isValid: true,
            isOpen: true
        };
    }
    try {
        var hasMultipleOpeningHours = openingHours.includes(',');
        var openHour = openingHours.split('–')[0].split(' ')[1];
        var closeHour = openingHours.split('–')[1];
        var parsedOpenHour = parseInt(openHour, 10);
        var parsedCloseHour = parseInt(closeHour, 10);
        var areHoursNumeric = !isNaN(parsedOpenHour) && !isNaN(parsedCloseHour);
        if (parsedCloseHour === 0 || parsedCloseHour < parsedOpenHour) {
            parsedCloseHour = 24;
        }
        if (areHoursNumeric && !hasMultipleOpeningHours) {
            var isOpenAtSelectedTime = (hourToCheck >= parsedOpenHour) && (hourToCheck < parsedCloseHour);
            return {
                isValid: true,
                isOpen: isOpenAtSelectedTime
            };
        }
        else {
            return invalidState;
        }
    }
    catch (e) {
        return invalidState;
    }
}
export var daysLabels = {
    0: 'niedziela',
    1: 'poniedziałek',
    2: 'wtorek',
    3: 'środa',
    4: 'czwartek',
    5: 'piątek',
    6: 'sobota'
};
export var normalizedDaysLabels = __spreadArray(__spreadArray([], __read(Object.values(daysLabels).slice(1)), false), __read(Object.values(daysLabels).slice(0, 1)), false);
export function getSelectedDateLabel(date, currentDate) {
    if (currentDate === void 0) { currentDate = new Date(); }
    var isMonthTheSame = date.getMonth() === currentDate.getMonth();
    var isDayTheSame = date.getDate() === currentDate.getDate();
    var isTomorrow = date.getDate() === (currentDate.getDate() + 1);
    var readableDate = toDateInputValue(date, { sep: '.', reversed: true });
    if (isMonthTheSame && isDayTheSame) {
        return {
            date: readableDate,
            label: '(dzisiaj)'
        };
    }
    if (isMonthTheSame && isTomorrow) {
        return {
            date: readableDate,
            label: '(jutro)'
        };
    }
    return {
        date: readableDate,
        label: "(".concat(daysLabels[date.getDay()], ")")
    };
}
export function toDateInputValue(local, options) {
    if (options === void 0) { options = { sep: '-', reversed: false }; }
    var _a = options.sep, sep = _a === void 0 ? '-' : _a, _b = options.reversed, reversed = _b === void 0 ? false : _b;
    var parts = [
        local.getFullYear(),
        ('0' + (local.getMonth() + 1)).slice(-2),
        ('0' + local.getDate()).slice(-2)
    ];
    if (reversed) {
        return parts.reverse().join(sep);
    }
    return parts.join(sep);
}
;
var ONE_HOUR = 60 * 60 * 1000;
export var diffHours = function (d1, d2) { return Math.round(Math.abs((d1 - d2) / ONE_HOUR)); };
export function parseDate(dateStr) {
    if (!dateStr || dateStr.length < 10) {
        return null;
    }
    try {
        var dd = parseInt(dateStr.slice(0, 2), 10);
        var mm = parseInt(dateStr.slice(3, 5), 10) - 1;
        var yyyy = parseInt(dateStr.slice(6, 10), 10);
        return new Date(yyyy, mm, dd);
    }
    catch (e) {
        console.error('parseDate error: ', e);
        return null;
    }
}
export function getTodayDeliveryHours(deliveryHours) {
    if (deliveryHours && deliveryHours.length) {
        return deliveryHours[getWeekDayIndex(new Date())];
    }
    return null;
}
