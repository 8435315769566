var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import Marker from '../../icons/Marker';
import OtoSpinner from '../common/OtoSpinner';
import { debounce } from 'App/app/lodash-cut';
import './address-autocomplete.scss';
import TenantService from 'App/services/tenant';
var renderSuggestions = function (suggestions, getSuggestionItemProps) {
    return suggestions.map(function (suggestion) {
        var className = suggestion.active
            ? 'suggestion-item suggestion-item--active'
            : 'suggestion-item';
        var otherProps = getSuggestionItemProps(suggestion, {
            className: className,
        });
        return (React.createElement("div", __assign({}, otherProps, { key: suggestion.placeId }),
            React.createElement("span", null, suggestion.description)));
    });
};
var areMapsReady = function () { return window.google && window.google.maps; };
var AddressAutocomplete = function (props) {
    var _a = props.addIcon, addIcon = _a === void 0 ? false : _a, _b = props.className, className = _b === void 0 ? '' : _b, _c = props.inputClassName, inputClassName = _c === void 0 ? '' : _c, handleValueChange = props.handleValueChange, handleSelect = props.handleSelect, _d = props.placeholder, placeholder = _d === void 0 ? 'Wyszukaj adres ...' : _d, value = props.value;
    var _e = __read(useState(false), 2), mapsLoaded = _e[0], setMapsLoaded = _e[1];
    var _f = __read(useState(false), 2), showPlainText = _f[0], setShowPlainText = _f[1];
    var debouncedSelect = React.useMemo(function () { return debounce(handleSelect, 500); }, [handleSelect]);
    var handlePlainInputChange = function (e) {
        handleValueChange(e.target.value);
        debouncedSelect(e.target.value);
    };
    var selectIfNotEmpty = function (value) {
        if (!!value && value.length > 3) {
            handleSelect(value);
        }
    };
    var showInputIfMapsReady = function () {
        if (areMapsReady()) {
            setMapsLoaded(true);
            return true;
        }
        return false;
    };
    useEffect(function () {
        if (!showInputIfMapsReady()) {
            setTimeout(showInputIfMapsReady, 1000);
            setTimeout(function () {
                if (!areMapsReady()) {
                    setShowPlainText(true);
                }
            }, 5000);
        }
    }, []);
    if (showPlainText) {
        return (React.createElement("div", { className: "".concat(className, " no-suggestions") },
            React.createElement("input", { placeholder: placeholder, onChange: handlePlainInputChange, className: "form-control ".concat(inputClassName), value: value }),
            props.children));
    }
    if (!mapsLoaded) {
        return (React.createElement("div", { className: "centrify w-100" },
            React.createElement(OtoSpinner, null)));
    }
    var renderInputPart = function (getInputProps) {
        var input = (React.createElement("input", __assign({}, getInputProps({
            placeholder: placeholder,
            className: "form-control ".concat(inputClassName),
        }))));
        if (addIcon) {
            return (React.createElement("div", { className: "input-group" },
                React.createElement("div", { className: "input-group-prepend" },
                    React.createElement("span", { className: "input-group-text", id: "calendar-addon" },
                        React.createElement(Marker, null))),
                input));
        }
        return input;
    };
    return (React.createElement(PlacesAutocomplete, { onChange: handleValueChange, onSelect: selectIfNotEmpty, highlightFirstSuggestion: true, shouldFetchSuggestions: value.length > 2, searchOptions: getSearchOptions(), value: value }, function (_a) {
        var getInputProps = _a.getInputProps, suggestions = _a.suggestions, getSuggestionItemProps = _a.getSuggestionItemProps, loading = _a.loading;
        return (React.createElement("div", { className: "".concat(className, " ").concat(suggestions.length ? 'has-suggestions' : 'no-suggestions') },
            renderInputPart(getInputProps),
            React.createElement("div", { className: "autocomplete-dropdown-container" },
                loading && React.createElement("div", null, "Szukam adresu..."),
                renderSuggestions(suggestions, getSuggestionItemProps)),
            props.children));
    }));
};
AddressAutocomplete.displayName = 'AddressAutocomplete';
AddressAutocomplete.propTypes = {
    addIcon: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    handleSelect: PropTypes.func.isRequired,
    handleValueChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
};
AddressAutocomplete.defaultProps = {
    value: '',
};
function getSearchOptions() {
    var coords = TenantService.getCoordinates();
    return {
        location: new window.google.maps.LatLng(coords.lat, coords.lng),
        radius: 10000,
        types: ['address'],
    };
}
export default AddressAutocomplete;
