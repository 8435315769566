var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { APIService } from 'App/services/api';
import LogService from 'Utils/log';
import BannerTile from './BannerTile';
var BannersSection = function () {
    var _a = __read(useState([]), 2), banners = _a[0], setBanners = _a[1];
    useEffect(function () {
        APIService.get('/banners')
            .then(function (response) { return response.json(); })
            .then(setBanners)
            .catch(function (error) {
            LogService.fetchError({
                actionName: 'Banners fetch',
                url: '/banners',
                e: error,
            });
        });
    }, []);
    if (!banners || !banners.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null, banners.map(function (banner) { return (React.createElement(BannerTile, { key: banner.sizes[0].src, banner: banner })); })));
};
BannersSection.displayName = 'BannersSection';
export default React.memo(BannersSection);
