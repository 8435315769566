var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalFooter, Label, Input, } from 'reactstrap';
import OtoError from './common/OtoError';
import CalendarAlt from '../icons/CalendarAlt';
import Clock from '../icons/Clock';
import UserFriends from '../icons/UserFriends';
import Utensils from '../icons/Utensils';
import { mapReservationFormValuesToDTO, createReservation, } from '../utils/reservation';
import { getSelectedDateLabel } from '../app/date-helpers';
import { NO_ADDITIONAL_NOTES_GUARANTEE } from '../app/lang';
import { hasKids, hasAreaPreferencePossibility, getBookingConfig, } from '../app/general';
import { createDerivedStateFromErrorLogger, logComponentDidCatch, } from '../utils/log';
import Restaurant from '../utils/restaurant';
import { getPeopleAmountLabel } from '../utils/text-formatters';
import OtoSpinner from './common/OtoSpinner';
import ReservationRestaurantClosedAlert from './ReservationRestaurantClosedAlert';
import { AnalyticsService } from 'Utils/tracking';
import OtoAlertNew from './common/OtoAlertNew';
import { isDesktop } from 'App/app/ui';
import Shapes from 'Shapes';
var areaPreferenceOptions = [
    { key: '', label: 'brak preferencji' },
    { key: 'outside', label: 'ogródek' },
    { key: 'inside', label: 'wnętrze' },
];
var reservationDurationOptions = [
    { label: '1h', value: 60 },
    { label: '1.5h', value: 90 },
    { label: '2h', value: 120 },
    { label: '2.5h', value: 150 },
    { label: '3h', value: 180 },
    { label: '4h', value: 240 },
    { label: '5h', value: 300 },
    { label: 'Do końca dnia', value: 720 },
];
var COMPONENT_NAME = 'ReservationModal';
var ReservationModal = /** @class */ (function (_super) {
    __extends(ReservationModal, _super);
    function ReservationModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            bookingTimeExplanationVisible: false,
            loading: false,
            hasError: false,
            values: {
                customer_fn: '',
                customer_ln: '',
                customer_phone: '',
                notes_from_guest: '',
                kids_number: '0',
            },
        };
        _this.bookingWaitingTimes = {
            instant: 'natychmiast',
            couple_of_hours: 'do kilku godzin',
            twice_per_day: 'do 1 dnia',
        };
        _this.getBookingWaitingTime = function (restaurant) {
            return (_this.bookingWaitingTimes[restaurant.booking_waiting_time] ||
                restaurant.booking_waiting_time);
        };
        _this.showBookingTimeExplanation = function () {
            return _this.setState({ bookingTimeExplanationVisible: true });
        };
        _this.handleChange = function (e) {
            var _a, _b;
            if (e.target.type === 'checkbox') {
                return _this.setState({
                    values: __assign(__assign({}, _this.state.values), (_a = {}, _a[e.target.name] = e.target.checked, _a)),
                });
                return;
            }
            _this.setState({
                values: __assign(__assign({}, _this.state.values), (_b = {}, _b[e.target.name] = e.target.value, _b)),
            });
        };
        _this.renderWaitingTimeAlert = function () { return (React.createElement(OtoAlertNew, { className: "m-2", color: "info", withBorder: true }, "Czas oczekiwania na potwierdzenie rezerwacji zale\u017Cy od warunk\u00F3w wsp\u00F3\u0142pracy z restauracj\u0105. Wp\u0142yw na d\u0142ugo\u015B\u0107 procesu ma mi\u0119dzy innymi wykorzystanie naszego systemu do przyjmowania rezerwacji (natychmiastowe potwierdzenie) lub rezygnacja z niego (potwierdzenie po kontakcie telefonicznym z lokalem). Robimy, co w naszej mocy, aby maksymalnie skr\u00F3ci\u0107 Pa\u0144stwa oczekiwanie na potwierdzenie.")); };
        _this.handleSubmit = function (event) {
            event.preventDefault();
            event.stopPropagation();
            var form = document.getElementById('reservationModalForm');
            var isValid = form.checkValidity();
            form === null || form === void 0 ? void 0 : form.classList.add('was-validated');
            if (!isValid) {
                return;
            }
            var reservationData = mapReservationFormValuesToDTO(__assign(__assign({}, _this.state.values), { restaurantId: _this.props.restaurant.id, visitDate: _this.props.visitDate, visitTime: _this.props.visitTime, peopleAmount: _this.props.peopleAmount, source: _this.props.source }));
            _this.setState({ loading: true });
            AnalyticsService.trackBookingPurchase();
            createReservation(reservationData, _this.showErrorModal);
        };
        _this.showErrorModal = function () {
            return _this.setState({
                hasError: true,
                loading: false,
            });
        };
        return _this;
    }
    ReservationModal.prototype.componentDidCatch = function (error, info) {
        logComponentDidCatch(COMPONENT_NAME, error, info);
    };
    ReservationModal.prototype.renderDetails = function () {
        var _a = this.props, explainConfirmationDuration = _a.explainConfirmationDuration, peopleAmount = _a.peopleAmount, visitTime = _a.visitTime, visitDate = _a.visitDate, restaurant = _a.restaurant;
        var bookingTimeExplanationVisible = this.state.bookingTimeExplanationVisible;
        var _b = getSelectedDateLabel(visitDate), date = _b.date, label = _b.label;
        var has_kids = hasKids(restaurant);
        var details = (React.createElement("div", { className: "reserve-modal__details" },
            React.createElement("div", null,
                React.createElement(CalendarAlt, null),
                "Data: ",
                date,
                ' ',
                React.createElement("span", { className: "reserve-modal__details-date-label" }, label)),
            React.createElement("div", null,
                React.createElement(Clock, null),
                "Godzina: ",
                visitTime),
            React.createElement("div", null,
                React.createElement(UserFriends, null),
                "Stolik dla:",
                ' ',
                getPeopleAmountLabel(peopleAmount, {
                    has_kids: has_kids,
                    kids_number: parseInt(this.state.values.kids_number || '0', 10),
                })),
            React.createElement("div", null,
                React.createElement(Utensils, null),
                "Lokal: ",
                restaurant.name),
            React.createElement("div", null,
                React.createElement("span", { className: "mr-1" },
                    React.createElement(Clock, null),
                    "Otrzymasz potwierdzenie:",
                    ' ',
                    React.createElement("span", { className: "text-primary" }, this.getBookingWaitingTime(restaurant)))),
            bookingTimeExplanationVisible &&
                !isDesktop() &&
                this.renderWaitingTimeAlert(),
            !bookingTimeExplanationVisible && explainConfirmationDuration && (React.createElement("button", { className: "btn btn-custom-outlined", type: "button", onClick: this.showBookingTimeExplanation }, "od czego zale\u017Cy czas oczekiwania na rezerwacj\u0119?"))));
        return details;
    };
    ReservationModal.prototype.render = function () {
        var _this = this;
        var toggle = function () { return _this.props.hideModal(); };
        var form = document.getElementById('reservationModalForm');
        var showMarketingFields = this.props.showMarketingFields;
        return (React.createElement(Modal, { isOpen: true, toggle: toggle, className: "reserve-modal" },
            React.createElement("form", { className: "modal-content", id: "reservationModalForm", noValidate: true, onSubmit: this.handleSubmit },
                React.createElement(ModalHeader, { toggle: toggle }, "Dane rezerwacji"),
                React.createElement(ModalBody, { className: "row" },
                    React.createElement(ReservationRestaurantClosedAlert, { restaurant: this.props.restaurant }),
                    React.createElement("div", { className: "reserve-modal__part reserve-modal__part--details" }, this.renderDetails()),
                    this.renderInputsPart(),
                    this.state.bookingTimeExplanationVisible &&
                        isDesktop() &&
                        this.renderWaitingTimeAlert()),
                React.createElement(ModalFooter, null,
                    React.createElement("div", { className: "form-check w-100 mb-2" },
                        React.createElement("input", { className: "form-check-input", type: "checkbox", value: "", id: "terms-of-use", required: true }),
                        React.createElement("label", { className: "form-check-label", htmlFor: "terms-of-use" },
                            "Zapozna\u0142em si\u0119 i akceptuj\u0119",
                            ' ',
                            React.createElement("a", { href: "/regulamin", target: "_blank" }, "regulamin"),
                            ' ',
                            "oraz",
                            ' ',
                            React.createElement("a", { href: "/polityka-prywatnosci", target: "_blank" }, "polityk\u0119 prywatno\u015Bci"))),
                    showMarketingFields && (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            "Chcesz otrzymywa\u0107 informacje o",
                            ' ',
                            React.createElement("span", { className: "text-primary" }, "promocjach oraz nowo\u015Bciach"),
                            ' ',
                            "w tym lokalu?",
                            React.createElement("br", null),
                            "Wyra\u017Cam zgod\u0119 na otrzymywanie informacji marketingowych:"),
                        React.createElement("div", { className: "form-check w-100" },
                            React.createElement("input", { className: "form-check-input", id: "sms_marketing", name: "sms_marketing", onChange: this.handleChange, type: "checkbox" }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "sms_marketing" }, "za po\u015Brednictwem SMS")),
                        React.createElement("div", { className: "form-check w-100" },
                            React.createElement("input", { className: "form-check-input", id: "email_marketing", name: "email_marketing", onChange: this.handleChange, type: "checkbox" }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "email_marketing" }, "za po\u015Brednictwem email")))),
                    this.renderFooterActions()))));
    };
    ReservationModal.prototype.renderInputsPart = function () {
        var _a = this.props, restaurant = _a.restaurant, peopleAmount = _a.peopleAmount, showEmailField = _a.showEmailField;
        var hasOutsideBookPossibility = hasAreaPreferencePossibility(restaurant);
        var bookingConfig = getBookingConfig(restaurant) || {
            reservation_duration_options: false,
        };
        var guestDurations = bookingConfig.reservation_duration_options_for_guests, restaurantDurations = bookingConfig.reservation_duration_options;
        var durationOptions = (guestDurations === null || guestDurations === void 0 ? void 0 : guestDurations.length)
            ? guestDurations
            : restaurantDurations || [];
        return (React.createElement("div", { className: "reserve-modal__part reserve-modal__part--inputs" },
            React.createElement("input", { onChange: this.handleChange, id: "customer_fn", type: "text", name: "customer_fn", placeholder: "Imi\u0119", required: true }),
            React.createElement("div", { className: "invalid-feedback" }, "Zapoznajmy si\u0119 :)"),
            React.createElement("input", { onChange: this.handleChange, id: "customer_ln", type: "text", name: "customer_ln", placeholder: "Nazwisko", required: true }),
            React.createElement("div", { className: "invalid-feedback" }, "Cz\u0119\u015B\u0107 lokali pozwala na rezerwacj\u0119 wy\u0142\u0105cznie na nazwisko"),
            React.createElement("input", { onChange: this.handleChange, id: "customer_phone", name: "customer_phone", type: "tel", placeholder: "Telefon", required: true, pattern: "([0-9]{9})|([0-9]{3} [0-9]{3} [0-9]{3})|([0-9]{3}-[0-9]{3}-[0-9]{3})|(\\+\\d{11})|(\\+\\d{2}-\\d{3}-\\d{3}-\\d{3})|(\\+\\d{2} \\d{3} \\d{3} \\d{3})" }),
            React.createElement("div", { className: "invalid-feedback" }, "Podaj prosz\u0119 numer telefonu, aby\u015Bmy mogli potwierdzi\u0107 rezerwacj\u0119, lub poinformowa\u0107 Ci\u0119, je\u015Bli co\u015B p\u00F3jdzie nie tak"),
            showEmailField && (React.createElement(React.Fragment, null,
                React.createElement("input", { onChange: this.handleChange, id: "customer_email", name: "customer_email", type: "email", placeholder: "Email", required: true }),
                React.createElement("div", { className: "invalid-feedback" }, "Restauracja wymaga podania adresu email w celu dokonania rezerwacji"))),
            React.createElement("div", { className: "reserve-modal__disclaimer" }, "*Nie przekazujemy danych osobom trzecim, s\u0105 one potrzebne wy\u0142\u0105cznie do potwierdzenia rezerwacji."),
            hasKids(restaurant) ? (React.createElement("input", { onChange: this.handleChange, type: "number", id: "kids_number", name: "kids_number", placeholder: "Liczba dzieci siedz\u0105cych", className: "form-control mb-2", min: "0", max: peopleAmount })) : null,
            Restaurant.wantsToCountNonVaccinated(restaurant) && (React.createElement(React.Fragment, null,
                React.createElement(Label, { for: "non_vaccinated_amount" }, "Liczba os\u00F3b niezaszczepionych"),
                React.createElement(Input, { onChange: this.handleChange, type: "select", id: "non_vaccinated_amount", name: "non_vaccinated_amount", placeholder: "Liczba os\u00F3b niezaszczepionych", className: "form-control mb-2", required: true, min: "0", max: peopleAmount },
                    React.createElement("option", { key: "", value: "" }),
                    React.createElement("option", { key: "0", value: 0 }, "wszyscy s\u0105 zaszczepieni"),
                    Array.from(Array(peopleAmount)).map(function (_, num) { return (React.createElement("option", { key: num + 1, value: num + 1 }, num + 1)); })),
                React.createElement("div", { className: "invalid-feedback" }, "Podaj prosz\u0119 liczb\u0119 os\u00F3b niezaszczepionych"))),
            hasOutsideBookPossibility && (React.createElement(React.Fragment, null,
                React.createElement("label", { htmlFor: "area_preferences", className: "mb-0" }, "Ogr\u00F3dek czy wn\u0119trze?"),
                React.createElement("select", { id: "area_preferences", name: "area_preferences", onChange: this.handleChange, className: "form-control mb-3" }, areaPreferenceOptions.map(function (opt) { return (React.createElement("option", { key: opt.key, value: opt.key }, opt.label)); })))),
            !!durationOptions.length && (React.createElement(React.Fragment, null,
                React.createElement("label", { htmlFor: "duration", className: "mb-0" }, "D\u0142ugo\u015B\u0107 rezerwacji"),
                React.createElement("select", { id: "duration", name: "duration", onChange: this.handleChange, className: "form-control mb-3" }, durationOptions.map(function (opt) {
                    var _a;
                    return (React.createElement("option", { key: opt, value: opt }, (_a = reservationDurationOptions.find(function (o) { return o.value === opt; })) === null || _a === void 0 ? void 0 : _a.label));
                })))),
            React.createElement("textarea", { onChange: this.handleChange, className: "form-control", id: "notes_from_guest", name: "notes_from_guest", placeholder: "Dodatkowe uwagi dla restauracji" }),
            this.state.values.notes_from_guest && (React.createElement("div", { className: "alert alert-warning small w-100 m-0 mt-2" }, NO_ADDITIONAL_NOTES_GUARANTEE))));
    };
    ReservationModal.prototype.renderFooterActions = function () {
        var _a = this.state, hasError = _a.hasError, loading = _a.loading;
        if (hasError) {
            return React.createElement(OtoError, null);
        }
        return loading ? (React.createElement(OtoSpinner, null)) : (React.createElement("button", { type: "submit", className: "btn btn-primary", id: "reservationModalButton" }, "Zarezerwuj"));
    };
    ReservationModal.displayName = COMPONENT_NAME;
    ReservationModal.getDerivedStateFromError = createDerivedStateFromErrorLogger(COMPONENT_NAME);
    ReservationModal.propTypes = {
        hideModal: PropTypes.func.isRequired,
        peopleAmount: PropTypes.number.isRequired,
        restaurant: Shapes.restaurantShape.isRequired,
        source: PropTypes.string.isRequired,
        visitTime: PropTypes.string.isRequired,
        visitDate: PropTypes.instanceOf(Date).isRequired,
    };
    return ReservationModal;
}(React.PureComponent));
export default ReservationModal;
