export function levenshteinDistance(a, b) {
    // Create empty edit distance matrix for all possible modifications of
    // substrings of a to substrings of b.
    var distanceMatrix = Array(b.length + 1).fill(null).map(function () { return Array(a.length + 1).fill(null); });
    // Fill the first row of the matrix.
    // If this is first row then we're transforming empty string to a.
    // In this case the number of transformations equals to size of a substring.
    for (var i = 0; i <= a.length; i += 1) {
        distanceMatrix[0][i] = i;
    }
    // Fill the first column of the matrix.
    // If this is first column then we're transforming empty string to b.
    // In this case the number of transformations equals to size of b substring.
    for (var j = 0; j <= b.length; j += 1) {
        distanceMatrix[j][0] = j;
    }
    for (var j = 1; j <= b.length; j += 1) {
        for (var i = 1; i <= a.length; i += 1) {
            var indicator = a[i - 1] === b[j - 1] ? 0 : 1;
            distanceMatrix[j][i] = Math.min(distanceMatrix[j][i - 1] + 1, // deletion
            distanceMatrix[j - 1][i] + 1, // insertion
            distanceMatrix[j - 1][i - 1] + indicator);
        }
    }
    return distanceMatrix[b.length][a.length];
}
