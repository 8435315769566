import * as Sentry from '@sentry/react';
export var initSentry = function () {
    Sentry.init({
        dsn: 'https://ce5fc5e1d61f453f2b219a92d4ac9f6b@o4506390625452032.ingest.sentry.io/4506650508066816',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost',
                    /^https:\/\/api\.otostolik\.pl\/api/,
                    /^https:\/\/api\.lubje\.pl\/api/,
                    /^https:\/\/api\.leszno-je\.pl\/api/,
                    /^https:\/\/api\.zamow\.leszno\.je\/api/,
                    /^https:\/\/stage\.otostolik\.pl\/api/,
                    /^https:\/\/stage\.lubje\.pl\/api/,
                    /^https:\/\/stage\.leszno-je\.pl\/api/,
                    /^https:\/\/stage\.zamow\.leszno\.je\/api/,
                    /^http:\/\/otostolik\.local\/api/,
                    /^http:\/\/lubje\.local\/api/,
                    /^http:\/\/leszno\.local\/api/,
                    /^https:\/\/zamow\.leszno\.local\/api/,
                ],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.01,
        // Session Replay
        replaysSessionSampleRate: 0.005,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
