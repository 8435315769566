import React from 'react';
import PropTypes from 'prop-types';
import RestaurantLabelInstantBook from './RestaurantLabelInstantBook';
import { isBookingPartner } from 'App/app/logic';
import Shapes from 'Shapes';
var RestaurantTitle = function (props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, isOrderMode = props.isOrderMode, restaurant = props.restaurant, _b = props.showInstantBookLabel, showInstantBookLabel = _b === void 0 ? false : _b, _c = props.textClassName, textClassName = _c === void 0 ? '' : _c;
    return (React.createElement("div", { className: "restaurant__title ".concat(className), "data-id": restaurant.id },
        React.createElement("h2", { className: "restaurant__name ".concat(textClassName) }, isOrderMode ? (React.createElement("a", { href: "/zamawiaj/".concat(restaurant.slug) }, restaurant.name)) : (restaurant.name)),
        showInstantBookLabel && (React.createElement(RestaurantLabelInstantBook, { show: isBookingPartner(restaurant) }))));
};
RestaurantTitle.displayName = 'RestaurantTitle';
RestaurantTitle.propTypes = {
    className: PropTypes.string,
    isOrderMode: PropTypes.bool.isRequired,
    restaurant: Shapes.restaurantShape.isRequired,
    textClassName: PropTypes.string,
};
export default RestaurantTitle;
