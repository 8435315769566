var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import filterXss from 'xss';
import { toDateInputValue } from '../app/date-helpers';
import { APIService } from '../services/api';
import LogService from './log';
export function createReservation(reservationData, errorCallback) {
    var url = '/reservations';
    APIService.post(url, reservationData)
        .then(function (response) {
        if (response.status === 422) {
            LogService.error('createReservation 422', response, { instant: true });
        }
        return response.json();
    })
        .then(function (data) {
        if (data.code) {
            var urlParams = new URLSearchParams(filterXss(window.location.search));
            var queryPart = urlParams.get('showHeader') === 'false' ? '?showHeader=false' : '';
            window.location.href = "/thank-you/".concat(data.code).concat(queryPart);
        }
        else {
            LogService.error('createReservation no code', data, { instant: true });
            errorCallback && errorCallback();
        }
    })
        .catch(function (e) {
        LogService.fetchError({ url: url, e: e, actionName: 'createReservation' });
        errorCallback && errorCallback();
    });
}
export function mapReservationFormValuesToDTO(values) {
    var area_preferences = values.area_preferences, customer_fn = values.customer_fn, customer_ln = values.customer_ln, customer_phone = values.customer_phone, customer_email = values.customer_email, duration = values.duration, non_vaccinated_amount = values.non_vaccinated_amount, kids_number = values.kids_number, notes_from_guest = values.notes_from_guest, source = values.source, sms_marketing = values.sms_marketing, email_marketing = values.email_marketing;
    var phone = customer_phone.trim().split(' ').join('');
    var extras = __assign(__assign(__assign({}, (kids_number ? { kids_number: kids_number } : {})), (area_preferences ? { area_preferences: area_preferences } : {})), (non_vaccinated_amount ? { non_vaccinated_amount: non_vaccinated_amount } : {}));
    return __assign(__assign({ customer_name: "".concat(customer_fn, " ").concat(customer_ln), phone_number: phone, email: customer_email, restaurant_id: values.restaurantId, reserved_on: toDateInputValue(values.visitDate) + ' ' + values.visitTime + ':00' }, (!!duration ? { duration: duration } : {})), { guests_number: values.peopleAmount, notes_from_guest: notes_from_guest, source: source, extras: extras, sms_marketing: sms_marketing, email_marketing: email_marketing });
}
export function getHoursRange(start, end, step, format) {
    if (start === void 0) { start = 32400; }
    if (end === void 0) { end = 86400; }
    if (step === void 0) { step = 1800; }
    if (format === void 0) { format = 'H:i'; }
    // Convert a timestamp to the 'H:i' format
    var formatTime = function (timestamp) {
        return new Date(timestamp * 1000).toISOString().substr(11, 5);
    };
    // Generate a list of timestamps between start and end
    var generateTimestamps = function (start, end, step) {
        var timestamps = [];
        for (var current = start; current <= end; current += step) {
            timestamps.push(current);
        }
        return timestamps;
    };
    // Convert the list of timestamps to a time range
    var timestamps = generateTimestamps(start, end, step);
    var times = timestamps.map(function (timestamp) {
        return format
            ? formatTime(timestamp)
            : new Date(timestamp * 1000).toISOString().substr(11, 5);
    });
    return times;
}
