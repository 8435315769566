var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import filterXss from 'xss';
import { hasOrders, getDisabledOrdersText, isDebugMode, getOrdersConfig, } from '../app/logic';
import { restaurantsWithNoBookPossibility, AppMode, DEFAULT_VALUES, getAvailableCuisineTypes, MODES, } from '../config';
import { isDeliveryClosed } from './cart-logic';
import { getTodayDeliveryHours, tomorrow, parseDate } from '../app/date-helpers';
import { slugify } from '../app/general';
import TenantService from 'App/services/tenant';
var addDetailsToRestaurant = function (restaurant) {
    var ordersConfig = getOrdersConfig(restaurant);
    var deliveryHours = ordersConfig.deliveryHours;
    var todayDeliveryHours = getTodayDeliveryHours(deliveryHours);
    // TODO get this generated fields from BE
    var restaurantDetails = __assign(__assign({}, ordersConfig), { todayDeliveryHours: todayDeliveryHours, isDeliveryClosed: todayDeliveryHours
            ? isDeliveryClosed(todayDeliveryHours)
            : true, disabledOrdersText: getDisabledOrdersText(restaurant) });
    return __assign(__assign({}, restaurant), { details: restaurantDetails });
};
export function getRestaurantsWithCustomFields(allRestaurants, mode) {
    if (mode === void 0) { mode = AppMode.ORDER; }
    var isDebug = isDebugMode();
    var notDebugRestaurants = allRestaurants.filter(function (r) { return isDebug || r.status !== 'debug'; });
    if (mode === AppMode.RESERVATION) {
        var canBook = function (restaurant) {
            return !restaurantsWithNoBookPossibility.includes(restaurant.id);
        };
        return notDebugRestaurants.filter(canBook).map(addDetailsToRestaurant);
    }
    if (mode === AppMode.ORDER) {
        return notDebugRestaurants.filter(hasOrders).map(addDetailsToRestaurant);
    }
    return [];
}
export function getRestaurantListUrlParams() {
    var urlParams = new URLSearchParams(filterXss(window.location.search));
    var initialPeopleAmount = parseInt(urlParams.get('peopleAmount') || DEFAULT_VALUES.peopleAmount.toString(), 10);
    var initialVisitTime = urlParams.get('visitTime') || DEFAULT_VALUES.visitTime;
    var initialVisitDate = (urlParams.get('visitDate') &&
        parseDate(urlParams.get('visitDate'))) ||
        tomorrow;
    var cuisineParam = urlParams.get('rodzaj-kuchni');
    var mode = getAppMode(urlParams);
    var initialCuisineFilter = cuisineParam &&
        getAvailableCuisineTypes(mode).map(slugify).includes(slugify(cuisineParam))
        ? cuisineParam
        : '';
    return {
        mode: mode,
        initialCuisineFilter: initialCuisineFilter,
        initialPeopleAmount: initialPeopleAmount,
        initialVisitDate: initialVisitDate,
        initialVisitTime: initialVisitTime,
    };
}
function getAppMode(urlParams) {
    var safePathName = filterXss(window.location.pathname);
    if (safePathName === '/zamawiaj' || safePathName.includes('zamow.')) {
        return AppMode.ORDER;
    }
    if (safePathName === '/rezerwuj') {
        return AppMode.RESERVATION;
    }
    if (TenantService.isTenantWithDefaultOrderMode()) {
        return AppMode.ORDER;
    }
    return ((MODES.find(function (mode) { return mode.name === urlParams.get('mode'); }) || {}).name ||
        AppMode.RESERVATION);
}
