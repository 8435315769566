var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { getWebpSrc } from '../../utils/images';
import TenantService from 'App/services/tenant';
import './restaurant-thumbnail.scss';
var placeholderImg = (React.createElement("img", { src: TenantService.getPlaceholderImageForRestaurant(), className: "restaurant__photo", alt: "logo ".concat(TenantService.getBrandName()) }));
var RestaurantThumbnail = function (_a) {
    var isOrderMode = _a.isOrderMode, _b = _a.isVertical, isVertical = _b === void 0 ? false : _b, restaurant = _a.restaurant;
    var _c = __read(useState(null), 2), webpError = _c[0], setWebpError = _c[1];
    var _d = __read(useState(null), 2), originalError = _d[0], setOriginalError = _d[1];
    var name = restaurant.name, images = restaurant.images, slug = restaurant.slug;
    var className = [
        'restaurant-photo',
        isOrderMode ? 'restaurant-photo--square' : '',
        isVertical ? 'restaurant-photo--vertical' : '',
    ].join(' ');
    if (images && images.length) {
        var alt = "Zdj\u0119cia jedzenia z lokalu ".concat(name);
        var src = "".concat(TenantService.getAssetsDomain(), "/").concat(images[0].src);
        var pic = (React.createElement(React.Fragment, null,
            !webpError && (React.createElement("img", { src: getWebpSrc(src), onError: function () { return setWebpError(true); }, alt: alt })),
            webpError && !originalError && (React.createElement("img", { src: src, onError: function () { return setOriginalError(true); }, alt: alt })),
            webpError && originalError && placeholderImg));
        if (isOrderMode) {
            return (React.createElement("a", { href: "/zamawiaj/".concat(slug), className: className }, pic));
        }
        return React.createElement("div", { className: className }, pic);
    }
    return (React.createElement("a", { href: "/zamawiaj/".concat(slug), className: className }, placeholderImg));
};
RestaurantThumbnail.displayName = 'RestaurantThumbnail';
export default RestaurantThumbnail;
