import React from 'react';
import { UncontrolledAlert as Alert } from 'reactstrap';

import './oto-alert.scss'

const OtoAlert = ({ color = 'warning', className = '', text }) => <Alert color={color} className={className}>
  {text}
</Alert>;

export default OtoAlert;

