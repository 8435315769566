import { EPaymentType } from './app/enums';
export var LS_CART_KEY = 'cart';
export var personalFields = [
    { label: 'Imię', name: 'customer_firstname' },
    { label: 'Nazwisko', name: 'customer_lastname' },
    { label: 'Telefon', name: 'phone', type: 'tel', addon: '+48' },
    { label: 'Email', name: 'email', type: 'email' },
];
export var addressFields = [
    {
        label: 'Adres',
        name: 'address',
        className: 'col-12 col-sm-8 col-md-6 mb-2 pr-md-0',
    },
    {
        label: 'Nr budynku',
        name: 'house',
        optional: false,
        className: 'col-6 col-sm-6 col-md-3 mb-2 pr-0',
    },
    {
        label: 'Nr mieszkania',
        name: 'flat',
        optional: true,
        className: 'col-6 col-sm-6 col-md-3 mb-2',
    },
];
export var DeliveryTypes;
(function (DeliveryTypes) {
    DeliveryTypes["usual"] = "delivery";
    DeliveryTypes["contactless"] = "contactless_delivery";
    DeliveryTypes["robot"] = "robot_delivery";
    DeliveryTypes["takeout"] = "takeout";
})(DeliveryTypes || (DeliveryTypes = {}));
export var deliveryOptions = [
    { label: 'Dostawa zwykła', name: DeliveryTypes.usual },
    {
        label: 'Dostawa bezkontaktowa',
        name: DeliveryTypes.contactless,
        note: '(wymagana płatność online)',
    },
    {
        label: 'Odbiór osobisty',
        name: DeliveryTypes.takeout,
        noMinCartRequired: true,
    },
    {
        label: 'Dostawa robotem',
        name: DeliveryTypes.robot,
        note: '(wymagane zejście pod klatkę i płatność online)',
    },
];
export var PAYMENT_PROVIDERS = {
    PAYNOW: [EPaymentType.CARD_ONLINE, EPaymentType.BLIK, EPaymentType.TRANSFER],
    STRIPE: [EPaymentType.APPLE_PAY, EPaymentType.GOOGLE_PAY],
};
export var DISCOUNT_TYPES = {
    SAME_PRODUCT: 'same-product',
    SAME_CATEGORY: 'same-category',
};
