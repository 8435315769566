var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import LogService from './log';
import { APIService } from '../services/api';
import { hasLocalStorageAccess } from '../pwa';
import { createCookie } from './cookies';
import { DELIVERY_MODES } from 'App/config';
var distanceMatrixService;
export function getGoogleMapsDistance(origin, destination, callback) {
    if (!distanceMatrixService) {
        distanceMatrixService = new google.maps.DistanceMatrixService();
    }
    return new Promise(function (resolve, reject) {
        var handleGoogleMapsResponse = function (response) {
            var _a = response.rows[0].elements[0], distance = _a.distance, status = _a.status;
            if (status === 'ZERO_RESULTS') {
                LogService.error('Google Maps zero results', response);
                reject('Adres nie został znaleziony, brak możliwości dostawy.');
                return;
            }
            if (!distance) {
                LogService.error('No Google Maps distance found', response);
                reject('Brak możliwości dostawy - wystąpił problem podczas obliczenia odległości do wskazanego adresu.');
                return;
            }
            resolve(distance.value);
        };
        distanceMatrixService.getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            travelMode: google.maps.TravelMode.DRIVING,
            // transitOptions: TransitOptions,
            // drivingOptions: DrivingOptions,
        }, handleGoogleMapsResponse);
    });
}
export var hasMaxDistance = function (restaurantDetails) {
    var delivery = restaurantDetails.delivery;
    return !!(delivery && delivery.maxKmDistance);
};
export function getRestaurantIsDelivering(restaurant, position) {
    return APIService.get("/restaurants/".concat(restaurant.id, "/distance?lng=").concat(position.lng, "&lat=").concat(position.lat)).then(function (response) { return response.json(); });
}
var getGoogleMapPlaceLocation = function (place) {
    return place.geometry.location;
};
var gMapsGeocoder;
export function geocodeByAddress(address) {
    if (!gMapsGeocoder) {
        gMapsGeocoder = new google.maps.Geocoder();
    }
    return new Promise(function (resolve, reject) {
        gMapsGeocoder.geocode({ address: address }, function (results, status) {
            if (status === 'OK' && results) {
                // TODO fixme this could be null
                resolve(results[0]);
            }
            else if (status !== 'ZERO_RESULTS') {
                LogService.error("geocodeByAddress was not successful. Address: ".concat(address, ". Reason:"), status);
                reject(status);
            }
        });
    });
}
export function geocodeAddressByCoords(coords) {
    if (!gMapsGeocoder) {
        gMapsGeocoder = new google.maps.Geocoder();
    }
    var latitude = coords.latitude, longitude = coords.longitude;
    return new Promise(function (resolve, reject) {
        gMapsGeocoder.geocode({
            location: {
                lat: latitude,
                lng: longitude,
            },
        }, function (results, status) {
            if (status === 'OK' && results) {
                var foundAddressParts = results[0].address_components;
                var homeNumberPart = foundAddressParts.find(function (part) {
                    return part.types.includes('street_number');
                });
                var streetPart = foundAddressParts.find(function (part) {
                    return part.types.includes('route');
                });
                var foundAddressDict = {};
                if (homeNumberPart && homeNumberPart.long_name) {
                    foundAddressDict.house = homeNumberPart.long_name;
                }
                if (streetPart && streetPart.long_name) {
                    foundAddressDict.street = streetPart.long_name;
                }
                resolve(foundAddressDict);
            }
            else {
                LogService.error('Geocode by coords was not successful. Reason:', status);
                reject(status);
            }
        });
    });
}
var _deliveryPreferences = null; // TODO pass deliveryPreferences as props to OrderFormAddress
var LS_DELIVERY_PREFS = 'delivery-preferences-v2';
export var getDeliveryPreferences = function () {
    if (!hasLocalStorageAccess()) {
        return _deliveryPreferences;
    }
    try {
        var str = localStorage.getItem(LS_DELIVERY_PREFS);
        return str ? JSON.parse(str) : null;
    }
    catch (e) {
        LogService.error('Unable to get delivery prefs: ', e);
        return null;
    }
};
export var getIsAddressSelected = function (deliveryMode, address) {
    var isDeliveryWithSelectedAddress = deliveryMode === DELIVERY_MODES[0].name && !!address;
    var isTakeout = deliveryMode === DELIVERY_MODES[1].name;
    return isDeliveryWithSelectedAddress || isTakeout;
};
export var saveDeliveryPreferences = function (prefs, debugLabel) {
    if (debugLabel === void 0) { debugLabel = ''; }
    if (!!prefs.address) {
        createCookie('delivery-address', prefs.address, 90); // TODO check cookie restrictions
    }
    _deliveryPreferences = __assign(__assign({}, prefs), { 
        // @ts-expect-error this was like this before TS, @TODO fix potentially missing address
        address: prefs.address || (_deliveryPreferences === null || _deliveryPreferences === void 0 ? void 0 : _deliveryPreferences.address) });
    if (!hasLocalStorageAccess()) {
        return;
    }
    try {
        var currentPrefs = getDeliveryPreferences();
        if ((currentPrefs || {}).type === 'Dostawa') {
            LogService.importantActivity("saveDeliveryPreferences - invalid key saving from current prefs. fixing by adding a key in new prefs\n        debug label: ".concat(debugLabel, "\n        currentPrefs: ").concat(JSON.stringify(currentPrefs), "\n        newPrefs: ").concat(JSON.stringify(prefs), "\n        UA: ").concat(window.navigator.userAgent));
            if (!(prefs === null || prefs === void 0 ? void 0 : prefs.type) || (prefs === null || prefs === void 0 ? void 0 : prefs.type) === 'Dostawa') {
                prefs.type = 'delivery';
                LogService.importantActivity('Just overriden prefs.type to delivery');
            }
        }
        if ((prefs || {}).type === 'Dostawa') {
            LogService.importantActivity("saveDeliveryPreferences - invalid key saving from new prefs\n        debug label: ".concat(debugLabel, "\n        prefs: ").concat(JSON.stringify(prefs), "\n        UA: ").concat(window.navigator.userAgent));
        }
        localStorage.setItem(LS_DELIVERY_PREFS, JSON.stringify(__assign(__assign({}, currentPrefs), prefs)));
        if (!!prefs.address) {
            addLastUsedDeliveryAddress(prefs.address);
        }
    }
    catch (e) {
        LogService.error('Unable to save delivery prefs: ', e);
    }
};
var lastUsedKey = 'last-used-delivery-addresses';
export var getLastUsedDeliveryAddresses = function () {
    if (!hasLocalStorageAccess()) {
        return [];
    }
    var saved = localStorage.getItem(lastUsedKey);
    if (!saved) {
        return [];
    }
    try {
        return JSON.parse(saved) || [];
    }
    catch (e) {
        return [];
    }
};
function addLastUsedDeliveryAddress(address) {
    var existing = getLastUsedDeliveryAddresses();
    if (!existing.includes(address)) {
        localStorage.setItem(lastUsedKey, JSON.stringify(__spreadArray(__spreadArray([], __read(existing), false), [address], false)));
    }
}
export function removeLastUsedDeliveryAddress(address) {
    if (!hasLocalStorageAccess()) {
        return;
    }
    var existing = getLastUsedDeliveryAddresses();
    localStorage.setItem(lastUsedKey, JSON.stringify(existing.filter(function (item) { return item != address; })));
}
var getGoogleMapsPlaceField = function (googleMapPlace, type) {
    var addressObj = googleMapPlace.address_components.find(function (el) { return el.types[0] === type; });
    return addressObj ? addressObj.long_name : '';
};
var mapGooglePlaceToDeliveryPrefs = function (googleMapPlace, address, deliveryMode) {
    var location = getGoogleMapPlaceLocation(googleMapPlace);
    var coordinates = {
        lat: location.lat(),
        lng: location.lng(),
    };
    debugger;
    var _a = getAddressPartsFromPlace(googleMapPlace), street = _a.street, house = _a.house, city = _a.city, flat = _a.flat;
    var newDeliveryPreferences = {
        address: address,
        coordinates: coordinates,
        street: street,
        house: house,
        city: city,
        mode: deliveryMode,
        flat: flat,
    };
    return newDeliveryPreferences;
};
var getAddressPartsFromPlace = function (googleMapPlace) {
    var city = getGoogleMapsPlaceField(googleMapPlace, 'locality');
    var street = getGoogleMapsPlaceField(googleMapPlace, 'route');
    var streetNumber = getGoogleMapsPlaceField(googleMapPlace, 'street_number');
    var premise = getGoogleMapsPlaceField(googleMapPlace, 'premise');
    var subpremise = getGoogleMapsPlaceField(googleMapPlace, 'subpremise');
    // e.g. Jakubowice Murowane 71
    var isSingleHouseInSuburbs = !!city && !street && !streetNumber && !!premise;
    if (isSingleHouseInSuburbs) {
        return {
            city: city,
            street: city,
            house: premise,
        };
    }
    return {
        city: city,
        street: street,
        house: streetNumber,
        flat: subpremise,
    };
};
var DeliveryService = {
    mapGooglePlaceToDeliveryPrefs: mapGooglePlaceToDeliveryPrefs,
    saveDeliveryPreferences: saveDeliveryPreferences,
    getDeliveryPreferences: getDeliveryPreferences,
};
export default DeliveryService;
