var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import TenantService from 'App/services/tenant';
import ReactGA from 'react-ga';
import filterXss from 'xss';
import { currency } from '../config';
var ALLOWED_HOSTS = ['otostolik.pl', 'lubje.pl', 'leszno-je.pl'];
var TRACK_ACTIONS;
(function (TRACK_ACTIONS) {
    TRACK_ACTIONS["addToCart"] = "AddToCart";
    TRACK_ACTIONS["checkout"] = "Checkout";
    TRACK_ACTIONS["purchase"] = "Purchase";
    TRACK_ACTIONS["fbPixelCheckout"] = "InitiateCheckout";
})(TRACK_ACTIONS || (TRACK_ACTIONS = {}));
export function getSource(urlParams) {
    var utmSource = urlParams.has && urlParams.has('utm_source')
        ? urlParams.get('utm_source')
        : 'iframe';
    if (utmSource === 'facebook') {
        return 'fb_' + urlParams.get('utm_medium');
    }
    return utmSource;
}
export function trackingEnabled(restaurant) {
    var _a, _b;
    var isInIframe = window.self !== window.top;
    var hostName = isInIframe
        ? (_a = window.top) === null || _a === void 0 ? void 0 : _a[0].location.hostname
        : (_b = window.top) === null || _b === void 0 ? void 0 : _b.location.hostname;
    return (ALLOWED_HOSTS.includes(filterXss(hostName || '').replace('www.', '')) &&
        restaurant.id !== 124);
}
export function GACustomEvent(event) {
    var restaurantId = event.restaurantId, eventData = __rest(event, ["restaurantId"]);
    ReactGA.event(eventData);
}
var mapProductToFbPixel = function (product) { return ({
    name: product.name,
    quantity: product.amount,
    price: product.displayPrice || product.price * product.amount,
}); };
function fbPixelTrackOrderAction(options) {
    var _a = options.action, action = _a === void 0 ? TRACK_ACTIONS.purchase : _a, order = options.order;
    var value = 0;
    var contents = [];
    if ([TRACK_ACTIONS.purchase, TRACK_ACTIONS.fbPixelCheckout].includes(action) &&
        !!order &&
        !!order.products) {
        value = order.price;
        contents = order.products.map(mapProductToFbPixel);
    }
    return fbPixelTrackAction(action, value, contents);
}
function fbPixelTrackProductAction(action, product) {
    return fbPixelTrackAction(action, product.price, [mapProductToFbPixel(product)]);
}
function fbPixelTrackAction(action, value, contents) {
    var isInIframe = window.self !== window.top;
    try {
        var payload = {
            value: value,
            currency: currency,
            contents: contents,
        };
        if (isInIframe) {
            // window.top.fbq('track', action, payload);
            window.top.postMessage({
                message: 'fbPixelAction',
                value: JSON.stringify({
                    action: action,
                    payload: payload,
                }),
            }, '*');
        }
        else {
            var fbPixelId = TenantService.getFbPixelId();
            if (fbPixelId) {
                window.fbq('trackSingle', action, payload);
            }
            else {
                if (process.env.NODE_ENV === 'development') {
                    console.info('skipped FB tracking - no pixel id');
                }
            }
        }
        console.log('successfully tracked FB pixel event', action, payload);
    }
    catch (e) {
        console.error('Error during facebook pixel track call', e);
    }
}
function fbPixelTrackTableBooking(action, options) {
    if (options === void 0) { options = {}; }
    if (window.self !== window.top) {
        return;
    }
    try {
        window.fbq('trackSingle', '324782612358961', action, options);
        console.log('successfully tracked FB pixel booking event', action, options);
    }
    catch (e) {
        console.error('Error during facebook pixel booking track call', e);
    }
}
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService() {
    }
    AnalyticsService.trackAddToCart = function (restaurant, product, isFirst) {
        if (isFirst === void 0) { isFirst = false; }
        if (!trackingEnabled(restaurant)) {
            console.info('Skipped tracking of add product to card because of non-prod env');
            return;
        }
        GACustomEvent({
            restaurantId: restaurant.id,
            category: TRACK_ACTIONS.addToCart,
            action: isFirst ? 'Add First Item To Cart' : 'Add Next Item To Cart',
        });
        fbPixelTrackProductAction(TRACK_ACTIONS.addToCart, product);
    };
    AnalyticsService.trackOpenCartTotal = function (restaurant, placement) {
        if (!trackingEnabled(restaurant)) {
            console.info('Skipped tracking of open cart total because of non-prod env');
            return;
        }
        GACustomEvent({
            restaurantId: restaurant.id,
            category: 'Order',
            action: 'OpenCartTotal',
            label: 'Order Cart Total Form',
        });
    };
    AnalyticsService.trackOrderPurchase = function (order) {
        var price = order.price, delivery_cost = order.delivery_cost, restaurant_id = order.restaurant_id, id = order.id;
        if (!trackingEnabled({ id: restaurant_id })) {
            console.info('Skipped tracking of order purchase because of non-prod env');
            return;
        }
        if (process.env.NODE_ENV === 'development') {
            console.info('Order Purchase Event Triggered', { restaurant_id: restaurant_id });
            return;
        }
        !!window.gtag &&
            window.gtag('event', 'conversion', {
                send_to: 'AW-447077817/fivTCNaa1vUBELm7l9UB',
                value: price + delivery_cost,
                currency: 'PLN',
                transaction_id: id,
                event_callback: function () {
                    // if (typeof url != 'undefined') {
                    //   window.location = url
                    // }
                },
            });
        GACustomEvent({
            restaurantId: restaurant_id,
            category: 'Order',
            action: TRACK_ACTIONS.purchase,
            label: 'Customer Ordered',
            value: price + delivery_cost,
        });
        fbPixelTrackOrderAction({ action: TRACK_ACTIONS.purchase, order: order });
    };
    AnalyticsService.trackCheckout = function (restaurant, cart) {
        if (!trackingEnabled(restaurant)) {
            console.info('Skipped tracking of order checkout because of non-prod env');
            return;
        }
        GACustomEvent({
            restaurantId: restaurant.id,
            category: 'Order',
            action: TRACK_ACTIONS.checkout,
            label: 'Order Checkout Form',
        });
        var order = {
            products: cart,
            price: cart.reduce(function (prevValue, item) { return item.price + prevValue; }, 0),
        };
        fbPixelTrackOrderAction({ action: TRACK_ACTIONS.fbPixelCheckout, order: order });
    };
    AnalyticsService.trackDeliveryOutOfRange = function (restaurant) {
        if (!trackingEnabled(restaurant)) {
            console.info('Skipped tracking of delivery out of range because of non-prod env');
            return;
        }
        GACustomEvent({
            restaurantId: restaurant.id,
            category: 'Order',
            action: "#".concat(restaurant.id, " Delivery Out Of Range"),
            label: "#".concat(restaurant.id, " Delivery Out Of Range"),
        });
    };
    AnalyticsService.trackCuisineFilterClick = function (cuisine) {
        // @ts-ignore it was like this, quick fix to allow build
        if (!trackingEnabled({ id: null })) {
            console.info('Skipped tracking of cuisine filter click because of non-prod env');
            return;
        }
        GACustomEvent({
            category: 'Filter',
            action: 'CuisineFilterClick',
            label: cuisine,
        });
    };
    AnalyticsService.trackBookingRestaurantSearch = function () {
        fbPixelTrackTableBooking('Search');
    };
    AnalyticsService.trackBookingCheckout = function () {
        fbPixelTrackTableBooking(TRACK_ACTIONS.fbPixelCheckout, { value: 0 });
    };
    AnalyticsService.trackBookingPurchase = function () {
        fbPixelTrackTableBooking(TRACK_ACTIONS.purchase, { value: 0, currency: currency });
    };
    return AnalyticsService;
}());
export { AnalyticsService };
