var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import LogService from 'Utils/log';
import { APIService } from './api';
import { EPaymentType } from 'App/app/enums';
export var isOtoStolik = function () {
    return window.theme && window.theme === 'otostolik';
};
export var isLUBJE = function () {
    return window.theme && window.theme === 'lubje';
};
var isLesznoje = function () {
    return window.theme && window.theme === 'lesznoje';
};
var isOstrowje = function () {
    return window.theme && window.theme === 'ostrowje';
};
var isMiedzioweJe = function () {
    return window.theme && window.theme === 'miedzioweje';
};
var otostolikFallbackColor = '#f4623a';
var foodOrderingPlatformsFallbackColor = '#F05515';
var placeholderImgByTenant = {
    lubje: '/storage/img/lubje-192.png',
    lesznoje: '/storage/img/logo-lesznoje-color-v3-192.png',
    ostrowje: '/storage/img/logo-ostrowje-192.png',
    otostolik: '/storage/img/orange-192.png',
    miedzioweje: '/storage/img/miedzioweje-192.png',
};
var newLubjePixel = '374869944009630';
var oldLubjePixel = '418665056367656';
var TenantService = /** @class */ (function () {
    function TenantService() {
        var _this = this;
        this.loadConfig = function (tenant) { return __awaiter(_this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                url = "/tenants/".concat(tenant, "/config");
                return [2 /*return*/, APIService.post(url, {})
                        .then(function (response) { return response.json(); })
                        .then(function (payload) {
                        _this.config = payload;
                    })
                        .catch(function (e) {
                        LogService.fetchError({ actionName: 'Tenants config', e: e, url: url });
                    })];
            });
        }); };
        this.getAssetsDomain = function () { return 'https://cdn.otostolik.pl/img'; };
        this.getAvailablePaymentOptions = function () {
            if (_this.config) {
                return getAllPaymentOptions().filter(function (option) {
                    return _this.config.allowed_payment_option_keys.includes(option.name);
                });
            }
            return getLocallyHardcodedAvailablePaymentOptions();
        };
        this.getBrandName = function () {
            var _a;
            var brandNamesByTenant = {
                otostolik: 'OtoStolik',
                lubje: 'LUB*JE',
                lesznoje: 'Leszno Je!',
                ostrowje: 'Ostrów Je!',
                miedzioweje: 'Miedziowe Je!',
            };
            return ((_a = _this.config) === null || _a === void 0 ? void 0 : _a.label) || brandNamesByTenant[_this.activeTenant];
        };
        this.getBrandColor = function () {
            if (_this.config) {
                return _this.config.themeColor;
            }
            return _this.activeTenant === 'otostolik'
                ? otostolikFallbackColor
                : foodOrderingPlatformsFallbackColor;
        };
        this.getCoordinates = function () {
            if (_this.config) {
                return _this.config.location;
            }
            if (isOstrowje()) {
                return {
                    lat: 51.6469028,
                    lng: 17.8039814,
                };
            }
            if (isLesznoje()) {
                return {
                    lat: 51.844058,
                    lng: 16.575598,
                };
            }
            return {
                lat: 51.247119,
                lng: 22.563311,
            };
        };
        this.getTechnicalSupportEmail = function () {
            if (!_this.config) {
                return 'hello@otostolik.pl';
            }
            return _this.config.support.technical.email;
        };
        this.getOperationsEmail = function () {
            if (!_this.config) {
                return 'hello@otostolik.pl';
            }
            return _this.config.support.operations.email;
        };
        this.getFbPixelId = function () {
            if (isLUBJE()) {
                return newLubjePixel;
            }
            if (isLesznoje()) {
                return ''; // todo add fb pixel for lesznoje
            }
            if (isMiedzioweJe()) {
                return ''; // todo add fb pixel for miedziowe je
            }
            if (isOtoStolik()) {
                return '148743583789743';
            }
            return '';
        };
        this.getGoogleAnalyticsTrackingId = function () {
            var LUBJE_GA4_MEASUREMENT_ID = 'G-YTQ7N6Q800';
            var OTO_TRACKING_ID = 'UA-144193896-1';
            if (isLUBJE()) {
                return LUBJE_GA4_MEASUREMENT_ID;
            }
            // TODO: add other tenants
            if (isOtoStolik()) {
                return OTO_TRACKING_ID;
            }
            return '';
        };
        this.getGoogleMapsApiKey = function () {
            if (isLUBJE()) {
                return 'AIzaSyB1BaKo7BExgqYls1BZV9V3l38o-TQ6PtY';
            }
            // TODO: add other tenants, otostolik used as fallback
            return 'AIzaSyD2KCpVpHLo94ARDeVQlBuEu68smL6dX8c';
        };
        this.getGoogleMapsLinkForReview = function () {
            return isOtoStolik() ? 'https://g.page/OtoStolik/review?rc' : null; // TODO add links for lubje, lesznoje, miedziowe je
        };
        this.getTermsOfUseLink = function () {
            if (isLUBJE()) {
                return '/regulamin-lubje';
            }
            if (isOstrowje()) {
                return 'https://coostrowje.pl/owu.html'; // @TODO Ostrow check link
            }
            if (isLesznoje()) {
                return 'https://leszno-je.pl/owu.html';
            }
            if (isMiedzioweJe()) {
                // TODO fix this link
                return 'https://miedziowe-je.pl/owu.html';
            }
            return '/regulamin-otostolik-dowoz';
        };
        this.getPrivacyPolicyLink = function () {
            if (isLUBJE()) {
                return '/polityka-prywatnosci';
            }
            if (isLesznoje()) {
                return 'https://coostrowje.pl/polityka-prywatnosci.html'; // @TODO Ostrow check link
            }
            if (isLesznoje()) {
                return 'https://leszno-je.pl/polityka-prywatnosci.html';
            }
            if (isMiedzioweJe()) {
                // TODO fix this link
                return '';
            }
            return '/polityka-prywatnosci-otostolik-dowoz';
        };
        this.getForRestaurateursPagePrivacyPolicyLink = function () {
            if (isLUBJE()) {
                // TODO this should be separate
                return '/polityka-prywatnosci';
            }
            if (isOstrowje()) {
                return ''; // TODO add ostrowje privacy policy link
            }
            if (isLesznoje()) {
                return ''; // TODO add lesznoje privacy policy link
            }
            if (isMiedzioweJe()) {
                return ''; // TODO add miedzioweje privacy policy link
            }
            // and this should probably be integrated with main privacy policy
            return '/polityka-prywatnosci';
        };
        this.getPlaceholderImageForRestaurant = function () {
            return placeholderImgByTenant[_this.activeTenant];
        };
        this.getPlaceholderImageForRestaurantHero = function () {
            if (isLesznoje()) {
                return '/storage/img/lesznoje/lesznoje-logo-square-1024.png';
            }
            if (isOstrowje()) {
                // @TODO @Ostrow
                return '/storage/img/lesznoje/lesznoje-logo-square-1024.png';
            }
            // TODO add miedziowe je logo
            return isLUBJE()
                ? '/images/lubje-logo.png'
                : '/storage/img/transparent-512.png';
        };
        this.isTenantWithDefaultBookingMode = function () {
            return _this.activeTenant === 'otostolik';
        };
        this.isTenantWithDefaultOrderMode = function () {
            return _this.activeTenant !== 'otostolik';
        };
        this.activeTenant = window.theme;
        this.loadConfig(this.activeTenant);
    }
    return TenantService;
}());
var TenantServiceInstance = new TenantService();
export default TenantServiceInstance;
var offlinePaymentOptions = [
    {
        label: 'Kartą przy odbiorze',
        name: EPaymentType.CARD_ON_PLACE,
        isOnline: false,
    },
    {
        label: 'Gotówką przy odbiorze',
        name: EPaymentType.CASH,
        isOnline: false,
        note: 'płacąc gotówką napisz w uwagach z jakiej kwoty chcesz otrzymać resztę',
    },
];
var transferPaymentOption = {
    label: 'Szybki Przelew',
    name: EPaymentType.TRANSFER,
    isOnline: true,
    disabled: false,
};
var blikPaymentOption = {
    label: 'BLIK',
    name: EPaymentType.BLIK,
    isOnline: true,
    disabled: false,
};
var payNowPaymentOptions = [
    transferPaymentOption,
    blikPaymentOption,
];
var blueMediaPaymentOptions = [
    {
        label: 'Kartą online',
        name: EPaymentType.CARD_ONLINE,
        isOnline: true,
        disabled: false,
    },
    { label: 'Google Pay', name: EPaymentType.GOOGLE_PAY, isOnline: true },
];
var stripePaymentOptions = [
    {
        label: 'Apple Pay',
        name: EPaymentType.APPLE_PAY,
        isOnline: true,
        note: 'po przekierowaniu zaczekaj kilka sekund zanim pojawi się okienko potwierdzenia',
    },
];
function getAllPaymentOptions() {
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(payNowPaymentOptions), false), __read(blueMediaPaymentOptions), false), __read(stripePaymentOptions), false), __read(offlinePaymentOptions), false);
}
function getLocallyHardcodedAvailablePaymentOptions() {
    if (isOtoStolik()) {
        return __spreadArray(__spreadArray(__spreadArray([], __read(payNowPaymentOptions), false), __read(blueMediaPaymentOptions), false), __read(offlinePaymentOptions), false);
    }
    if (isLUBJE() || isLesznoje()) {
        return __spreadArray(__spreadArray([], __read(payNowPaymentOptions), false), __read(offlinePaymentOptions), false);
    }
    LogService.error('getLocallyHardcodedAvailablePaymentOptions', 'unknown tenant - showing only offline payment options');
    return offlinePaymentOptions;
}
