import React from 'react';
import PropTypes from 'prop-types';

import './select-mode.scss';

export const ModeTogglerWrapper = props => <div className={`mode-toggler ${props.fixed ? 'mode-toggler--fixed' : ''} ${props.className || ''}`}>
  {props.children}
</div>;

ModeTogglerWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  fixed: PropTypes.bool,
}

export const ModeItem = props => <button
  type="button"
  className={`mode-toggler__btn ${props.isActive ? 'mode-toggler__btn--active' : ''} ${props.className}`}
  onClick={props.onClick}>
  {props.children}
</button>;

ModeItem.defaultProps = {
  className: '',
};

ModeItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
