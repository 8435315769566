import React, { useState } from 'react'
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

import OtoAlert from '../common/OtoAlert'
import LogService from '../../utils/log'
import { getCityId } from '../../utils/url-params'
import TenantService from 'App/services/tenant'

import './recommend-restaurant.scss'

const getBrandName = TenantService.getBrandName

const RecommendRestaurant = () => {
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('')
  const [restaurant, setRestaurant] = useState('')
  const [contact, setContact] = useState('')
  const [notes, setNotes] = useState('')
  const [isSent, setIsSent] = useState(false)
  const closeModal = () => setModal(false)
  const submit = () => {
    const data = {
      name,
      restaurant,
      contact,
      notes,
    }
    LogService.importantActivity(
      `Restaurant recommended: ${JSON.stringify(data)}; city: ${getCityId()}`
    )
    setIsSent(true)
  }
  return (
    <>
      <section className="home-fr recommend-restaurant detect-webp">
        <h6 className="home-small-title">
          Nie znalazłeś restauracji na liście?
        </h6>
        <h3 className="h2 home-title text-left">
          Poleć restaurację i zgarnij rabat!
        </h3>
        <p>
          W wypadku nawiązania współpracy dzięki Twojemu poleceniu - otrzymasz
          od nas 100zł do wykorzystania przy zamówieniu z {getBrandName()} ;)
        </p>
        <button
          type="button"
          className="btn btn-link home-fr__link"
          onClick={() => setModal(true)}
        >
          Poleć restaurację
        </button>
      </section>
      <Modal isOpen={modal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Poleć restaurację</ModalHeader>
        <ModalBody>
          <div>
            <p>
              Miło nam, że chcesz, aby nowa restauracja dołączyła do{' '}
              {getBrandName()}.
            </p>
            <p>
              Będziemy dodatkowo wdzięczni, jeżeli{' '}
              <span className="text-primary">
                napiszesz do restauracji, np. na Facebook'u
              </span>
              , o tym, że chciałbyś mieć możliwość u niej zamawiać przez{' '}
              {getBrandName()}.
            </p>
            <p>
              Zajmie Ci to zaledwie kilka minut, a drastycznie zwiększy szansę -
              dla nas na rozpoczęcie współpracy, dla Ciebie -{' '}
              <span className="text-primary">otrzymanie kuponu rabatowego</span>{' '}
              :)
            </p>
          </div>
          <FormGroup>
            <Label>Kto poleca? (wymagane)</Label>
            <Input
              name="name"
              placeholder="Twoje imię i/lub nazwisko"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Jaką restaurację polecasz? (wymagane)</Label>
            <Input
              name="retaurant"
              placeholder="Nazwa / kontakt do restauracji"
              value={restaurant}
              onChange={(e) => setRestaurant(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Kontakt do Ciebie (opcjonalne, do wysłania kodu rabatowego)
            </Label>
            <Input
              name="contact"
              placeholder="Telefon lub email"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Uwagi</Label>
            <Input
              type="textarea"
              name="notes"
              value={notes}
              placeholder="Np. kontakt do osoby decyzyjnej w restauracji, jeżeli go masz"
              onChange={(e) => setNotes(e.target.value)}
            />
          </FormGroup>
          {isSent && (
            <OtoAlert
              color="success"
              text="Dziękujemy za polecenie! Trzymaj za nas kciuki ;)"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={submit}
          >
            Wyślij
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RecommendRestaurant
