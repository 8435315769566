import {
  MIN_HOUR_BOOK_ADVANCE,
  MIN_WEEKEND_HOUR_BOOK_ADVANCE,
  LAST_HOUR_TO_BOOK_TODAY,
  MIN_NON_PARTNER_HOUR_BOOK_ADVANCE
} from '../config';

export function getIsWorkingHours() {
  var hours = (new Date()).getHours();
  var isWorkingHours = (hours > 7) && (hours < 22);
  return isWorkingHours;
}

export function canBookTodayForHour(hourStr) {
  const today = new Date();
  const desiredHour = parseInt(hourStr);
  const currentHour = (today).getHours();
  if (isWeekend(today)) {
    return currentHour + MIN_WEEKEND_HOUR_BOOK_ADVANCE <= desiredHour;
  }
  return currentHour + MIN_HOUR_BOOK_ADVANCE <= desiredHour;
}

export function isWeekend(date) {
  const dayIndex = date.getDay()
  return [5, 6, 0].includes(dayIndex);
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

const minLength = (str, prependWith = '0', len = 2) => (prependWith + str).slice(-len)

export function displayTime(date) {
  return `${minLength(date.getHours())}:${minLength(date.getMinutes())}`;
}

export function newSafeDate(str) {
  return new Date(str.replace(/-/g, '/'));
}
