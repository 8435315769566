var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import LogService from 'Utils/log';
import { getAppSource } from 'Utils/url-params';
import version from 'Utils/version';
import filterXss from 'xss';
import { toDateInputValue } from '../app/date-helpers';
var API_URL = getApiUrl();
function getApiUrl() {
    var hostWithWWW = filterXss(window.location.hostname);
    var host = hostWithWWW.replace('www.', '');
    if (host.includes('stage.')) {
        return "https://".concat(host, "/api");
    }
    if (host.includes('.local')) {
        return "http://".concat(host, "/api");
    }
    if (host.includes('api.')) {
        return "https://".concat(host, "/api");
    }
    return "https://api.".concat(host, "/api");
}
export function post(url, body, headers) {
    if (headers === void 0) { headers = {}; }
    return fetch("".concat(API_URL).concat(url), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: __assign(__assign({}, getDefaultHeaders()), headers),
    });
}
export function put(url, body, headers) {
    if (headers === void 0) { headers = {}; }
    return fetch("".concat(API_URL).concat(url), {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: __assign(__assign({}, getDefaultHeaders()), headers),
    });
}
export var get = function (url) {
    return fetch("".concat(API_URL).concat(url), {
        headers: getDefaultHeaders(),
        mode: 'cors',
    });
};
export var APIService = {
    get: get,
    post: post,
    put: put,
};
export function getInstantBookings(props, errorCb) {
    var peopleAmount = props.peopleAmount, visitTime = props.visitTime, visitDate = props.visitDate, ids = props.ids;
    var from = toDateInputValue(visitDate) + ' ' + visitTime;
    if (!ids || !ids.length) {
        return Promise.resolve([]);
    }
    var url = "/restaurants/".concat(ids.join(','), "/availability?from=").concat(from, "&people=").concat(peopleAmount);
    return fetch("".concat(API_URL).concat(url))
        .then(function (response) { return response.json(); })
        .catch(function (e) {
        LogService.fetchError({ url: url, e: e, actionName: 'Availability' });
        errorCb(e);
    });
}
function getDefaultHeaders() {
    return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-app-name': getAppSource(),
        'x-app-version': version,
    };
}
