var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import Restaurant from '../Restaurant/Restaurant';
import OtoError from '../common/OtoError';
import { AppMode, DEFAULT_VALUES } from '../../config';
import { isActive } from '../../utils/restaurant-filtering';
import Shapes, { instantBookingsShape } from '../../app/shapes/main';
import { isDaySame, isToday } from '../../app/date-helpers';
import { throttle } from '../../app/lodash-cut';
import { bindOpeningHoursPopover, reenableRestaurantLabelPopover, } from '../../app/ui';
import { logNotFoundRestaurant, createDerivedStateFromErrorLogger, logComponentDidCatch, } from '../../utils/log';
import ScrollToTop from '../common/ScrollToTop';
import RecommendRestaurant from './RecommendRestaurant';
import RestaurantListActiveFilters from './RestaurantListActiveFilters';
import OtoSpinner from 'Comps/common/OtoSpinner';
var INITIAL_VISIBLE_AMOUNT = 15;
var isFilterEmpty = function (filter) {
    return Object.values(filter).filter(function (value) { return !!value; }).length === 0;
};
var COMPONENT_NAME = 'RestaurantList';
var RestaurantList = /** @class */ (function (_super) {
    __extends(RestaurantList, _super);
    function RestaurantList(props) {
        var _this = _super.call(this, props) || this;
        _this.hasScrollListener = false;
        _this.handleScroll = throttle(function (e) {
            var h = document.documentElement, b = document.body, st = 'scrollTop', sh = 'scrollHeight';
            var scrolled = h[st] || b[st];
            var total = (h[sh] || b[sh]) - h.clientHeight;
            var percent = (scrolled / total) * 100;
            if (percent > 60 || total - scrolled < 1500) {
                _this.lazyLoadRestaurants();
            }
        }, 200);
        _this.lazyLoadRestaurants = function () {
            var visibleAmount = _this.state.visibleAmount + 20;
            _this.setState({
                visibleAmount: visibleAmount,
            }, _this.updatePopovers);
            if (visibleAmount >= (_this.props.restaurants || []).length) {
                _this.hasScrollListener = false;
                window.removeEventListener('scroll', _this.handleScroll);
            }
        };
        _this.updatePopovers = function () {
            bindOpeningHoursPopover();
            reenableRestaurantLabelPopover();
        };
        _this.filterByCuisine = function (cuisine) {
            return _this.props.handleFilterChange(__assign(__assign({}, _this.props.filter), { cuisine: cuisine }));
        };
        _this.removeFilter = function (filterKey) {
            var _a;
            return _this.props.handleFilterChange(__assign(__assign({}, _this.props.filter), (_a = {}, _a[filterKey] = '', _a)));
        };
        if (isFilterEmpty(props.filter)) {
            _this.state = { visibleAmount: INITIAL_VISIBLE_AMOUNT, hasError: false };
        }
        else {
            _this.state = {
                visibleAmount: Math.max((props.restaurants || []).length, 10),
                hasError: false,
            };
        }
        return _this;
    }
    RestaurantList.prototype.componentDidMount = function () {
        if (this.state.visibleAmount === INITIAL_VISIBLE_AMOUNT) {
            window.addEventListener('scroll', this.handleScroll);
            this.hasScrollListener = true;
        }
    };
    RestaurantList.prototype.componentWillUnmount = function () {
        window.removeEventListener('scroll', this.handleScroll);
    };
    RestaurantList.prototype.componentWillReceiveProps = function (nextProps) {
        var filterChanged = this.props.filter !== nextProps.filter;
        var restaurantsInitialLoad = !this.props.restaurants && !!nextProps.restaurants;
        if (restaurantsInitialLoad) {
            return this.props.handleFilterChange(__assign({}, nextProps.filter));
        }
        if (filterChanged && nextProps.restaurants) {
            var nextFilter_1 = nextProps.filter;
            var activeRestaurants = nextProps.restaurants.filter(function (item) {
                return isActive(item, nextFilter_1);
            });
            if (!activeRestaurants.length) {
                this.logNotFoundRestaurant(nextProps.filter);
            }
            this.setState({
                visibleAmount: nextProps.restaurants.length,
            }, bindOpeningHoursPopover);
        }
    };
    RestaurantList.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var _this = this;
        if (this.state !== nextState ||
            !isDaySame(this.props.visitDate, nextProps.visitDate)) {
            return true;
        }
        var keysThatChanged = Object.keys(nextProps)
            .filter(function (key) { return !['visitDate'].includes(key); })
            .filter(function (key) { return _this.props[key] !== nextProps[key]; });
        if (keysThatChanged.length) {
            if (keysThatChanged.length === 1 &&
                keysThatChanged[0] === 'instantBookings') {
                if (this.props.instantBookings.loading !==
                    nextProps.instantBookings.loading) {
                    return true;
                }
                if (this.props.instantBookings.data.length === 0 &&
                    nextProps.instantBookings.data.length === 0) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };
    RestaurantList.prototype.componentDidCatch = function (error, info) {
        logComponentDidCatch(COMPONENT_NAME, error, info);
    };
    RestaurantList.prototype.logNotFoundRestaurant = function (filter) {
        var message = "Restauracja nie znaleziona: '".concat(filter.name, "'.\n      Filtr typ kuchni: '").concat(filter.cuisine, "'.\n      Tryb wyszukiwania: ").concat(this.props.appMode, ".\n      Miasto: ").concat(this.props.cityId);
        logNotFoundRestaurant(message);
    };
    RestaurantList.prototype.render = function () {
        var _this = this;
        if (this.state.hasError) {
            return React.createElement(OtoError, { className: "col-12 col-md-8 mx-md-auto" });
        }
        var _a = this.props, restaurants = _a.restaurants, appMode = _a.appMode, otherProps = __rest(_a, ["restaurants", "appMode"]);
        var canBookNonPartner = !isToday(this.props.visitDate);
        return (React.createElement("section", { className: "restaurant-list" },
            this.props.children,
            React.createElement(RestaurantListActiveFilters, { filter: this.props.filter, onRemove: this.removeFilter }),
            this.props.loading ? (React.createElement(OtoSpinner, { center: true, text: "Pobieram list\u0119 restauracji" })) : ((restaurants || []).slice(0, this.state.visibleAmount).map(function (item) { return (React.createElement(Restaurant, __assign({ key: item.name, restaurant: item, isOrderMode: appMode === AppMode.ORDER, canBookNonPartner: canBookNonPartner, 
                // @ts-ignore todo fix this & create type or interface
                deliversToSelectedAddress: item.delivering, handleCuisineClick: _this.filterByCuisine }, otherProps))); })),
            React.createElement(RecommendRestaurant, null),
            React.createElement(ScrollToTop, null)));
    };
    RestaurantList.propTypes = {
        cityId: PropTypes.number.isRequired,
        filter: PropTypes.shape({
            cuisine: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        instantBookings: instantBookingsShape,
        appMode: Shapes.appModePropType.isRequired,
        deliveryMode: Shapes.deliveryModePropType.isRequired,
        loading: PropTypes.bool.isRequired,
        handleFilterChange: PropTypes.func.isRequired,
        handleReservationClick: PropTypes.func.isRequired,
        peopleAmount: PropTypes.number.isRequired,
        restaurants: PropTypes.arrayOf(Shapes.restaurantShape),
        visitDate: PropTypes.object.isRequired,
        visitTime: PropTypes.string.isRequired,
    };
    RestaurantList.defaultProps = {
        peopleAmount: DEFAULT_VALUES.peopleAmount,
        restaurants: [],
    };
    RestaurantList.displayName = COMPONENT_NAME;
    RestaurantList.getDerivedStateFromError = createDerivedStateFromErrorLogger(COMPONENT_NAME);
    return RestaurantList;
}(React.Component));
export default RestaurantList;
