import React from 'react';

import Marker from '../../icons/Marker';

const RestaurantLocation = ({ className = 'mb-2', location }) => (
  location && <div className={`${className} restaurant__location`}>
    <Marker />
    <span className="ml-2">{location}</span>
  </div>
);

export default RestaurantLocation;
