var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import filterXss from 'xss';
import { throttle } from '../app/lodash-cut';
import { APIService } from '../services/api';
import version from './version';
var LOG_TYPE_ERROR = 'error';
var LOG_TYPE_ACTIVITY = 'activity';
var LOG_TYPE_SALES_ACTIVITY = 'sales_activity';
var LOG_TYPE_NO_RESTAURANT = 'no_restaurant';
var MAX_LOG_FREQUENCY = 3000;
var MAX_NOT_FOUND_LOG_FREQUENCT = 5000;
var activityLogMessage = '';
var errorLogMessage = '';
var notFoundRestaurantMessage = '';
var throttledLogActivity = throttle(function () {
    log(activityLogMessage, LOG_TYPE_ACTIVITY);
    activityLogMessage = '';
}, MAX_LOG_FREQUENCY);
var throttledLogError = throttle(function () {
    log(errorLogMessage, LOG_TYPE_ERROR);
    errorLogMessage = '';
}, MAX_LOG_FREQUENCY);
var throttledLogNotFoundRestaurant = throttle(function () {
    log(notFoundRestaurantMessage, LOG_TYPE_NO_RESTAURANT);
    notFoundRestaurantMessage = '';
}, MAX_NOT_FOUND_LOG_FREQUENCT);
var getUrl = function () { return filterXss(window.location.href); };
function logActivity(message, options) {
    if (options === void 0) { options = { instant: false }; }
    if (options && options.instant) {
        return log(message, LOG_TYPE_ACTIVITY);
    }
    else {
        activityLogMessage += message + '\n';
        return throttledLogActivity();
    }
}
function logSalesActivity(message) {
    return log(message, LOG_TYPE_SALES_ACTIVITY);
}
var stasMention = '<@ULV4W7JR4>';
function logImportantActivity(message, options) {
    if (options === void 0) { options = { instant: false }; }
    return logActivity("".concat(stasMention, "\nURL: ").concat(getUrl(), "\n").concat(message), options);
}
// DEPRECATED - use logErrorNew
function logError(message, options) {
    if (options === void 0) { options = { instant: false }; }
    if (options && options.instant) {
        return log("Landing, URL: ".concat(getUrl(), "\n      version: ").concat(version, "\n      ").concat(message), LOG_TYPE_ERROR);
    }
    else {
        errorLogMessage += "URL: ".concat(getUrl(), "\n      version: ").concat(version, "\n      UA: ").concat(window.navigator.userAgent, "\n      ").concat(message, " \n");
        return throttledLogError();
    }
}
function logErrorNew(message, error, options) {
    if (error === void 0) { error = null; }
    if (options === void 0) { options = { instant: false }; }
    return logError("".concat(message, "\nError: ").concat(stringifyEntity(error)), options);
}
export function logNotFoundRestaurant(message) {
    notFoundRestaurantMessage += message + '\n';
    return throttledLogNotFoundRestaurant();
}
var TELEGRAM_MAX_LENGTH = 4000; //actually 4096, cut for safety
export function log(message, type) {
    if (type === void 0) { type = LOG_TYPE_ACTIVITY; }
    if (process.env.NODE_ENV === 'development') {
        type === LOG_TYPE_ACTIVITY ? console.warn(message) : console.error(message);
        return;
    }
    return APIService.post('/logger/js', {
        message: message.slice(0, TELEGRAM_MAX_LENGTH),
        type: type,
    });
}
var skippableFetchErrors = [
    'Failed to fetch',
    'The request timed out.',
    'Przekroczenie limitu czasu żądania.',
    'cancelled',
    'anulowane',
    'The network connection was lost.',
    'Połączenie sieciowe zostało przerwane.',
    'Połączenie sieciowe zostało utracone.',
    'Połączenie z Internetem jest prawdopodobnie rozłączone.',
    'NetworkError when attempting to fetch resource.',
    'Load failed',
];
var failToFetchDict = {};
var logFetchError = function (_a) {
    var url = _a.url, e = _a.e, actionName = _a.actionName;
    var isSkippable = e && skippableFetchErrors.includes(e.message);
    if (isSkippable) {
        failToFetchDict[url] = failToFetchDict[url] ? failToFetchDict[url]++ : 0;
        if (failToFetchDict[url] > 0) {
            logError("".concat(actionName, " failed to fetch ").concat(failToFetchDict[url], " times. ").concat(stringifyEntity(e)));
        }
    }
    else {
        logError("".concat(actionName, " fetch error: ").concat(stringifyEntity(e)));
    }
};
var SINGLE_OBJECT_MAX_LEN = 1000;
export function stringifyEntity(entity) {
    if (!entity) {
        return 'NO_ENTITY';
    }
    if (typeof entity === 'string') {
        return entity;
    }
    if (entity instanceof Error) {
        if (entity.message) {
            return 'Type: instance of Error, message: ' + entity.message;
        }
        return ('instance of Error json: ' +
            JSON.stringify(entity, Object.getOwnPropertyNames(entity)).slice(0, SINGLE_OBJECT_MAX_LEN));
    }
    if (typeof entity === 'object') {
        var message = entity.message, status_1 = entity.status, rest = __rest(entity, ["message", "status"]);
        return "message: ".concat(message === null || message === void 0 ? void 0 : message.toString(), "\n      status: ").concat(status_1 === null || status_1 === void 0 ? void 0 : status_1.toString(), "\n      ").concat(JSON.stringify(rest).slice(0, SINGLE_OBJECT_MAX_LEN));
    }
    return 'OTHER_ENTITY';
}
export function createDerivedStateFromErrorLogger(componentName) {
    return function (error) {
        logError("".concat(componentName, " caught error in derived state. \n").concat(stringifyEntity(error)));
        return { hasError: true };
    };
}
export function logComponentDidCatch(name, error, info) {
    if (info === void 0) { info = {}; }
    var _a = info.componentStack, componentStack = _a === void 0 ? 'unknownStack' : _a;
    return logError("".concat(name, " caught error. Component stack: ").concat(stringifyEntity(componentStack), ";\n ").concat(stringifyEntity(error), "\n ").concat(stringifyEntity(info)));
}
var LogService = {
    componentDidCatch: logComponentDidCatch,
    activity: logActivity,
    error: logErrorNew,
    fetchError: logFetchError,
    importantActivity: logImportantActivity,
    salesActivity: logSalesActivity,
};
export default LogService;
