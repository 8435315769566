var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Shapes from 'Shapes';
import RestaurantThumbnail from 'Comps/Restaurant/RestaurantThumbnail';
import RestaurantTitle from 'Comps/Restaurant/RestaurantTitle';
import './RestaurantsSlider.scss';
import { isDesktop } from 'App/app/ui';
var sliderSettings = {
    className: 'slider variable-width',
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
};
var w200 = { width: 200 };
var w300 = { width: 250 };
var RestaurantsSlider = function (props) {
    var promotions = props.promotions;
    if (!promotions || !promotions.length) {
        return null;
    }
    var itemStyle = isDesktop() ? w300 : w200;
    return (React.createElement("div", { className: "col-12" }, promotions.map(function (promotion) { return (React.createElement("div", { key: promotion.name },
        React.createElement("div", { className: "h2" }, promotion.name),
        React.createElement(Slider, __assign({}, sliderSettings), promotion.restaurants.map(function (restaurant) { return (React.createElement("article", { key: restaurant.name, className: "restaurant restaurant--order-mode restaurant--slide visible mr-2", style: itemStyle },
            React.createElement(RestaurantThumbnail, { isOrderMode: true, isVertical: true, restaurant: restaurant }),
            React.createElement("section", { className: "restaurant__details" },
                React.createElement(RestaurantTitle, { textClassName: "small", isOrderMode: true, restaurant: restaurant })))); })))); })));
};
RestaurantsSlider.displayName = 'RestaurantsSlider';
RestaurantsSlider.propTypes = {
    promotions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        restaurants: PropTypes.arrayOf(Shapes.restaurantShape).isRequired,
    })),
};
var arrowStyle = {
    color: 'var(--primary)',
};
export default RestaurantsSlider;
