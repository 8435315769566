var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import CalendarAlt from './icons/CalendarAlt';
import ShoppingBag from './icons/ShoppingBag';
export var BIG_GROUP = 11;
export var MAX_GROUP = 50;
export var peopleList = __spreadArray(__spreadArray([
    {
        label: '1 osoba',
        value: 1,
    },
    {
        label: '2 osoby',
        value: 2,
        selected: true,
    },
    {
        label: '3 osoby',
        value: 3,
    },
    {
        label: '4 osoby',
        value: 4,
    }
], __read([5, 6, 7, 8, 9, 10, 11, 12].map(function (num) { return ({
    label: "".concat(num, " os\u00F3b"),
    value: num,
}); })), false), [
    {
        label: 'Większa grupa',
        value: BIG_GROUP,
    },
], false);
export var DEFAULT_VALUES = {
    peopleAmount: 2,
    visitTime: '18:00',
};
export var MIN_HOUR_BOOK_ADVANCE = 2;
export var MIN_NON_PARTNER_HOUR_BOOK_ADVANCE = 4;
export var MIN_WEEKEND_HOUR_BOOK_ADVANCE = 2;
export var LAST_HOUR_TO_BOOK_TODAY = 18;
export var RESERVATION_STATUS = {
    pending: 'pending',
    confirmed: 'confirmed',
    declined: 'declined',
};
export var ORDER_STATUS = {
    new: 'new',
    pending: 'pending',
    confirmed: 'confirmed',
    sent: 'sent',
    // by restaurant
    declined: 'declined',
    // by user
    cancelled: 'cancelled',
    delivered: 'delivered',
};
var indian = 'Indyjska';
var pub = 'Pub';
var coffee = 'Kawiarnia';
var burgers = 'Burgery';
var pizza = 'Pizza';
var sushi = 'Sushi';
var pasta = 'Makaron';
var american = 'Amerykańska';
var italian = 'Włoska';
var japanese = 'Japońska';
var east = 'Wschodnia';
var kebab = 'Kebab';
var polishandeuropean = 'Polska';
var vege = 'Wege';
var vegan = 'Wegan';
var mexican = 'Meksykańska';
var breakfast = 'Śniadanie';
var salad = 'Sałatki';
export var SIDEBAR_ORDER_CUISINE_LIST = [
    burgers,
    pizza,
    sushi,
    pasta,
    american,
    italian,
    japanese,
    polishandeuropean,
    east,
    kebab,
    vege,
    vegan,
    mexican,
    pub,
    coffee,
];
export var SIDEBAR_BOOK_CUISINE_LIST = [
    burgers,
    pizza,
    sushi,
    pasta,
    american,
    italian,
    japanese,
    polishandeuropean,
    indian,
    east,
    vege,
    vegan,
    breakfast,
    salad,
    pub,
    coffee,
];
export var currency = 'PLN';
var isTest = function () {
    return process.env.NODE_ENV === 'development' ||
        window.location.hostname.includes('.local');
};
export var STRIPE_API_KEY = !isTest()
    ? 'pk_live_W2h9sSskyzr73Riq4kvT7dDT001uK3MvRT'
    : 'pk_test_3nEoGyZM588s2xxppnqJGBxa00uoaHoeCk';
export var restaurantsWithNoBookPossibility = [155, 157, 161, 162, 163, 168];
export var ADDITIONAL_FIELD_TYPES;
(function (ADDITIONAL_FIELD_TYPES) {
    ADDITIONAL_FIELD_TYPES["MULTIPLE_CHOICE"] = "multiple-choice";
    ADDITIONAL_FIELD_TYPES["SINGLE_CHOICE"] = "single-choice";
    ADDITIONAL_FIELD_TYPES["CHECKBOX"] = "checkbox";
    ADDITIONAL_FIELD_TYPES["SET"] = "set";
})(ADDITIONAL_FIELD_TYPES || (ADDITIONAL_FIELD_TYPES = {}));
export var PromoCodeTypes;
(function (PromoCodeTypes) {
    PromoCodeTypes["percent"] = "percent";
    PromoCodeTypes["value"] = "value";
    PromoCodeTypes["freeDelivery"] = "freeDelivery";
})(PromoCodeTypes || (PromoCodeTypes = {}));
export var ASAP = 'ASAP';
export var AppMode;
(function (AppMode) {
    AppMode["RESERVATION"] = "booking";
    AppMode["ORDER"] = "order";
})(AppMode || (AppMode = {}));
export var OTO_PRODUCTS = {
    foodeli_deliveries: 'foodeli_deliveries',
    deliveries: 'deliveries',
    robot_deliveries: 'robot_deliveries',
    marketing: 'marketing',
    orders: 'orders',
    orders_list: 'orders_list',
    lubje_orders: 'lubje_orders_list',
    lesznoje_orders: 'lesznoje_orders_list',
    ostrowje_orders: 'ostrowje_orders_list',
    reservations: 'reservations',
    printer: 'printer',
    website: 'website',
    iframe: 'iframe',
};
export var REASONS_TO_CLOSE = {
    'too-many-orders': {
        name: 'dużo obecnych zamówień',
        text: 'Zamówienia chwilowo wstrzymane',
    },
    closed: {
        name: 'restauracja zamknięta',
        text: 'Restauracja obecnie jest zamknięta',
    },
    technical: {
        name: 'techniczny',
        text: 'Zamówienia chwilowo wstrzymane',
    },
    default: {
        name: 'inny powód',
        text: 'Zamówienia chwilowo wstrzymane',
    },
};
export var MODES = [
    {
        name: AppMode.ORDER,
        label: 'zamawiaj',
        longLabel: 'Zamawiam jedzenie',
        Icon: ShoppingBag,
    },
    {
        name: AppMode.RESERVATION,
        label: 'rezerwuj',
        longLabel: 'Rezerwuję stolik',
        Icon: CalendarAlt,
    },
];
export var getAvailableCuisineTypes = function (mode) {
    return mode === AppMode.ORDER
        ? SIDEBAR_ORDER_CUISINE_LIST
        : SIDEBAR_BOOK_CUISINE_LIST;
};
export var IFRAME_CONFIG = {
    dishModalOffset: 200,
    fabBtnOffset: 0,
    fabBtnOffsetMd: 0,
    fixedHeaderHeight: 0,
    iframeOffsetTop: 0,
    menuModalSubstract: 0,
    menuModalSubstractMd: 70,
    menuNavOffsetMd: 0,
    menuNavOffset: 0,
    mdBreakpoint: 1100,
    restaurantMenuModalOffset: 0,
    restaurantMenuModalOffsetMd: 30, // not used more
};
export var OFFER_TYPES = {
    NOT_SELLING: 'not-selling',
    SELL_WITHOUT_COMMISSION: 'sell-without-commission',
    WANT_TO_REDUCE_COST: 'want-to-reduce-cost',
    LUBJE: 'lubje',
};
export var subscriptionPlans = [
    { ordersAmount: 100, price: 120, label: 'S' },
    {
        ordersAmount: 250,
        price: 200,
        label: 'M',
        featuredText: 'najczęściej wybierany!',
    },
    { ordersAmount: 1000, price: 450, label: 'L' },
];
export var THEMES = {
    OtoStolik: 'otostolik',
    LUBJE: 'lubje',
};
export var DELIVERY_MODES = [
    {
        name: 'delivery',
        label: 'Dostawa',
        longLabel: 'Zamawiam z dostawą',
    },
    {
        name: 'takeout',
        label: 'Odbiór',
        longLabel: 'Zamawiam z odbiorem',
    },
];
