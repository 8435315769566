import React from 'react';
import PropTypes from 'prop-types';
var bannerStyle = {
    marginBottom: 10,
};
var imgStyle = {
    borderRadius: 8,
    maxWidth: '100%',
    width: '100%',
    height: 'auto',
};
var pictureImg = function (bannerShape) { return (React.createElement("img", { style: imgStyle, src: bannerShape.src, width: bannerShape.width, height: bannerShape.height })); };
var pictureSource = function (bannerShape) { return (React.createElement("source", { key: bannerShape.src, srcSet: bannerShape.src, media: "(min-width: ".concat(bannerShape.width, "px)") })); };
var BannerTile = function (props) {
    var banner = props.banner;
    return (React.createElement("div", { style: bannerStyle },
        React.createElement("picture", null,
            banner.sizes.slice(1).map(function (size) { return pictureSource(size); }),
            pictureImg(banner.sizes[0]))));
};
var bannerShape = PropTypes.exact({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
});
BannerTile.propTypes = {
    banner: PropTypes.exact({
        action: PropTypes.array,
        sizes: PropTypes.arrayOf(bannerShape).isRequired,
    }),
};
export default BannerTile;
