export function getPeopleAmountLabel(peopleAmount, options = {}) {
  const { has_kids, kids_number } = options;
  const generalLabel = getPlural(peopleAmount, ['osoby', 'osób']);
  if (has_kids && kids_number && typeof kids_number === 'number') {
    const kidsNum = Math.min(Math.max(kids_number, 0), peopleAmount);
    const adultsNum = Math.max(peopleAmount - kidsNum, 0);
    const adultsLabel = getPlural(adultsNum, ['osoby dorosłej', 'osób dorosłych']);
    const kidsLabel = getPlural(kidsNum, ['dziecka', 'dzieci']);
    return `${generalLabel} (${adultsLabel} i ${kidsLabel})`;
  }
  return generalLabel;
}

export function getPlural(num, strs) {
  if (num === 1) {
    return `1 ${strs[0]}`;
  }
  return `${num} ${strs[1]}`;
}
