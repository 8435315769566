import React from 'react';

import Bolt from '../../icons/Bolt';

import './restaurant-label.scss';

const RestaurantLabelInstantBook = ({ show = true }) => {
  if (!show) {
    return null;
  }
  return <div className="restaurant__label mw-md-225"
    data-toggle="popover"
    data-placement="top"
    data-trigger="hover"
    title="Szybka rezerwacja"
    data-content="W tym lokalu masz możliwość rezerwacji natychmiastowej, bez dłuższego oczekiwania na potwierdzenie.">
    <span>natychmiastowa rezerwacja</span>
    <Bolt />
  </div>;
}

RestaurantLabelInstantBook.displayName = 'RestaurantLabelInstantBook';

export default RestaurantLabelInstantBook;
