import PropTypes from 'prop-types';
var currentAreaShape = PropTypes.exact({
    freeDeliveryFrom: PropTypes.number.isRequired,
    minCartAmount: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
});
var discountItemShape = PropTypes.exact({
    discounted_price: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    value_without_discount: PropTypes.number.isRequired,
});
var promoCodeHintShape = PropTypes.shape({
    cart_required: PropTypes.number,
    cart_missing: PropTypes.number,
    discount_amount: PropTypes.number,
    times_left: PropTypes.number,
    times_used: PropTypes.number,
});
export var upsellingItemShape = PropTypes.exact({
    value: PropTypes.number.isRequired,
    min_value: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
});
export var basketStatusShape = PropTypes.exact({
    currentArea: currentAreaShape,
    distance: PropTypes.number,
    formattedDistance: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    price: PropTypes.exact({
        delivery: PropTypes.number.isRequired,
        discounted: PropTypes.number.isRequired,
        discounts_value: PropTypes.number.isRequired,
        withDelivery: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
    }).isRequired,
    discounts: PropTypes.arrayOf(discountItemShape.isRequired).isRequired,
    upselling: PropTypes.arrayOf(upsellingItemShape.isRequired).isRequired,
    voucher_ids: PropTypes.arrayOf(PropTypes.number.isRequired),
    promo_code: PropTypes.string,
    promo_code_name: PropTypes.string,
    promo_code_reason: PropTypes.string,
    promo_code_hint: promoCodeHintShape,
    show_promo_code_input: PropTypes.bool,
});
