import filterXss from 'xss';
import 'url-search-params-polyfill';
import 'array-flat-polyfill';
import { toDateInputValue, isFri, isSat, isSun } from './date-helpers';
import { REASONS_TO_CLOSE, OTO_PRODUCTS } from '../config';
import { DeliveryTypes } from '../order-fields';
export function getActiveReservationCustomRules(restaurant, restaurantProps) {
    var rules = restaurant.custom_rules;
    var peopleAmount = restaurantProps.peopleAmount, visitDate = restaurantProps.visitDate;
    if (!rules || !rules.map) {
        return [];
    }
    var activeCustomRulesWithKnownKeys = rules.map(function (rule) {
        var key = rule.key, text = rule.text, value = rule.value, values = rule.values, disableBook = rule.disableBook;
        if (key === 'peopleAmount') {
            if (peopleAmount >= parseInt(value || '', 10)) {
                return { text: text, disableBook: disableBook };
            }
        }
        if (key === 'visitDate') {
            if (values &&
                values.includes &&
                values.includes(toDateInputValue(visitDate))) {
                return { text: text, disableBook: disableBook };
            }
        }
        if (key === 'isFri' && isFri(visitDate)) {
            return { text: text, disableBook: disableBook };
        }
        if (key === 'isSat' && isSat(visitDate)) {
            return { text: text, disableBook: disableBook };
        }
        if (key === 'isSun' && isSun(visitDate)) {
            return { text: text, disableBook: disableBook };
        }
        if (key === 'disableBookMessage') {
            return { text: text, disableBook: true };
        }
        return null;
    });
    return activeCustomRulesWithKnownKeys.filter(function (rule) { return !!rule; });
}
export function getActiveOrdersCustomRules(rules) {
    if (rules === void 0) { rules = []; }
    return (rules || [])
        .map(function (rule) {
        var key = rule.key, text = rule.text, values = rule.values, _a = rule.disableOrder, disableOrder = _a === void 0 ? true : _a;
        if (key === 'visitDate') {
            if (values &&
                values.includes &&
                values.includes(toDateInputValue(new Date()))) {
                return { text: text, disableOrder: disableOrder };
            }
        }
        return null;
    })
        .filter(function (rule) { return !!rule; });
}
export function isBookingPartner(restaurant) {
    var products = (restaurant || {}).products;
    return (products || []).includes(OTO_PRODUCTS.reservations);
}
export function hasOrders(restaurant) {
    var products = (restaurant || {}).products;
    return (products || []).includes(OTO_PRODUCTS.orders);
}
export function getOrdersConfig(restaurant) {
    return (restaurant.config && restaurant.config.orders) || {};
}
export function isPaused(restaurant) {
    return restaurant.status === 'blocked';
}
export function isTakeoutOrder(order) {
    return order.delivery_type === DeliveryTypes.takeout;
}
export function hasCustomDeliveryTime(order) {
    return order.customer && order.customer.deliveryAt;
}
export function getDisabledOrdersText(restaurant) {
    var ordersConfig = getOrdersConfig(restaurant);
    if (ordersConfig) {
        var value = ordersConfig.allowOrders;
        if (value && value !== true) {
            return ((REASONS_TO_CLOSE[value] && REASONS_TO_CLOSE[value].text) ||
                REASONS_TO_CLOSE.default.text);
        }
    }
    return false;
}
export function isDebugMode() {
    var urlParams = new URLSearchParams(filterXss(window.location.search));
    return urlParams.get('debug') === 'true';
}
