import PropTypes from 'prop-types';
import ordersConfigShape from './orders-config';
import reservationConfigShape from './reservation-config';
import menuShape from './restaurant-menu';
export var hourShape = PropTypes.exact({
    close_at: PropTypes.string.isRequired,
    close_day: PropTypes.number.isRequired,
    is_closed: PropTypes.bool.isRequired,
    open_at: PropTypes.string.isRequired,
    open_day: PropTypes.number.isRequired,
});
var customRuleShape = PropTypes.exact({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    disableBook: PropTypes.bool.isRequired,
    disableOrder: PropTypes.bool.isRequired,
});
var robotDiscountShape = PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
});
var restaurantShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    city_id: PropTypes.number.isRequired,
    config: reservationConfigShape.isRequired,
    created_at: PropTypes.string.isRequired,
    cuisine_label: PropTypes.array.isRequired,
    cuisine_type: PropTypes.string,
    custom_rules: PropTypes.arrayOf(customRuleShape.isRequired),
    g_maps_id: PropTypes.string,
    hero_images: PropTypes.arrayOf(PropTypes.string.isRequired),
    images: PropTypes.array.isRequired,
    logo: PropTypes.string,
    location: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    menu: menuShape.isRequired,
    menu_on_place: menuShape,
    name: PropTypes.string.isRequired,
    opening_hours: PropTypes.array.isRequired,
    restaurant_opening_hours: PropTypes.arrayOf(hourShape.isRequired).isRequired,
    delivery_opening_hours: PropTypes.arrayOf(hourShape.isRequired).isRequired,
    opening_hours_relation: PropTypes.arrayOf(hourShape),
    phone: PropTypes.string.isRequired,
    price_level: PropTypes.number,
    products: PropTypes.array.isRequired,
    rating: PropTypes.number,
    robot_discount: robotDiscountShape,
    slug: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    // generated after normalization on FE
    orders: ordersConfigShape.isRequired,
    tablesConfig: PropTypes.array,
});
export default restaurantShape;
