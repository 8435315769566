import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { MODES } from '../../config';
import './mode-select-modal.scss';

const ModeSelectModal = props => {
  const { setMode } = props;
  return <Modal isOpen centered className="mode-select-modal">
    <ModalHeader>
      Cześć, co dzisiaj robimy?
    </ModalHeader>
    <ModalBody>
      {
        MODES.map(mode => <button
          key={mode.name}
          type="button"
          className="mode-select-modal__btn"
          onClick={() => setMode(mode.name)}>
          <mode.Icon />
          <div>{mode.longLabel}</div>
        </button>)
      }
    </ModalBody>
  </Modal>
}

ModeSelectModal.propTypes = {
  setMode: PropTypes.func.isRequired,
}

ModeSelectModal.displayName = 'ModeSelectModal';

export default ModeSelectModal;
