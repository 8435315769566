import React from 'react';
import PropTypes from 'prop-types';

const OtoSpinner = ({ center = false, className = '', text = '' }) => {
  const spinner = <div className={`spinner-border text-primary ${className}`} role="status">
    <span className="sr-only">Loading...</span>
  </div>;
  if (center) {
    return <div className="w-100 text-center">
      {spinner}
      {text && <span className="ml-2">{text}</span> }
    </div>
  }
  return spinner;
};

OtoSpinner.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
};

export default OtoSpinner;
