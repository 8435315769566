import React from 'react';
import PropTypes from 'prop-types';

import OtoHashTag from '../common/OtoHashTag';
import { filterShape } from '../../app/shapes/main';

const mapFilterKeyToLabel = {
  name: 'Nazwa restauracji',
  cuisine: 'Typ kuchni',
};

const RestaurantListActiveFilters = props => {
  const { filter, onRemove } = props;
  const activeFilters = Object.entries(filter).filter(([, filterValue]) => !!filterValue);
  if (!activeFilters.length) {
    return null;
  }
  return <>
    <h5>Aktywne filtry</h5>
    <div className="vertical-center flex-wrap mb-3">
      {activeFilters.map(([filterKey, filterValue]) => <React.Fragment key={filterKey}>
        <span className="mr-2">{mapFilterKeyToLabel[filterKey]}:</span>
        <OtoHashTag key={filterKey} tag={filterValue} onRemove={() => onRemove(filterKey)} />
      </React.Fragment>)}
    </div>
  </>;
};

RestaurantListActiveFilters.propTypes = {
  filter: filterShape.isRequired,
  onRemove: PropTypes.func.isRequired,
};

RestaurantListActiveFilters.displayName = 'RestaurantListActiveFilters';

export default RestaurantListActiveFilters;
