var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import { enableDatepicker } from '../app/ui';
import { getSelectedDateLabel, isDaySame, toDateInputValue, tomorrow, } from '../app/date-helpers';
import { BIG_GROUP, peopleList } from '../config';
import CalendarAlt from '../icons/CalendarAlt';
import UserFriends from '../icons/UserFriends';
import Clock from '../icons/Clock';
import Location from '../icons/Location';
import { AnalyticsService } from 'Utils/tracking';
export var isBigGroup = function (peopleAmount) { return peopleAmount >= BIG_GROUP; };
var SelectBar = /** @class */ (function (_super) {
    __extends(SelectBar, _super);
    function SelectBar(props) {
        var _this = _super.call(this, props) || this;
        _this.handleReservationDateChange = function (event) {
            var date = event.date;
            _this.props.handleDateChange(date);
        };
        _this.handlePeopleAmountChange = function (e) {
            var val = e.target.value;
            if (!val) {
                _this.props.handlePeopleAmountChange(e);
                return;
            }
            if (!_this.state.bigGroup) {
                if (isBigGroup(parseInt(val, 10))) {
                    _this.setState({ bigGroup: true });
                    _this.props.handlePeopleAmountChange({ target: { value: '' } });
                    return;
                }
            }
            _this.props.handlePeopleAmountChange(e);
        };
        _this.setPeopleAmountRef = function (node) {
            node.focus();
        };
        _this.submitForm = function (e) {
            AnalyticsService.trackBookingRestaurantSearch();
        };
        _this.state = {
            bigGroup: isBigGroup(props.peopleAmount),
        };
        return _this;
    }
    SelectBar.prototype.componentDidMount = function () {
        var startDate = tomorrow;
        var initialValue = this.props.visitDate > startDate ||
            isDaySame(this.props.visitDate, startDate)
            ? this.props.visitDate
            : tomorrow;
        enableDatepicker({
            container: '#visitDateContainer',
            startDate: startDate,
            initialValue: initialValue,
            onDateChange: this.handleReservationDateChange,
            format: {
                toDisplay: function (input) {
                    var _a = getSelectedDateLabel(input), date = _a.date, label = _a.label;
                    return "".concat(date, " ").concat(label);
                },
                toValue: function (date) {
                    return toDateInputValue(date, { sep: '.', reversed: false });
                },
            },
        });
    };
    SelectBar.prototype.render = function () {
        var _a;
        return (React.createElement("form", { action: "/rezerwuj", method: "GET", className: this.props.formClassName },
            React.createElement("section", { className: "select-bar ".concat(this.props.className) },
                React.createElement("h4", { className: "select-bar__title" }, "Dane rezerwacji"),
                this.props.cityList && ((_a = this.props.cityList) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement("div", { className: "input-group" },
                    React.createElement("div", { className: "input-group-prepend" },
                        React.createElement("span", { className: "input-group-text", id: "city-select-addon" },
                            React.createElement(Location, null))),
                    React.createElement("label", { htmlFor: "city-select" }, "Miasto"),
                    React.createElement("select", { name: "city", className: "custom-select", id: "city-select", "aria-label": "Miasto", "aria-describedby": "city-select-addon" }, this.props.cityList.map(function (item) {
                        return (React.createElement("option", { key: item.id, value: item.id }, item.name));
                    })))),
                React.createElement("div", { className: "input-group date datepicker", id: "visitDateContainer" },
                    React.createElement("div", { className: "input-group-prepend" },
                        React.createElement("span", { className: "input-group-text", id: "calendar-addon" },
                            React.createElement(CalendarAlt, null))),
                    this.props.isInline && (React.createElement("label", { htmlFor: "visitDate" }, "Data rezerwacji")),
                    React.createElement("input", { type: "text", id: "visitDate", name: "visitDate", className: "form-control date-input", placeholder: "Data", "aria-label": "Data", "aria-describedby": "calendar-addon", readOnly: true })),
                React.createElement("div", { className: "input-group" },
                    React.createElement("div", { className: "input-group-prepend" },
                        React.createElement("span", { className: "input-group-text", id: "basic-addon3" },
                            React.createElement(Clock, null))),
                    this.props.isInline && (React.createElement("label", { htmlFor: "reservation-time-select" }, "Godzina")),
                    React.createElement("select", { name: "visitTime", className: "custom-select", id: "reservation-time-select", "aria-label": "Godzina rezerwacji", "aria-describedby": "basic-addon3", value: this.props.visitTime, onChange: this.props.handleTimeChange }, this.props.reservationHours.map(function (hour) { return (React.createElement("option", { key: hour, value: hour }, hour)); }))),
                React.createElement("div", { className: "input-group" },
                    React.createElement("div", { className: "input-group-prepend" },
                        React.createElement("span", { className: "input-group-text", id: "user-addon" },
                            React.createElement(UserFriends, null))),
                    this.props.isInline && (React.createElement("label", { htmlFor: "peopleAmount" }, "Liczba os\u00F3b")),
                    this.renderPeopleAmountInput()),
                this.props.hasButton && (React.createElement("button", { type: "submit", onSubmit: this.submitForm, className: "home-switch-btn" }, "Znajd\u017A restauracje")))));
    };
    SelectBar.prototype.renderPeopleAmountInput = function () {
        var commonPeopleAmountProps = {
            name: 'peopleAmount',
            'aria-label': 'Ilość osób',
            'aria-describedby': 'user-addon',
            value: this.props.peopleAmount || '',
            onChange: this.handlePeopleAmountChange,
        };
        if (!this.state.bigGroup) {
            return (React.createElement("select", __assign({ className: "custom-select" }, commonPeopleAmountProps), peopleList.map(function (item) { return (React.createElement("option", { key: item.label, value: item.value }, item.label)); })));
        }
        return (React.createElement("input", __assign({ ref: this.setPeopleAmountRef, className: "form-control people-amount-input", placeholder: "Podaj liczb\u0119 os\u00F3b", type: "number", min: "1", max: "30" }, commonPeopleAmountProps)));
    };
    SelectBar.displayName = 'SelectBar';
    SelectBar.propTypes = {
        className: PropTypes.string,
        formClassName: PropTypes.string,
        isInline: PropTypes.bool,
        handleDateChange: PropTypes.func.isRequired,
        handleTimeChange: PropTypes.func.isRequired,
        handlePeopleAmountChange: PropTypes.func.isRequired,
        hasButton: PropTypes.bool,
        peopleAmount: PropTypes.number.isRequired,
        reservationHours: PropTypes.array.isRequired,
        visitDate: PropTypes.instanceOf(Date).isRequired,
        visitTime: PropTypes.string.isRequired,
    };
    SelectBar.defaultProps = {
        className: '',
        formClassName: '',
        hasButton: false,
        isInline: false,
        cityList: [],
    };
    return SelectBar;
}(React.PureComponent));
export default SelectBar;
